import React from 'react';
import { Card, Modal, Typography } from '@mui/material';
import './MarkAsContactDialog.scss';

const MarkAsContactDialog = (props) => {
  const { open, setOpen, position, updateContactPendingByEmployeeId } = props;
  const onClose = () => {
    setOpen(false);
  };
  const handleClickMarkAsContacted = () => {
    updateContactPendingByEmployeeId();
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="mark-paid-dialog"
      style={{ position: 'absolute', top: position.top, left: position.left }}
    >
      <Card variant="outlined" className="card-style">
        <Typography className="mark-style" variant="h5">
          <button type="button" onClick={handleClickMarkAsContacted}>
            Mark as contacted
          </button>
        </Typography>
      </Card>
    </Modal>
  );
};

export default MarkAsContactDialog;
