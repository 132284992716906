import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './TabStatus.scss';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const TabStatus = ({ tabsData, handleChangeStatus, tabIndex, setPage, setRowsPerPage }) => {
  const [value, setValue] = React.useState(tabIndex || 0);

  const handleChange = (event, newValue) => {
    setPage(0);
    setRowsPerPage(10);
    handleChangeStatus(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (tabIndex) {
      // eslint-disable-next-line no-undef
      handleChange(null, tabIndex);
    }
  }, [tabIndex]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: '1px solid #E6E6EE', borderColor: 'divider', color: '#000650' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          textColor="primary"
          variant="standard"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#000650',
            },
            '& .MuiTab-root': {
              fontWeight: 'bold',
            },
          }}
        >
          {tabsData.map((tab, index) => (
            <Tab
              sx={{
                textTransform: 'none',
                paddingInline: '26px',
                minWidth: '192px',
              }}
              key={tab}
              label={tab}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default TabStatus;
