import { configureStore } from '@reduxjs/toolkit';
import storage from 'localforage';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import createExpirationTransform from 'redux-persist-transform-expire';
import { appEnv } from '../config/variable';
import rootReducer from './reducers';

const middlewares = [];
const loggerEnvs = ['dev', 'stg'];
if (loggerEnvs.includes(appEnv)) {
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });

  middlewares.push(logger);
}

const expireTransform = createExpirationTransform({
  expireKey: 'expiresAt',
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['loginReducer'],
  transforms: [expireTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: appEnv === 'dev',
});

const persistor = persistStore(store);

export { persistor, store };
