import { createTheme } from '@mui/material';
import ModeratLight from '../assets/fonts/Moderat-Light.ttf';
import ModeratMedium from '../assets/fonts/Moderat-Medium.ttf';
import ModeratRegular from '../assets/fonts/Moderat-Regular.ttf';

const themeWithColors = createTheme({
  palette: {
    primary: {
      main: '#000650',
    },
    secondary: {
      main: '#5E17EB',
    },
    background: {
      default: '#FFF',
    },
    green: {
      main: '#00804A',
      light: '#E6F3ED',
      10: '#E6F3ED',
      100: '#00804A',
    },
    grey: {
      main: '#9F9F9F',
      10: '#E8E8E8',
      20: '#CFCFCF',
      40: '#9F9F9F',
      60: '#707070',
      80: '#404040',
    },
    navy: {
      10: '#E6E6EE',
      20: '#CCCDDC',
      40: '#8082A7',
      60: '#666A96',
      80: '#333873',
      100: '#000650',
      main: '#8082A7',
    },
    noir: '#101010',
    pink: {
      10: '#FEFBFF',
      20: '#FCF5FF',
      40: '#F9EBFF',
      60: '#F5E2FF',
      80: '#F2D8FF',
      100: '#EFCEFF',
    },
    purple: {
      10: '#EFE8FD',
      20: '#DFD1FB',
      40: '#BFA2F7',
      60: '#9E74F3',
      80: '#7E45EF',
      100: '#5E17EB',
    },
    red: {
      10: '#FEECEC',
      100: '#F33F3F',
      main: '#F33F3F',
    },
    yellow: {
      10: '#FFF7EA',
      20: '#FFEFD5',
      40: '#FFDEAA',
      60: '#FFD188',
      80: '#FFBD55',
      100: '#FFAD2B',
      main: '#FFAD2B',
    },
  },
});

const theme = createTheme(themeWithColors, {
  typography: {
    body1: {
      color: themeWithColors.palette.noir,
      fontFamily: 'Moderat-Light',
      fontSize: 14,
      fontWeight: 300,
      letterSpacing: 'normal',
    },
    body2: {
      color: themeWithColors.palette.noir,
      fontFamily: 'Moderat-Light',
      fontSize: 16,
      fontWeight: 300,
      letterSpacing: 'normal',
    },
    h1: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 48,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h2: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 40,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h3: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 32,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h4: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h5: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    h6: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 8,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    subtitle1: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    subtitle2: {
      color: themeWithColors.palette.primary.main,
      fontFamily: 'Moderat-Medium',
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
    muted1: {
      color: themeWithColors.palette.grey[60],
      fontFamily: 'Moderat-Light',
      fontSize: 16,
      fontWeight: 300,
      letterSpacing: 'normal',
    },
    muted2: {
      color: themeWithColors.palette.grey,
      fontFamily: 'Moderat-Medium',
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 'normal',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Moderat-Light';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: url(${ModeratLight});
        }

        @font-face {
          font-family: 'Moderat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${ModeratRegular});
        }

        @font-face {
          font-family: 'Moderat-Medium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${ModeratMedium});
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&.Mui-disabled:not(.MuiLoadingButton-loading)': {
            backgroundColor: themeWithColors.palette.navy?.[20],
            color: themeWithColors.palette.navy?.[40],
          },
          '&.MuiLoadingButton-loading': {
            backgroundColor: themeWithColors.palette.primary.main,
            'span.MuiLoadingButton-loadingIndicator': {
              color: themeWithColors.palette.background.default,
            },
          },
          '&:hover': {
            opacity: 0.8,
          },
        },
        outlinedPrimary: {
          borderColor: themeWithColors.palette.navy?.[100],
          borderStyle: 'solid',
          borderWidth: 1,
          '&.Mui-disabled:not(.MuiLoadingButton-loading)': {
            borderColor: themeWithColors.palette.navy?.[20],
            color: themeWithColors.palette.navy?.[20],
          },
          '&:hover': {
            backgroundColor: themeWithColors.palette.background.default,
            opacity: 0.6,
          },
        },
        text: {
          '&:hover': {
            backgroundColor: 'transparent',
            // textDecoration: 'underline',
            opacity: 0.6,
          },
        },
        root: ({ ownerState }) => ({
          borderRadius: 8,
          boxShadow: 'none',
          fontSize: ownerState.size === 'small' ? 14 : 16,
          fontFamily: 'Moderat-Medium',
          letterSpacing: 'normal',
          textTransform: 'none',
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
          },
          '&:hover': {
            boxShadow: 'none',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'underlined' },
          style: {
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: themeWithColors.palette.background.default,
              opacity: 0.6,
              textDecoration: 'underline',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Moderat-Medium',
          fontWeight: 500,
        },
        root: {
          fontFamily: 'Moderat-Light',
          fontWeight: 300,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          fontFamily: 'Moderat-Medium',
          fontWeight: 400,
        },
        select: {
          fontFamily: 'Moderat-Medium',
          fontWeight: 400,
        },
        displayedRows: {
          fontFamily: 'Moderat-Medium',
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Moderat-Medium',
          fontWeight: 500,
          color: themeWithColors.palette.primary.main,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export default theme;
