import TableCell from '../../../../../../Components/TableCell/TableCell';

function RejectReasonsTableCell({ reasons }) {
  if (Array.isArray(reasons)) {
    return (
      <TableCell>
        <ul style={{ listStyle: 'inside' }}>
          {reasons.map((reason) => (
            <li>{reason}</li>
          ))}
        </ul>
      </TableCell>
    );
  }

  return <TableCell>{reasons}</TableCell>;
}

export default RejectReasonsTableCell;
