import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsData } from '../../app/Analytics/AnalyticsUrlSlice';
import './B2BAnalytics.scss';

const B2BAnalytics = () => {
  const dispatch = useDispatch();
  const { isLoading, embedUrl } = useSelector((state) => state.analytics);
  useEffect(() => {
    dispatch(getAnalyticsData());
  }, [dispatch]);
  return (
    <div className="b2b-analytics">
      {isLoading ? (
        <Typography variant="h5" className="message-style">
          Loading....
        </Typography>
      ) : (
        <iframe title="Analytics" width="100%" height="100%" src={embedUrl} frameBorder="0" />
      )}
    </div>
  );
};

export default B2BAnalytics;
