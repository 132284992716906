import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Breadcrumbs,
  Grid,
  InputLabel as MuiInputLabel,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCompanyEmployeeDetails } from '../../app/companyEmployees/companyEmployeeDetailsSlice';
import {
  fetchEmployeeClaims,
  putEmployeeClaims,
} from '../../app/companyEmployees/employeeClaimsSlice';
import { convertTimestampToDate, syncDateFormat } from '../../utils/dataFormat';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import Loader from '../Loader/Loader';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './EmployeeDetails.scss';
import SkeletonBoxContainer from './SkeletonBoxContainer';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const TextField = styled(MuiTextField)(() => ({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    width: '100%',
  },
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    marginBottom: theme.spacing(0.8),
  },
}));
const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [openSnack, setSnackOpen] = useState(false);
  const [sendToastMessage, setSendToastMessage] = useState('');
  const { isLoading, employeeDetails } = useSelector((state) => state.companyEmployeeDetails);
  const {
    isLoading: claimsLoading,
    employeeClaims,
    totalCount,
  } = useSelector((state) => state.companyEmployeeClaims);
  useEffect(() => {
    dispatch(fetchCompanyEmployeeDetails(params.employeeId));
  }, [dispatch]);
  const tabsData = ['Pending', 'Approved', 'Rejected'];
  const [status, setStatus] = useState(tabsData[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  const getStatusFlags = (currentStatus) => {
    const normalizedStatus = currentStatus.toUpperCase();
    return {
      isPending: normalizedStatus === 'PENDING',
      isApproved: normalizedStatus === 'APPROVED',
      isRejected: normalizedStatus === 'REJECTED',
    };
  };

  const { isPending, isApproved, isRejected } = getStatusFlags(status);

  const getHeadings = () => {
    switch (true) {
      case isPending:
        return ['Claim ID', 'Date requested', 'Claim amount ($)'];
      case isApproved:
        return ['ID', 'Requested by', 'Date approved', 'Claim (hours)', 'Claim amount ($)'];
      case isRejected:
        return ['Claim ID', 'Date requested', 'Claim amount ($)', 'Date rejected'];
      default:
        return [];
    }
  };
  const headingsToRender = getHeadings();
  useEffect(() => {
    const claimsParams = {
      claimStatus: status.toUpperCase(),
      limit: rowsPerPage,
      offset: page,
      employeeId: params.employeeId,
    };
    dispatch(fetchEmployeeClaims(claimsParams));
  }, [dispatch, status, rowsPerPage, page]);
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const handleChangeCreditCheckStatus = async (data) => {
    await dispatch(putEmployeeClaims(data)).unwrap();
    setSendToastMessage(`Employee claim successfully updated`);
    setSnackOpen(true);
  };
  return (
    <div className="employee-details" style={{ flex: 1, gap: '16px' }}>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb" className="company-bredcumb">
          <Link underline="hover" color="inherit" to="/b2b-companies">
            <span className="icon-wrapper">
              <ArrowBackIosIcon className="arrow-icon" />
            </span>
            Companies
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            to={`/company/${employeeDetails?.companyId}`}
            aria-current="page"
          >
            <span>{employeeDetails?.companyName}</span>
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            to={`/company/employees/${employeeDetails?.companyId}`}
            aria-current="page"
          >
            <span>Employees</span>
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            <span className="span-text">
              {employeeDetails.firstName &&
                `${employeeDetails.firstName} ${employeeDetails.lastName}`}
            </span>
          </Link>
        </Breadcrumbs>
      </div>
      <div className="title-section">
        <h1 className="title">Leave balance</h1>
        <span>
          Last synced from {employeeDetails?.integration} at{' '}
          {employeeDetails.syncDate && syncDateFormat(employeeDetails.syncDate)}
        </span>
      </div>
      {isLoading ? (
        <SkeletonBoxContainer />
      ) : (
        <div className="box-container">
          <div className="box">
            <Typography variant="h6"> Total annual leave ($)</Typography>
            <Typography variant="h5">
              $
              {employeeDetails.totalAnnualLeaveAmount &&
                formatNumberWithThousandSeparator(
                  employeeDetails.totalAnnualLeaveAmount.toFixed(2)
                )}
            </Typography>
          </div>
          <div className="box">
            <Typography variant="h6">Total annual leave (hours)</Typography>
            <Typography variant="h5">
              {employeeDetails.totalAnnualLeaveInHours &&
                formatNumberWithThousandSeparator(
                  employeeDetails.totalAnnualLeaveInHours.toFixed(2)
                )}
            </Typography>
          </div>
          <div className="box">
            <Typography variant="h6">Total claimable amount</Typography>
            <Typography variant="h5">
              $
              {employeeDetails.totalClaimableAmount &&
                formatNumberWithThousandSeparator(employeeDetails.totalClaimableAmount.toFixed(2))}
            </Typography>
          </div>
        </div>
      )}
      <div className="textfield-info">
        <Typography variant="h1" className="info-text">
          Employee details
        </Typography>
        <form>
          <div className="user-info">
            <Typography variant="h2" className="info-text">
              Basic information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel>First Name</InputLabel>
                <TextField
                  disabled
                  type="text"
                  name="firstName"
                  value={employeeDetails?.firstName}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Last Name</InputLabel>
                <TextField disabled name="lastName" type="text" value={employeeDetails?.lastName} />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <InputLabel>Payroll Email</InputLabel>
                <TextField disabled type="email" name="email" value={employeeDetails?.email} />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Address</InputLabel>
                <TextField disabled type="text" name="address" value={employeeDetails?.address} />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>TFN</InputLabel>
                <TextField disabled type="text" name="tfn" value={employeeDetails?.tfn} />
              </Grid>
            </Grid>
          </div>
          <div className="user-info" style={{ marginTop: '32px' }}>
            <Typography variant="h2" className="info-text">
              Employment information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel>Working hours (per week)</InputLabel>
                <TextField
                  disabled
                  type="text"
                  name="workingHour"
                  value={employeeDetails?.workingHoursPerWeek}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel>Hourly rate</InputLabel>
                <TextField
                  disabled
                  name="hourlyRate"
                  type="text"
                  value={employeeDetails?.hourlyRate}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <InputLabel>Total annual leave (hours)</InputLabel>
                <TextField
                  disabled
                  type="text"
                  name="annualLeave"
                  value={employeeDetails?.totalAnnualLeaveInHours}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
      <div className="title">
        <h1>Claim history</h1>
      </div>
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (claimsLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!employeeClaims.length) {
            return (
              <Typography variant="h4" className="message-style">
                No employee claim history to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {employeeClaims.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row._id}</TableCell>
                    {isApproved && (
                      <>
                        <TableCell>{row?.requestedBy}</TableCell>
                        <TableCell>{convertTimestampToDate(row.dateApproved)}</TableCell>
                        <TableCell>{row.claimHours}</TableCell>
                      </>
                    )}
                    {!isApproved && (
                      <TableCell>{convertTimestampToDate(row.dateRequested)}</TableCell>
                    )}
                    <TableCell>{formatNumberWithThousandSeparator(row.claimAmount)}</TableCell>
                    {isRejected && (
                      <TableCell>{convertTimestampToDate(row.dateRejected)}</TableCell>
                    )}
                    {isPending && (
                      <TableCell>
                        <div className="button-action-style">
                          <button
                            type="button"
                            className="reject-button"
                            onClick={() =>
                              handleChangeCreditCheckStatus({
                                action: 'REJECTED',
                                claimId: row._id,
                              })
                            }
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            className="approve-button"
                            onClick={() =>
                              handleChangeCreditCheckStatus({
                                action: 'APPROVED',
                                claimId: row._id,
                              })
                            }
                          >
                            Approve
                          </button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {sendToastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmployeeDetails;
