import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React from 'react';
import './ButtonGroup.scss';

function stringToLowerCaseDashed(str) {
  // Convert string to lowercase
  str = str.toLowerCase();

  // Replace spaces with dashes
  str = str.replace(/\s+/g, '-');

  return str;
}

const ButtonGroup = ({ buttons, handleSelectClick }) => {
  const handleClick = (e, name) => {
    handleSelectClick(e, stringToLowerCaseDashed(name));
  };
  return (
    <div className="button-row">
      {buttons.map((button) => (
        <button
          key={button.name}
          onClick={(e) => handleClick(e, button.name)}
          type="button"
          className="custom-button"
        >
          <span>{button.name}</span> <KeyboardArrowDownIcon className="arrow-icon" />
        </button>
      ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  handleSelectClick: PropTypes.func.isRequired,
};

export default ButtonGroup;
