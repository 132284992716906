import { Grid, InputLabel as MuiInputLabel, TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import DateInput from '../../DateInput';
import BaseDialog from '../BaseDialog/BaseDialog';

const TextField = styled(MuiTextField)(() => ({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    width: '100%',
  },
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    marginBottom: theme.spacing(0.8),
  },
}));

const EmployeeApproveDialog = (props) => {
  const { open, setOpen, value, setValue, handleApply, selectedUser, ...other } = props;
  const inputRef = React.useRef(null);
  const numberFields = ['workingHoursPerWeek', 'hourlyRate', 'totalAnnualLeaveInHours'];

  useEffect(() => {
    setValue((prev) => ({ ...prev, ...selectedUser }));
  }, [selectedUser]);

  const handleChange = (e) => {
    const { name } = e.target;
    let inputValue = e.target.value;
    if (numberFields.includes(name)) {
      inputValue = Number(inputValue);
    }
    setValue((prev) => ({ ...prev, [name]: inputValue }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDate = (dateStr) => {
    setValue((prev) => ({ ...prev, payday: dateStr }));
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title="Employee details"
      actionButtons={[{ label: 'Submit', variant: 'contained', onClick: handleApply }]}
      ref={inputRef}
      {...other}
    >
      <form>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel>First Name</InputLabel>
            <TextField name="firstName" value={selectedUser?.firstName} onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Last Name</InputLabel>
            <TextField name="lastName" value={selectedUser?.lastName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Email</InputLabel>
            <TextField
              name="email"
              value={selectedUser?.email}
              type="email"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Company Name</InputLabel>
            <TextField name="companyName" onChange={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Hourly rate</InputLabel>
            <TextField
              name="hourlyRate"
              type="number"
              inputProps={{ min: 0 }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Total annual leave (in hours)</InputLabel>
            <TextField
              name="totalAnnualLeaveInHours"
              type="number"
              inputProps={{ min: 0 }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Cash out limit</InputLabel>
            <TextField
              name="cashOutLimit"
              type="number"
              inputProps={{ min: 0 }}
              onChange={handleChange}
            />
          </Grid>
          <DateInput
            label="Their next payday"
            name="payday"
            required
            value={selectedUser?.payday}
            setValue={handleSetDate}
            minDate={dayjs()}
            maxDate={dayjs().add(1, 'month')}
          />
        </Grid>
      </form>
    </BaseDialog>
  );
};

EmployeeApproveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
};

export default EmployeeApproveDialog;
