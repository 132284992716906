import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchRecipientsList = createAsyncThunk(
  'recipients/fetchRecipientsList',
  async (subscriberIds, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/data/user-list?ids=${subscriberIds}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const notificationsRecipientsSlice = createSlice({
  name: 'recipients',
  initialState: {
    recipientsDetails: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecipientsList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRecipientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.recipientsDetails = action.payload;
      })
      .addCase(fetchRecipientsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.recipientsDetails = {};
      });
  },
});

export default notificationsRecipientsSlice.reducer;
