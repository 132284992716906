import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  fetchUserRepositories,
  setUserRepositoryBySearchToken,
} from '../../app/userRepository/userRepositorySlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import getItemPosition from '../../utils/getItemPosition';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import { amountReuquestedSelect, companySelect } from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AmountSliderDialog from '../Dialog/AmountSliderDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import './UserRepositories.scss';

const UserRepository = () => {
  const dispatch = useDispatch();
  const { isLoading, repositories, totalCount } = useSelector((state) => state.userRepositories);
  const filterButtons = [
    // { name: 'Company' },
    { name: 'Claimable Amount' },
  ];
  const [searchToken, setSearchToken] = useState();
  const headings = [
    'User Id',
    'Employee',
    'Company',
    'Total Annual Leave ($)',
    'Total Annual Leave (hours)',
    'Claimable Amount ($)',
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [amountSelectModalOpen, setAmountSelectModalOpen] = useState(false);
  const [companySelectModalOpen, setCompanySelectModalOpen] = useState(false);
  const [amountValues, setAmountValues] = useState([]);
  const [companyValues, setCompanyValues] = useState([]);
  const [options, setOptions] = useState([]);

  const [applyClicked, setApplyClicked] = useState(false);
  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);

    if (type === 'company') {
      setTitle('Company');
      setOptions(companySelect);
      setCompanySelectModalOpen(!companySelectModalOpen);
    }
    if (type === 'claimable-amount') {
      setTitle('Claimable amount');
      setOptions(amountReuquestedSelect);
      setAmountSelectModalOpen(!amountSelectModalOpen);
    }
  };

  const handleCompanySelectApply = () => {
    setCompanySelectModalOpen(false);
    setApplyClicked(true);
  };
  const handleAmountSelectApply = () => {
    setAmountSelectModalOpen(false);
    setApplyClicked(true);
  };

  useEffect(() => {
    dispatch(
      fetchUserRepositories({
        limit: rowsPerPage,
        offset: page,
        claimableAmount: amountValues.length ? amountValues.join('-') : 0,
      })
    );
  }, [dispatch, rowsPerPage, page]);
  useEffect(() => {
    if (applyClicked) {
      dispatch(
        fetchUserRepositories({
          limit: rowsPerPage,
          offset: page,
          company: companyValues.join('$'),
          claimableAmount: amountValues.length ? amountValues.join('-') : 0,
        })
      );
      setApplyClicked(false);
    }
  }, [applyClicked]);
  useDidUpdateEffect(() => {
    dispatch(setUserRepositoryBySearchToken({ token: searchToken }));
  }, [searchToken]);
  return (
    <div className="root-div" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>User repository</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterButtons} handleSelectClick={handleSelectClick} />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!repositories.length) {
            return (
              <Typography variant="h4" className="message-style">
                No users to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headings.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {repositories.map((row) => (
                  <TableRow
                    key={row.userId}
                    component={Link}
                    to={`/user-details/${row.userId}`}
                    hover
                  >
                    <TableCell>{row.userId}</TableCell>
                    <TableCell>{`${row.firstName || ''} ${row.lastName || ''}`.trim()}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>
                      {row.annualLeaveAmountValue &&
                        formatNumberWithThousandSeparator(
                          Number(row.annualLeaveAmountValue).toFixed(2)
                        )}
                    </TableCell>
                    <TableCell>
                      {row.annualLeaveTime &&
                        formatNumberWithThousandSeparator(row.annualLeaveTime?.toFixed(2))}
                    </TableCell>
                    <TableCell>
                      {row.claimableAmount &&
                        formatNumberWithThousandSeparator(row.claimableAmount?.toFixed(2))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <CheckboxDialog
        open={companySelectModalOpen}
        setOpen={setCompanySelectModalOpen}
        values={companyValues}
        setValues={setCompanyValues}
        options={options}
        position={position}
        handleApply={handleCompanySelectApply}
        title={title}
      />
      <AmountSliderDialog
        open={amountSelectModalOpen}
        setOpen={setAmountSelectModalOpen}
        values={amountValues}
        setValues={setAmountValues}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handleAmountSelectApply}
        title={title}
      />
    </div>
  );
};

export default UserRepository;
