import { InputLabel as MuiInputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInputLabel = styled(MuiInputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    marginBottom: theme.spacing(0.8),
  },
}));

export default StyledInputLabel;
