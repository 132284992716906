import { convertTimestampToDate } from './dataFormat';

export const calculatePaidItemTotal = (items) => {
  const total = items.reduce((acc, item) => {
    if (item.isPaid) {
      return acc + (item.itemTotal || 0);
    }
    return acc;
  }, 0);
  return total > 0 ? total / 100 : 0;
};

export const centsToDollars = (cents) => {
  return cents === 0 ? 0 : cents / 100;
};

export const findDueDateOfFirstUnpaid = (items) => {
  const unpaidItem = items.find((item) => !item.isPaid);
  return unpaidItem ? convertTimestampToDate(unpaidItem.dueDate.iso || unpaidItem.dueDate) : null;
};
export const colorBasedOnDate = (items) => {
  const unpaidItem = items.find((item) => !item.isPaid);
  const dueDate = unpaidItem?.dueDate.iso || unpaidItem.dueDate;
  const date = new Date();
  const currentDate = date.toISOString();
  return dueDate < currentDate ? 'red' : '#101010';
};

export const getBankModalPosition = (e) => {
  const btnRect = e.currentTarget.getBoundingClientRect();
  const btnTop = btnRect.top + window.scrollY;
  const btnLeft = btnRect.left + window.scrollX;
  return { top: btnTop + btnRect.height, left: btnLeft };
};

export const countMyTaskPropertiesLength = (obj) => {
  return Object.values(obj).reduce((count, value) => {
    return count + (value > 0 ? 1 : 0);
  }, 0);
};

export const getMarkModalPosition = (e) => {
  const btnRect = e.currentTarget.getBoundingClientRect();
  const btnTop = btnRect.top + window.scrollY;
  const btnLeft = btnRect.left + window.scrollX - 160;
  return { top: btnTop + btnRect.height, left: btnLeft };
};

export const formatNumberWithThousandSeparator = (number) => {
  let numberString = number.toString();
  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numberString;
};

export const calculatePercentageObject = (businessData) => {
  // const total =
  //   businessData.paidPayment + businessData.overduePayment + businessData.upcomingPayment;
  const total = businessData?.totalCollection;
  if (!total) {
    return {
      overduePaymentPercentage: 0.0,
      paidPaymentPercentage: 0.0,
      upcomingPaymentPercentage: 0.0,
    };
  }

  const overduePaymentPercentage = (businessData.overduePayment / total) * 100.0;
  const paidPaymentPercentage = (businessData.paidPayment / total) * 100.0;
  const upcomingPaymentPercentage = (businessData.upcomingPayment / total) * 100.0;

  return {
    overduePaymentPercentage,
    paidPaymentPercentage,
    upcomingPaymentPercentage,
  };
};

export const joinNotificationsSubscriberIds = (notifications) => {
  const subscribedIdsArray = notifications
    .filter((notification) => notification.subscriberId && notification.subscriberId[0] !== 'all')
    .map((notification) => notification.subscriberId.join('$'));

  const subscribedIds = subscribedIdsArray.join('$');

  return subscribedIds;
};

export const getSubscribersNames = (allRecipientUsers, subscribersIds) => {
  const subscribersNames = subscribersIds
    .map((userId) => {
      const user = allRecipientUsers.find((recipientUser) => recipientUser._id === userId);
      return user ? user.name : null;
    })
    .filter((name) => name !== null)
    .join(', ');

  const truncatedNames =
    subscribersNames.length > 22 ? `${subscribersNames.slice(0, 22)}..` : subscribersNames;

  return truncatedNames;
};
