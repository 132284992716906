export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const api = process.env.REACT_APP_API_URL;
export const appEnv = process.env.REACT_APP_ENV;
export const analyticsUrl = process.env.REACT_APP_ANALYTICS_URL;
export const businessApiUrl = process.env.REACT_APP_BUSINESS_API_URL;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const notificationTypes = {
  INSTANT: 'INSTANT',
  SCHEDULED_ONCE: 'SCHEDULED_ONCE',
};

export const notificationStatuses = {
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  DELETED: 'DELETED',
  FAILED: 'FAILED',
};
