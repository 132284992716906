import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import React from 'react';
import { useSelector } from 'react-redux';

const NewNotificationDot = ({ item }) => {
  const { task } = useSelector((state) => state.b2bDashboardMyTask);
  const { task: b2cTask } = useSelector((state) => state.b2cDashboardMyTask);
  //   let isNewNotification = false;

  const isNewNotification =
    (task.creditCheckPendingCount && item.link === '/b2b-credit-check') ||
    (task.employeePaymentsPendingCount && item.link === '/b2b-employee-payments') ||
    (b2cTask.pendingPayslipCount && item.link === '/b2c-payslips') ||
    (b2cTask.contactPendingCount && item.link === '/b2c-claims') ||
    (b2cTask.creditCheckPendingCount && item.link === '/b2c-claims') ||
    (b2cTask.employeePaymentsPendingCount && item.link === '/b2c-employee-payments');

  return isNewNotification ? (
    <FiberManualRecordRoundedIcon
      style={{
        color: '#5e17eb',
        height: '10px',
        width: '10px',
      }}
    />
  ) : (
    <div />
  );
};

export default NewNotificationDot;
