import React from 'react';
import { Tooltip, tooltipClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import './CreditCheckPopup.scss';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: '16rem',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
}));

const TooltipContent = ({ row }) => (
  <div className="credit-check-popup">
    <Typography className="title">Company Details</Typography>
    <p className="credit-check-data">Name: {row.companyName}</p>
    <p className="credit-check-data">Integration: {row.integration}</p>
    <p className="credit-check-data">FTE: {row.fte}</p>
    <p className="credit-check-data">
      Admin: {row.adminFirstName} {row.adminLastName}
    </p>
  </div>
);

const CreditCheckPopup = ({ children, row }) => (
  <StyledTooltip title={<TooltipContent row={row} />}>
    <span>{children}</span>
  </StyledTooltip>
);

export default CreditCheckPopup;
