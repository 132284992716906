import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icon } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { b2bMenu, b2cMenu, sidebarBottomMenu, sidebarTopMenu } from '../../utils/menu';
import { useLogout } from '../Auth/AuthContext';
import './Sidebar.scss';
import NewNotificationDot from './NewNotificationDot';

const drawerWidth = 270;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingLeft: theme.spacing(1),
}));

const ListItemText = styled((props) => <MuiListItemText {...props} />)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontFamily: 'Moderat-Medium',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}));

const CustomListItem = ({ item, onClick }) => {
  const { pathname } = useLocation();
  return (
    <ListItem
      className={`${pathname === item.link ? 'active-item' : ''}`}
      key={item.title}
      onClick={onClick}
      disablePadding
    >
      <ListItemButton>
        <ListItemIcon className="list-item-icon">
          <Icon color="primary">{item.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={item.title} />
        <NewNotificationDot item={item} />
      </ListItemButton>
    </ListItem>
  );
};

export default function Sidebar() {
  const [expandedB2B, setExpandedB2B] = React.useState(true);
  const [expandedB2C, setExpandedB2C] = React.useState(true);
  const logout = useLogout();

  const onMenuItemClickHandler = (title) => {
    if (title.toLowerCase() === 'logout') {
      logout();
    }
  };

  return (
    <Box className="sidebar-wrapper" sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            paddingRight: '8px',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box className="logo-wrapper">
          <img className="logo-img" src="/Logo.svg" alt="subi-logo" />
        </Box>
        <List style={{ padding: '0px 8px' }}>
          {sidebarTopMenu.map((item) => (
            <Link key={item.title} className="link" to={item.link}>
              <CustomListItem item={item} />
            </Link>
          ))}
        </List>
        <Accordion
          expanded={expandedB2B}
          onChange={() => setExpandedB2B((prev) => !prev)}
          className="b2b"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" color="primary">
              <Box className="tire-img-wrapper">
                <img src="/img/water-tire.png" alt="water-tire" />
              </Box>
              B2B
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {b2bMenu.map((item) => (
                <Link key={item.title} className="link" to={item.link}>
                  <CustomListItem item={item} />
                </Link>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedB2C}
          onChange={() => setExpandedB2C((prev) => !prev)}
          className="b2c"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" color="primary">
              <Box className="tire-img-wrapper">
                <img src="/img/water-tire.png" alt="water-tire" />
              </Box>
              B2C
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {b2cMenu.map((item) => (
                <Link key={item.title} className="link" to={item.link}>
                  <CustomListItem item={item} />
                </Link>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        <List style={{ padding: '0px 8px' }}>
          {sidebarBottomMenu.map((item) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <div key={item.title}>
              {item.link ? (
                <Link className="link" to={item.link}>
                  <CustomListItem item={item} />
                </Link>
              ) : (
                <CustomListItem item={item} onClick={() => onMenuItemClickHandler(item.title)} />
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
