import ClearIcon from '@mui/icons-material/Clear';
import { Card, CardContent, Modal, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import './ManagerInfoDialog.scss';

const ManagerInfoDialog = (props) => {
  const { open, setOpen, position, managerInfo } = props;
  const onClose = () => {
    setOpen(false);
  };

  if (!managerInfo) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="manager-details-dialog"
      style={{ position: 'absolute', top: position.top, left: position.left }}
    >
      <Card variant="outlined">
        <CardContent className="manager-info">
          <div className="title-icon">
            <Typography className="title">{managerInfo.name}</Typography>
            <IconButton size="small" onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </div>
          <p className="info-data">{managerInfo.email}</p>
          <p className="info-data">{managerInfo.linkedIn}</p>
          <p className="info-data">{managerInfo.phone}</p>
          <p className="info-data">{managerInfo?.address}</p>
        </CardContent>
      </Card>
    </Modal>
  );
};
ManagerInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
  managerInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
};
export default ManagerInfoDialog;
