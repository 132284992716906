import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePost, privatePut } from '../../utilities/apiCaller';

export const fetchB2CEmployees = createAsyncThunk(
  'b2cEmployee/fetchB2CEmployees',
  async (
    { status, limit, offset, company, approvedDate, paidDate, paidAmount, requestAmount },
    { rejectWithValue }
  ) => {
    try {
      let url = `/data/b2c/employee-payments?claimStatus=${status}&limit=${limit}&offset=${
        offset + 1
      }`;
      if (company) {
        url += `&company=${company}`;
      }
      if (approvedDate) {
        url += `&approvedDate=${approvedDate}`;
      }
      if (requestAmount) {
        url += `&amount=${requestAmount}`;
      }
      if (paidDate) {
        url += `&paidDate=${paidDate}`;
      }
      if (paidAmount) {
        url += `&amount=${paidAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateB2CClaimById = createAsyncThunk(
  'claims/updateB2CClaimById',
  async (claimId, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2c/employee-payments', { claimId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getB2CEmployeePaymentsAgreementSignUrl = createAsyncThunk(
  'b2cEmployee/getEmployeePaymentsAgreementSignedUrl',
  async ({ agreementInfo }, { rejectWithValue }) => {
    try {
      const res = await privatePost('/data/view-agreement', agreementInfo);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const b2cEmployeeSlice = createSlice({
  name: 'b2cEmployee',
  initialState: {
    b2cEmployees: [],
    unfiltredb2cEmployees: null,
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    setEmployeePaymentsBySearchToken: (state, action) => {
      if (!state.unfiltredb2cEmployees) {
        state.unfiltredb2cEmployees = [...state.b2cEmployees];
      }
      const { token } = action.payload;
      state.b2cEmployees = state.unfiltredb2cEmployees.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2CEmployees.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2CEmployees.fulfilled, (state, action) => {
        state.b2cEmployees = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2CEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.b2cEmployees = [];
        state.totalCount = 0;
      })
      .addCase(updateB2CClaimById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateB2CClaimById.fulfilled, (state, action) => {
        const { _id: claimId } = action.payload;
        state.b2cEmployees = state.b2cEmployees.filter((employee) => employee.claimId !== claimId);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateB2CClaimById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export const { setEmployeePaymentsBySearchToken } = b2cEmployeeSlice.actions;
export default b2cEmployeeSlice.reducer;
