import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchCompanyEmployeeDetails = createAsyncThunk(
  'companyEmployeeDetails/fetchCompanyEmployeeDetails',
  async (employeeId, { rejectWithValue }) => {
    try {
      const url = `/data/b2b/company-employees/employee-details?employeeId=${employeeId}`;
      const response = await privateGet(url);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const companyEmployeeDetailsSlice = createSlice({
  name: 'companyEmployeeDetails',
  initialState: {
    employeeDetails: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyEmployeeDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyEmployeeDetails.fulfilled, (state, action) => {
        state.employeeDetails = action.payload.data;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyEmployeeDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.employeeDetails = {};
      });
  },
});

export default companyEmployeeDetailsSlice.reducer;
