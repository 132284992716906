import { Icon } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import MultiProgress from 'react-multi-progress';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchB2CCompanyInfo } from '../../app/b2cDashboard/b2cCompanyInfoSlice';
import { fetchB2CDashboardBusinessRepayments } from '../../app/b2cDashboard/b2cDashboardBusinessSlice';
import { fetchB2CTasks } from '../../app/b2cDashboard/b2cMyTaskSlice';
import {
  calculatePercentageObject,
  countMyTaskPropertiesLength,
  formatNumberWithThousandSeparator,
} from '../../utils/helperMethod';
import './B2CDashboard.scss';

const B2CDashboard = () => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.loginReducer.authResult);
  const { isLoading: businessLoading, dashboardBusinessRepayments } = useSelector(
    (state) => state.b2cDashboardBusinessRepayments
  );
  const { isLoading: infoLoading, companyInfo } = useSelector((state) => state.b2cCompanyInfo);
  const { isLoading: taskLoading, task } = useSelector((state) => state.b2cDashboardMyTask);
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchB2CDashboardBusinessRepayments(accessToken));
      dispatch(fetchB2CTasks(accessToken));
      dispatch(fetchB2CCompanyInfo(accessToken));
    }
  }, [dispatch, accessToken]);
  useEffect(() => {
    const profileData = localStorage.getItem('profileResult');
    if (profileData) {
      const parsedData = JSON.parse(profileData);
      setUser(parsedData);
    }
  }, []);
  const [percentageValues, setPercentageValues] = useState({
    overduePaymentPercentage: 0.0,
    paidPaymentPercentage: 0.0,
    upcomingPaymentPercentage: 0.0,
  });
  useEffect(() => {
    if (!businessLoading) {
      const percentages = calculatePercentageObject(dashboardBusinessRepayments);
      setPercentageValues(percentages);
    }
  }, [businessLoading, dashboardBusinessRepayments]);
  return (
    <div className="root-dashboard" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Hi {user && user.given_name} 👋🏼</h1>
      </div>

      <div>
        {infoLoading ? (
          <div className="dashboard-container">
            {[...Array(4)].map(() => (
              <div className="dashboard-skeleton">
                <Skeleton variant="rectangular" height={85} style={{ borderRadius: '8px' }} />
              </div>
            ))}
          </div>
        ) : (
          <div className="dashboard-container">
            <div className="dashboard-box">
              <h2>{companyInfo ? companyInfo?.companyCount : 0}</h2>
              <span>Companies</span>
            </div>
            <div className="dashboard-box">
              <h2>{companyInfo?.approvedClaimCount}</h2>
              <span>Total approved claims</span>
            </div>
            <div className="dashboard-box">
              <h2>
                $
                {companyInfo?.avgClaimAmount &&
                  formatNumberWithThousandSeparator(companyInfo?.avgClaimAmount?.toFixed(2))}
              </h2>
              <span>Avg. claim amount</span>
            </div>
            <div className="dashboard-box">
              <h2>
                $
                {companyInfo?.businessRepaymentsOutstanding &&
                  formatNumberWithThousandSeparator(
                    companyInfo?.businessRepaymentsOutstanding?.toFixed(2)
                  )}
              </h2>
              <span>Outstanding</span>
            </div>
          </div>
        )}
      </div>
      <div className="new-row">
        <div className="new-row-column">
          {taskLoading ? (
            <Skeleton variant="rectangular" height={331} />
          ) : (
            <>
              <div className="task">My Task({countMyTaskPropertiesLength(task)})</div>
              {task.pendingPayslipCount !== 0 && (
                <div className="task-row">
                  <div className="task-logo">
                    <ListItemIcon className="list-item-icon">
                      <Icon color="primary">description</Icon>
                    </ListItemIcon>
                  </div>
                  <div className="task-content">
                    {task.pendingPayslipCount} payslips need to be reviewed
                  </div>
                  <button type="button" className="review-button">
                    <Link to="/b2c-payslips">Review</Link>
                  </button>
                </div>
              )}
              {task.creditCheckPendingCount !== 0 && (
                <div>
                  <Divider />
                  <div className="task-row">
                    <div className="task-logo">
                      <ListItemIcon className="list-item-icon">
                        <Icon color="primary">account_balance</Icon>
                      </ListItemIcon>
                    </div>
                    <div className="task-content">
                      {task.creditCheckPendingCount} pending companies need credit check
                    </div>
                    <button type="button" className="review-button">
                      <Link to="/b2c-claims?tab=2">Review</Link>
                    </button>
                  </div>
                </div>
              )}
              {task.contactPendingCount !== 0 && (
                <div>
                  <Divider />
                  <div className="task-row">
                    <div className="task-logo">
                      <ListItemIcon className="list-item-icon">
                        <Icon color="primary">support</Icon>
                      </ListItemIcon>
                    </div>
                    <div className="task-content">
                      {task.contactPendingCount} managers/directors need to be contacted
                    </div>
                    <button type="button" className="review-button">
                      <Link to="/b2c-claims?tab=1">Review</Link>
                    </button>
                  </div>
                </div>
              )}
              {task.employeePaymentsPendingCount !== 0 && (
                <div>
                  <Divider />
                  <div className="task-row">
                    <div className="task-logo">
                      <ListItemIcon className="list-item-icon">
                        <Icon color="primary">payments</Icon>
                      </ListItemIcon>
                    </div>
                    <div className="task-content">
                      {task.employeePaymentsPendingCount} employees need to be paid
                    </div>
                    <button type="button" className="review-button">
                      <Link to="/b2c-employee-payments">Review</Link>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="new-row-column">
          {businessLoading ? (
            <Skeleton variant="rectangular" height={200} />
          ) : (
            <>
              <div className="task">Business repayments</div>
              <div className="total-collections" style={{ marginInline: '16px', gap: '16px' }}>
                <span
                  style={{
                    color: '#707070',
                  }}
                >
                  Total collections
                </span>
                <div className="display-amount">
                  $
                  {dashboardBusinessRepayments?.totalCollection &&
                    formatNumberWithThousandSeparator(dashboardBusinessRepayments?.totalCollection)}
                </div>
              </div>
              <div className="progress-line">
                {!businessLoading &&
                Object.values(percentageValues).some((value) => value !== 0) ? (
                  <MultiProgress
                    height="8px"
                    elements={[
                      {
                        value: percentageValues.paidPaymentPercentage,
                        color: '#00804A',
                      },
                      {
                        value: percentageValues.overduePaymentPercentage,
                        color: '#F33F3F',
                      },
                      {
                        value: percentageValues.upcomingPaymentPercentage,
                        color: '#FFAD2B',
                      },
                    ]}
                  />
                ) : (
                  <MultiProgress
                    height="8px"
                    elements={[
                      {
                        value: 200,
                        color: 'gray',
                      },
                    ]}
                  />
                )}
              </div>
              <div className="amount-with-status">
                <div className="payment-column">
                  <div className="dot-and-status">
                    <div className="dot paid" />
                    <div className="status-text">
                      <span>Paid</span>
                    </div>
                  </div>
                  <div className="amount">
                    $
                    {dashboardBusinessRepayments?.paidPayment &&
                      formatNumberWithThousandSeparator(dashboardBusinessRepayments?.paidPayment)}
                  </div>
                </div>
                <div className="payment-column">
                  <div className="dot-and-status">
                    <div className="dot overdue" />
                    <div className="status-text">
                      <span>Overdue</span>
                    </div>
                  </div>
                  <div className="amount">
                    $
                    {dashboardBusinessRepayments?.overduePayment &&
                      formatNumberWithThousandSeparator(
                        dashboardBusinessRepayments?.overduePayment
                      )}
                  </div>
                </div>
                <div className="payment-column">
                  <div className="dot-and-status">
                    <div className="dot upcoming" />
                    <div className="status-text">
                      <span>Upcoming</span>
                    </div>
                  </div>
                  <div className="amount">
                    $
                    {dashboardBusinessRepayments?.upcomingPayment &&
                      formatNumberWithThousandSeparator(
                        dashboardBusinessRepayments?.upcomingPayment
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default B2CDashboard;
