import { Grid, InputLabel, TextField, styled } from '@mui/material';
import { AuBankAccountElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getPaymentIntent, postAttachUser } from '../../../app/b2cPayments/b2cPaymentsSlice';
import BaseDialog from '../BaseDialog/BaseDialog';

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    marginBottom: theme.spacing(0.8),
  },
}));

const StyledTextField = styled(TextField)(() => ({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    width: '100%',
  },
}));

const AU_BANK_ACCOUNT_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const AU_BANK_ACCOUNT_ELEMENT_OPTIONS = {
  style: AU_BANK_ACCOUNT_STYLE,
  disabled: false,
  hideIcon: false,
  iconStyle: 'default',
};

const AddFallbackDialog = (props) => {
  const { isOpen, userId, email, fullName, handleClose, ...rest } = props;
  const inputRef = React.useRef(null);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [paymentIntentData, setPaymentIntentData] = useState(undefined);
  const [formData, setFormData] = useState({
    accountName: fullName,
  });

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!email) return;
    (async () => {
      const response = await getPaymentIntent();
      setPaymentIntentData(response);
    })();
  }, [email]);

  const handleChange = (e) => {
    const { name } = e.target;
    const inputValue = e.target.value;
    setFormData((prev) => ({ ...prev, [name]: inputValue }));
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setSubmitIsLoading(true);
    const auBankAccount = elements.getElement(AuBankAccountElement);
    const result = await stripe.confirmAuBecsDebitSetup(
      paymentIntentData.setupIntent.clientSecret,
      {
        payment_method: {
          au_becs_debit: auBankAccount,
          billing_details: {
            name: formData.accountName,
            email,
          },
        },
      }
    );

    if (result.error) {
      // eslint-disable-next-line no-alert
      window.alert(`ERROR : ${result.error.message}`);
    } else {
      await postAttachUser({ userId, setupIntentId: paymentIntentData.setupIntent.id });
      setPaymentIntentData(undefined);
      setSubmitIsLoading(false);
      handleClose();
    }
  };

  return (
    <BaseDialog
      open={isOpen}
      handleClose={() => {
        setPaymentIntentData(undefined);
        setSubmitIsLoading(false);
        handleClose();
      }}
      hasCloseIcon
      title="Fallback option"
      actionButtons={[
        {
          label: 'Submit',
          variant: 'contained',
          onClick: handleSubmit,
          isLoading: submitIsLoading,
        },
      ]}
      ref={inputRef}
      {...rest}
    >
      {!paymentIntentData ? (
        <p>Loading...</p>
      ) : (
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledInputLabel>Email</StyledInputLabel>
              <StyledTextField name="email" value={email} disabled />
            </Grid>
            <Grid item xs={12}>
              <StyledInputLabel>Account name</StyledInputLabel>
              <StyledTextField
                name="accountName"
                value={formData.accountName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInputLabel>Bank Account (New stripe Payment Method)</StyledInputLabel>
              {/** @ts-ignore */}
              <AuBankAccountElement options={AU_BANK_ACCOUNT_ELEMENT_OPTIONS} />
            </Grid>
            <Grid item xs={12}>
              <p>
                By providing the client bank account details, he should agree to this Direct Debit
                Request and the
                <a href="https://stripe.com/au-becs-dd-service-agreement/legal">
                  Direct Debit Request service agreement
                </a>
                , and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User
                ID number 507156 (“Stripe”) to debit your account through the Bulk Electronic
                Clearing System (BECS) on behalf of SUBI Pty Ltd (the “Merchant”) for any amounts
                separately communicated to you by the Merchant. You certify that you are either an
                account holder or an authorised signatory on the account listed above.
              </p>
            </Grid>
          </Grid>
        </form>
      )}
    </BaseDialog>
  );
};

AddFallbackDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddFallbackDialog;
