import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchCompanyInfo = createAsyncThunk(
  'companyInfo/fetchCompanyInfo',
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/data/b2b/company/details?companyId=${companyId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchCompanyList = createAsyncThunk(
  'companyInfo/fetchCompanyList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/data/company-list`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const companyInfoSlice = createSlice({
  name: 'companyInfo',
  initialState: {
    companyDetailsData: {},
    companyList: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyInfo.fulfilled, (state, action) => {
        state.companyDetailsData = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.companyDetailsData = null;
      })
      .addCase(fetchCompanyList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        state.companyList = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.companyList = [];
      });
  },
});

export default companyInfoSlice.reducer;
