import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLogout } from './AuthContext';

const checkIfTokenExpired = ({ loginReducer, logout }) => {
  if (loginReducer.tokenExpireTime <= new Date()) {
    logout();
  }
};

export const TokenChecker = ({ children }) => {
  const location = useLocation();
  const loginReducer = useSelector((state) => state.loginReducer);
  const logout = useLogout();

  useEffect(() => {
    checkIfTokenExpired({ loginReducer, logout });
  }, [location.pathname, logout, loginReducer]);

  return <div>{children}</div>;
};
