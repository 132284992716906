import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import React from 'react';
import './BoxedRadioOption.scss';

const BoxedRadioOption = ({ value, label, subTitle, ...rest }) => {
  return (
    <Box className="boxed-radio-option-wrapper" {...rest}>
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={
          <div>
            {label}
            <Typography className="subtitle">{subTitle}</Typography>
          </div>
        }
      />
    </Box>
  );
};

export default BoxedRadioOption;
