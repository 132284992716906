import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import * as React from 'react';
import BaseSelectModal from './BaseSelectModal';

const commonStyles = {
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
};

const AmountSliderDialog = (props) => {
  const {
    values,
    setValues,
    open,
    setOpen,
    title,
    position,
    minValue,
    maxValue,
    handleApply,
    ...other
  } = props;

  const [value, setValue] = React.useState([minValue, maxValue]);

  const handleChange = (event, newValue) => {
    setValues(newValue);
    setValue(newValue);
  };

  const handleReset = () => {
    setValues([]);
    setValue([minValue, maxValue]);
  };

  // const handleMaxInputChange = (event) => {
  //   const newMaxValue = parseInt(event.target.value, 10);
  //   if (!Number.isNaN(newMaxValue) && newMaxValue >= value[0]) {
  //     setValue([value[0], newMaxValue]);
  //   }
  // };

  return (
    <BaseSelectModal
      title={title}
      open={open}
      setOpen={setOpen}
      handleReset={handleReset}
      position={position}
      handleApply={handleApply}
      {...other}
    >
      <Box>
        <div
          style={{
            display: 'flex',
            gap: '58px',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        >
          <TextField
            label="Min"
            type="number"
            value={value[0]}
            onChange={(event) => handleChange(event, [event.target.value, value[1]])}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
            }}
            style={commonStyles}
            size="small"
          />
          <TextField
            label="Max"
            type="number"
            value={value[1]}
            onChange={(event) => handleChange(event, [value[0], event.target.value])}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 1,
            }}
            size="small"
          />
        </div>

        <Box display="flex" alignItems="center">
          <Slider
            getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
            value={value}
            // defaultValue={[minValue, maxValue]}
            onChange={handleChange}
            min={minValue}
            max={maxValue}
            valueLabelDisplay="auto"
            disableSwap
          />
        </Box>
      </Box>
    </BaseSelectModal>
  );
};

AmountSliderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValues: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
};

export default AmountSliderDialog;
