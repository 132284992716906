import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCompanies, setCompaniesBySearchToken } from '../../app/companiesb2b/companySlice';
import {
  getB2BCreditCheckSignedUrl,
  uploadCreditCheckDocFromCompany,
} from '../../app/creditCheckb2b/creditCheckBtoBSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { convertTimestampToDate } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import {
  fteCountSelect,
  industrySelect,
  integrationSelect,
  locationSelect,
} from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import UploadDialog from '../Dialog/UploadDialog/UploadDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import './Companies.scss';

const Companies = () => {
  const dispatch = useDispatch();
  const { isLoading, companies, totalCount, error } = useSelector((state) => state.companies);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const filterButtons = [
    { name: 'Joined In' },
    { name: 'Industry' },
    { name: 'Location' },
    { name: 'FTE Count' },
    { name: 'Integration' },
  ];
  const headings = [
    'Date Joined',
    'Name',
    'ABN',
    'Industry',
    'Location',
    'Credit Check',
    'FTE Count',
    'Integration',
    '',
    'Features',
  ];

  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [dateSelectModalOpen, setDateSelectModalOpen] = useState(false);
  const [locationSelectModalOpen, setLocationSelectModalOpen] = useState(false);
  const [industrySelectModalOpen, setIndustrySelectModalOpen] = useState(false);
  const [integrationSelectModalOpen, setIntegrationSelectModalOpen] = useState(false);
  const [fteCountSelectModalOpen, setFteCountSelectModalOpen] = useState(false);
  const [selectIndustryValues, setSelectIndustryValues] = useState([]);
  const [selectLocationValues, setSelectLocationValues] = useState([]);
  const [selectIntegrationValues, setSelectIntegrationValues] = useState([]);
  const [selectFteCountValues, setSelectFteCountValues] = useState([]);
  const [applyClicked, setApplyClicked] = useState(false);
  const [options, setOptions] = useState([]);
  const [timeframe, setTimeframe] = useState('before');
  const [joinDate, setDate] = useState(dayjs(Date.now()));
  const [searchToken, setSearchToken] = useState();
  const [file, setFile] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'industry') {
      setTitle('Industry');
      setOptions(industrySelect);
      setIndustrySelectModalOpen(!industrySelectModalOpen);
    }
    if (type === 'location') {
      setTitle('Location');
      setOptions(locationSelect);
      setLocationSelectModalOpen(!locationSelectModalOpen);
    }
    if (type === 'fte-count') {
      setTitle('FTE Count');
      setOptions(fteCountSelect);
      setFteCountSelectModalOpen(!fteCountSelectModalOpen);
    }
    if (type === 'integration') {
      setTitle('Integration');
      setOptions(integrationSelect);
      setIntegrationSelectModalOpen(!integrationSelectModalOpen);
    }
    if (type === 'joined-in') {
      setTitle('Joined in');
      setDateSelectModalOpen(!dateSelectModalOpen);
    }
  };

  const handleIndustrySelectApply = () => {
    setApplyClicked(true);
    setIndustrySelectModalOpen(false);
  };
  const handleLocationSelectApply = () => {
    setApplyClicked(true);
    setLocationSelectModalOpen(false);
  };
  const handleIntegrationSelectApply = () => {
    setApplyClicked(true);
    setIntegrationSelectModalOpen(false);
  };
  const handleFteCountSelectApply = () => {
    setApplyClicked(true);
    setFteCountSelectModalOpen(false);
  };

  const handleDateSelectApply = () => {
    setApplyClicked(true);
    setDateSelectModalOpen(false);
  };
  useEffect(() => {
    dispatch(fetchCompanies({ limit: rowsPerPage, offset: page }));
  }, [dispatch, rowsPerPage, page]);
  const handleTryAgain = () => {
    dispatch(fetchCompanies({ limit: rowsPerPage, offset: page }));
  };
  useEffect(() => {
    if (applyClicked) {
      const filterDate = timeframe + joinDate.toISOString().substring(0, 10);
      dispatch(
        fetchCompanies({
          limit: rowsPerPage,
          offset: page,
          industry: selectIndustryValues.join('$'),
          location: selectLocationValues.map((value) => value.toUpperCase()).join('$'),
          joinedDate: filterDate,
          fteCount: selectFteCountValues.join('$'),
          integration: selectIntegrationValues.map((value) => value.toUpperCase()).join('$'),
        })
      );
      setApplyClicked(false);
    }
  }, [applyClicked]);
  useDidUpdateEffect(() => {
    dispatch(setCompaniesBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const uploadClickHandler = (e, row) => {
    e.stopPropagation();
    setSelectedCompany(row);
    setUploadModalOpen(true);
  };
  const handleUploadApply = async () => {
    await dispatch(
      uploadCreditCheckDocFromCompany({
        companyId: selectedCompany.companyId,
        file: file[0],
        filename: file[0]?.name.slice(0, -4),
      })
    ).unwrap();
    setUploadModalOpen(false);
    dispatch(fetchCompanies({ limit: rowsPerPage, offset: page }));
  };
  const showCreditCheckReport = async (e, fileName) => {
    e.stopPropagation();
    const resp = await dispatch(getB2BCreditCheckSignedUrl({ fileName })).unwrap();
    window.open(resp?.URL, '_blank');
  };

  const navigate = useNavigate();
  return (
    <div className="companies" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Companies</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterButtons} handleSelectClick={handleSelectClick} />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (error) {
            return (
              <Typography variant="h4" className="message-style">
                There is an error.{' '}
                <button
                  type="button"
                  onClick={handleTryAgain}
                  style={{ textDecoration: 'underline', color: 'blue' }}
                >
                  Try again.
                </button>
              </Typography>
            );
          }
          if (!companies.length) {
            return (
              <Typography variant="h4" className="message-style">
                No companies to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headings.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {companies.map((row) => (
                  <TableRow
                    sx={{ cursor: 'pointer' }}
                    key={row.companyId}
                    // component={Link}
                    // to={`/company/${row.companyId}`}
                    onClick={(e) => {
                      if (!e.target.closest('.prevent-row-click')) {
                        navigate(`/company/${row.companyId}`);
                      }
                    }}
                    hover
                  >
                    <TableCell>
                      {row.joinedDate && convertTimestampToDate(row.joinedDate)}
                    </TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    <TableCell>{row.abn}</TableCell>
                    <TableCell>{row.industry}</TableCell>
                    <TableCell>
                      {row.abnBusinessLocationPostalCode &&
                        `${row.abnBusinessLocationPostalCode} ${row.abnBusinessLocationState}`}
                    </TableCell>
                    <TableCell>
                      {row.creditCheckDate && convertTimestampToDate(row.creditCheckDate)}
                    </TableCell>
                    <TableCell>{row.fte} </TableCell>
                    <TableCell>{row?.integration} </TableCell>

                    <TableCell>
                      {row?.creditCheckReport ? (
                        <button
                          type="button"
                          className="view-credit-check .prevent-row-click"
                          onClick={(e) => showCreditCheckReport(e, row.creditCheckReport)}
                        >
                          View Credit Check Report
                        </button>
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={(e) => uploadClickHandler(e, row)}
                        >
                          Upload
                        </Button>
                      )}
                    </TableCell>

                    <TableCell>
                      <ul style={{ listStyle: 'inside' }}>
                        {row.features.map((feature) => (
                          <li>{feature === 'SUBI_HEALTH' ? 'Subi Health' : 'Safety Net'}</li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>

      <UploadDialog
        title="Upload credit check report"
        file={file}
        setFile={setFile}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        subTitle={`Company name: ${selectedCompany.companyName}`}
        handleApply={handleUploadApply}
        value={selectedCompany}
      />

      <CheckboxDialog
        open={industrySelectModalOpen}
        setOpen={setIndustrySelectModalOpen}
        values={selectIndustryValues}
        setValues={setSelectIndustryValues}
        options={options}
        position={position}
        handleApply={handleIndustrySelectApply}
        title={title}
      />
      <CheckboxDialog
        open={locationSelectModalOpen}
        setOpen={setLocationSelectModalOpen}
        values={selectLocationValues}
        setValues={setSelectLocationValues}
        options={options}
        position={position}
        handleApply={handleLocationSelectApply}
        title={title}
      />
      <CheckboxDialog
        open={integrationSelectModalOpen}
        setOpen={setIntegrationSelectModalOpen}
        values={selectIntegrationValues}
        setValues={setSelectIntegrationValues}
        options={options}
        position={position}
        handleApply={handleIntegrationSelectApply}
        title={title}
      />
      <CheckboxDialog
        open={fteCountSelectModalOpen}
        setOpen={setFteCountSelectModalOpen}
        values={selectFteCountValues}
        setValues={setSelectFteCountValues}
        options={options}
        position={position}
        handleApply={handleFteCountSelectApply}
        title={title}
      />
      <DateDialog
        open={dateSelectModalOpen}
        setOpen={setDateSelectModalOpen}
        date={joinDate}
        setDate={setDate}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        handleApply={handleDateSelectApply}
        position={position}
        title={title}
      />
    </div>
  );
};

export default Companies;
