import PropTypes from 'prop-types';
import { renderLayout } from '../../layouts/renderLayout';

const PublicRoute = ({ element, layoutType }) => {
  return renderLayout(layoutType || 'none', element);
};

PublicRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  layoutType: PropTypes.oneOf(['withSidebar', 'none']),
};

PublicRoute.defaultProps = {
  layoutType: 'none',
};

export default PublicRoute;
