import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateDelete, privateGet, privatePost, privatePut } from '../../utilities/apiCaller';

export const fetchNotifications = createAsyncThunk(
  'notification/fetchNotifications',
  async ({ status, createdDate, modifiedAt, publishedAt }, { rejectWithValue }) => {
    try {
      let url = `/notification/list?status=${status}`;
      if (createdDate) {
        url += `&createdDate=${createdDate}`;
      }
      if (modifiedAt) {
        url += `&modifiedAt=${modifiedAt}`;
      }
      if (publishedAt) {
        url += `&publishedAt=${publishedAt}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notification/deleteNotification',
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await privateDelete(`/notification/${notificationId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateScheduleNotificationToDraft = createAsyncThunk(
  'notification/scheduledNotificationToDraft',
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await privatePut(`/notification/schedule-to-draft/${notificationId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const createNotification = createAsyncThunk(
  'notification/createNotfication',
  async (body, { rejectWithValue }) => {
    try {
      const response = await privatePost('/notification/', body);
      body.id = response.data.notificationId;
      return body;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotification = createAsyncThunk(
  'notification/getNotification',
  async (notificationId, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/notification/${notificationId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const publishNotification = createAsyncThunk(
  'notification/publishNotification',
  async (
    { title, subTitle, body, notificationType, subscriberId, scheduledAt },
    { rejectWithValue }
  ) => {
    try {
      const response = await privatePost('/notification/publish', {
        title,
        subTitle,
        body,
        notificationType,
        subscriberId,
        scheduledAt,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editNotification = createAsyncThunk(
  'notification/editNotification',
  async (fields, { rejectWithValue }) => {
    try {
      const { id, ...data } = fields;
      const response = await privatePut(`/notification/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const publishDraftNotification = createAsyncThunk(
  'notification/publishDraftNotification',
  async ({ id, notificationType, scheduledAt }, { rejectWithValue }) => {
    try {
      const body = { notificationType };
      if (scheduledAt) {
        body.scheduledAt = scheduledAt;
      }
      await privatePost(`/notification/publish/${id}`, body);
      return id;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUsersList = createAsyncThunk(
  'notification/getUsersList',
  async (userIds, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/data/user-list?ids=${userIds.join('$')}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    notification: null,
    unfiltredNotifications: [],
    companyUsersPair: [],
    isUsersLoading: false,
    isLoading: false,
    isNotificationDeleted: false,
    isNotificationDrafted: false,
    error: null,
    totalCount: 10,
    isInstantPublishSuccess: false,
    isDraftPublishSuccess: false,
    paginateNotifications: [],
  },
  reducers: {
    setNotificationsBySearchToken: (state, action) => {
      const { token } = action.payload;
      state.notifications = state.unfiltredNotifications.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
    setIsNotificationDeleted: (state) => {
      state.isNotificationDeleted = false;
      state.isNotificationDrafted = false;
      state.isInstantPublishSuccess = false;
      state.isDraftPublishSuccess = false;
    },
    paginateNotificationsReducer: (state, action) => {
      const { page, rowsPerPage } = action.payload;
      const startIndex = page * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      state.paginateNotifications = state.notifications.slice(startIndex, endIndex);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.data;
        state.unfiltredNotifications = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.data.length;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.notifications = [];
        state.totalCount = 10;
      })
      .addCase(createNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notifications = [...state.notifications, action.payload];
        state.error = null;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        const deletedNotificationId = action.payload.id;
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== deletedNotificationId
        );
        state.isNotificationDeleted = true;
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateScheduleNotificationToDraft.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateScheduleNotificationToDraft.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedNotificationId = action.payload.id;
        state.notifications = state.notifications.filter(
          (notification) => notification.id !== updatedNotificationId
        );
        state.isNotificationDrafted = true;
        state.error = null;
      })
      .addCase(updateScheduleNotificationToDraft.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(publishNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(publishNotification.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isInstantPublishSuccess = true;
        state.instantPublishedNotification = payload;
        state.error = null;
      })
      .addCase(publishNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(publishDraftNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(publishDraftNotification.fulfilled, (state) => {
        state.isDraftPublishSuccess = true;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(publishDraftNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.notification = action.payload;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editNotification.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(editNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const index = state.notifications.findIndex(
          (notification) => notification.id === action.payload.id
        );
        state.notifications[index] = action.payload;
      })
      .addCase(editNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getUsersList.pending, (state) => {
        state.isUsersLoading = true;
        state.error = null;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.isUsersLoading = false;
        state.error = null;
        state.companyUsersPair = Object.keys(action.payload).map((key) => ({
          [key]: action.payload[key],
        }));
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.isUsersLoading = false;
        state.error = action.payload;
        state.companyUsersPair = [];
      });
  },
});

export const {
  setNotificationsBySearchToken,
  setIsNotificationDeleted,
  paginateNotificationsReducer,
} = notificationSlice.actions;
export default notificationSlice.reducer;
