import axios from 'axios';
import localforage from 'localforage';
import { api } from '../config/variable';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const publicGet = async (endpoint) => {
  const response = await axios.get(`${api}${endpoint}`, config);
  return response.data;
};

export const publicPost = async (endpoint, body) => {
  const response = await axios.post(`${api}${endpoint}`, body, config);
  return response.data;
};

export const publicPut = async (endpoint, body) => {
  const response = await axios.put(`${api}${endpoint}`, body, config);
  return response.data;
};

export const publicPatch = async (endpoint, body) => {
  const response = await axios.patch(`${api}${endpoint}`, body, config);
  return response.data;
};

export const privateGet = async (endpoint) => {
  const { token } = JSON.parse(JSON.parse(await localforage.getItem('persist:root')).loginReducer);
  config.headers.Authorization = `Bearer ${token}`;
  const response = await axios.get(`${api}${endpoint}`, config);
  return response.data;
};
export const privateGetV2 = async (endpoint, token) => {
  const response = await axios.get(`${api}${endpoint}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
export const privateDelete = async (endpoint) => {
  const { token } = JSON.parse(JSON.parse(await localforage.getItem('persist:root')).loginReducer);
  config.headers.Authorization = `Bearer ${token}`;
  const response = await axios.delete(`${api}${endpoint}`, config);
  return response.data;
};

export const privatePost = async (endpoint, body, additionalHeaders) => {
  const { token } = JSON.parse(JSON.parse(await localforage.getItem('persist:root')).loginReducer);
  const postConfig = {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}`, ...additionalHeaders },
  };
  const response = await axios.post(`${api}${endpoint}`, body, postConfig);
  return response.data;
};

export const privatePut = async (endpoint, body) => {
  const { token } = JSON.parse(JSON.parse(await localforage.getItem('persist:root')).loginReducer);
  config.headers.Authorization = `Bearer ${token}`;
  const response = await axios.put(`${api}${endpoint}`, body, config);
  return response.data;
};

export const privatePatch = async (endpoint, body) => {
  const { token } = JSON.parse(JSON.parse(await localforage.getItem('persist:root')).loginReducer);
  config.headers.Authorization = `Bearer ${token}`;
  const response = await axios.patch(`${api}${endpoint}`, body, config);
  return response.data;
};
