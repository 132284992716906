/* eslint-disable no-nested-ternary */
import {
  Button,
  Icon,
  Snackbar,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteNotification,
  fetchNotifications,
  paginateNotificationsReducer,
  publishDraftNotification,
  setIsNotificationDeleted,
  setNotificationsBySearchToken,
  updateScheduleNotificationToDraft,
} from '../../app/notificationCentre/notificationSlice';
import { fetchRecipientsList } from '../../app/notificationCentre/recipientsSlice';
import { notificationTypes } from '../../config/variable';
import useNotificationAlert from '../../hooks/useNotificationAlert';
import {
  convertTimestampToDate,
  convertTimestampToDateAndTime,
  extractDateTime,
} from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import { getSubscribersNames, joinNotificationsSubscriberIds } from '../../utils/helperMethod';
import { companySelect } from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import DeleteDialog from '../Dialog/DeleteDialog/DeleteDialog';
import ReviewNotificationDialog from '../Dialog/ReviewNotificationDialog/ReviewNotificationDialog';
import ScheduleNotificationDialog from '../Dialog/ScheduleNotificationDialog/ScheduleNotificationDialog';
import UploadDialog from '../Dialog/UploadDialog/UploadDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './NotificationCentre.scss';
import ThreeDotModal from './components/ThreeDotModal/ThreeDotModal';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const NotificationCentre = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    notifications,
    totalCount,
    isNotificationDeleted,
    isInstantPublishSuccess,
    isDraftPublishSuccess,
    paginateNotifications,
    isNotificationDrafted,
  } = useSelector((state) => state.notification);
  const { isLoading: recipientsLoading, recipientsDetails } = useSelector(
    (state) => state.notificationsRecipients
  );
  const [allRecipientUsers, setAllRecipientUsers] = useState([]);
  const filterButtonsList = {
    draft: [{ name: 'Last edited' }],
    scheduled: [{ name: 'Date created' }],
    sent: [{ name: 'Date sent' }],
  };

  const headings = {
    draft: ['Notification title', 'Date created', 'Last edited', 'Recipients'],
    scheduled: ['Notification title', 'Date created', 'Recipients', 'Scheduled send'],
    sent: ['Notification title', 'Recipients', 'Date sent'],
  };

  const actionButtonsList = {
    draft: [
      { name: 'Delete', icon: 'delete' },
      { name: 'Edit', icon: 'create' },
      { name: 'Schedule', icon: 'schedule_send' },
      { name: 'Send', icon: 'send' },
    ],
    scheduled: [
      { name: 'Edit', icon: 'create' },
      { name: 'Schedule', icon: 'schedule_send' },
      { name: 'Send', icon: 'send' },
      { name: 'More', icon: 'more_vert' },
    ],
  };
  const tabsData = ['Draft', 'Scheduled', 'Sent'];
  const [currentTab, setCurrentTab] = useState(tabsData[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterList, setFilterList] = useState([]);
  const handleChangeCurrentTab = (newTab) => {
    setCurrentTab(tabsData[newTab]);
  };
  const [applyClicked, setApplyClicked] = useState(false);
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [recipientSelectModalOpen, setRecipientSelectModalOpen] = useState(false);
  const [lastEditedDateModalOpen, setLastEditedDateModalOpen] = useState(false);
  const [lastEditedTimeframe, setLastEditedTimeframe] = useState('before');
  const [lastEditedDate, setLastEditedDate] = useState(dayjs(Date.now()));
  const [dateCreatedModalOpen, setDateCreatedModalOpen] = useState(false);
  const [dateCreatedTimeframe, setDateCreatedTimeframe] = useState('before');
  const [dateCreated, setDateCreated] = useState(dayjs(Date.now()));
  const [dateSentModalOpen, setDateSentModalOpen] = useState(false);
  const [dateSentTimeframe, setDateSentTimeframe] = useState('before');
  const [dateSent, setDateSent] = useState(dayjs(Date.now()));
  const [recipientSelectValues, setRecipientSelectValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedCompany] = useState({});
  const [file, setFile] = useState(null);
  const [searchToken, setSerchToken] = useState('');
  const [deleteNotificationModalOpen, setDeleteNotificationModelOpen] = useState(false);
  const [scheduleNotificationModalOpen, setScheduleNotificationModalOpen] = useState(false);
  const [scheduleNotificationTime, setScheduleNotificationTime] = useState(
    dayjs()
      .startOf('hour')
      .add(Math.ceil(dayjs().minute() / 15) * 15, 'minutes')
  );
  const [scheduleNotificationDate, setScheduleNotificationDate] = useState(dayjs(Date.now()));
  const [reviewNotificationModalOpen, setReviewNotificationModalOpen] = useState(false);
  const [threeDotModalOpen, setThreeDotModalOpen] = useState(false);
  const { openSnack, setSnackOpen, toastMessage, setToastMessage, handleSnackClose } =
    useNotificationAlert();
  const queryParam = new URLSearchParams(window.location.search);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tabValue = queryParam.get('tab');
    if (tabValue) {
      setTabIndex(+tabValue);
    }
  }, [queryParam]);

  const navigate = useNavigate();

  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'last-edited') {
      setTitle('Last edited');
      setLastEditedDateModalOpen(!lastEditedDateModalOpen);
    }
    if (type === 'recipients') {
      setTitle('Recipients');
      setOptions(companySelect);
      setRecipientSelectModalOpen(!recipientSelectModalOpen);
    }
    if (type === 'date-created') {
      setTitle('Date created');
      setDateCreatedModalOpen(!dateCreatedModalOpen);
    }
    if (type === 'date-sent') {
      setTitle('Date sent');
      setDateSentModalOpen(!dateSentModalOpen);
    }
  };

  const handleRecipientSelectApply = () => {
    setFilterList([...filterList, 'recipient']);
    setRecipientSelectModalOpen(false);
  };

  const handleLastEditedDateApply = () => {
    setFilterList([...filterList, 'lastEdited']);
    setApplyClicked(true);
    setLastEditedDateModalOpen(false);
  };

  const handleDateCreatedApply = () => {
    setFilterList([...filterList, 'dateCreated']);
    setApplyClicked(true);
    setDateCreatedModalOpen(false);
  };

  const handleDateSentApply = () => {
    setFilterList([...filterList, 'dateSent']);
    setApplyClicked(true);
    setDateSentModalOpen(false);
  };

  const headingsToRender = headings[currentTab.toLowerCase()];
  const filterButtons = filterButtonsList[currentTab.toLowerCase()];
  const actionButtons = actionButtonsList[currentTab.toLowerCase()];
  const [sendNotificationText, setSendNotificationText] = useState('');

  const handleActionBtn = (type, item, e) => {
    setSelectedNotification(item);
    if (type.toLowerCase() === 'delete') {
      setDeleteNotificationModelOpen(true);
    }
    if (type.toLowerCase() === 'schedule') {
      setScheduleNotificationModalOpen(true);
    }
    if (type.toLowerCase() === 'edit') {
      navigate(`/notification-centre/edit/${item.id}`);
    }
    if (type.toLowerCase() === 'send') {
      if (allRecipientUsers.length) {
        const notificationText = getSubscribersNames(allRecipientUsers, item.subscriberId);
        setSendNotificationText(notificationText);
      }
      setReviewNotificationModalOpen(true);
    }
    if (type.toLowerCase() === 'more') {
      const itemPosition = getItemPosition(e);
      itemPosition.left -= 250;
      itemPosition.top += 10;
      setPosition(itemPosition);
      setThreeDotModalOpen(true);
    }
  };
  useEffect(() => {
    if (queryParam.get('tab') && tabIndex !== +queryParam.get('tab')) {
      return;
    }
    const fetchParams = {};
    setRowsPerPage(10);
    setPage(0);
    if (filterList.includes('lastEdited')) {
      fetchParams.createdDate = lastEditedTimeframe + lastEditedDate.toISOString().substring(0, 10);
    }
    if (filterList.includes('dateCreated')) {
      fetchParams.createdDate = dateCreatedTimeframe + dateCreated.toISOString().substring(0, 10);
    }
    if (filterList.includes('dateSent')) {
      fetchParams.publishedAt = dateSentTimeframe + dateSent.toISOString().substring(0, 10);
    }

    dispatch(fetchNotifications({ status: currentTab.toUpperCase() }));
  }, [dispatch, currentTab]);

  useEffect(() => {
    if (queryParam.get('tab') && tabIndex !== +queryParam.get('tab')) {
      return;
    }
    const fetchParams = {};
    if (filterList.includes('lastEdited')) {
      fetchParams.modifiedAt = lastEditedTimeframe + lastEditedDate.toISOString().substring(0, 10);
    }
    if (filterList.includes('dateCreated')) {
      fetchParams.createdDate = dateCreatedTimeframe + dateCreated.toISOString().substring(0, 10);
    }
    if (filterList.includes('dateSent')) {
      fetchParams.publishedAt = dateSentTimeframe + dateSent.toISOString().substring(0, 10);
    }
    if (applyClicked) {
      dispatch(fetchNotifications({ status: currentTab.toUpperCase(), ...fetchParams }));
      setApplyClicked(false);
    }
  }, [applyClicked]);

  useEffect(() => {
    if (notifications.length) {
      dispatch(paginateNotificationsReducer({ page, rowsPerPage }));
    }
  }, [notifications, rowsPerPage, page]);

  useEffect(() => {
    dispatch(setNotificationsBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const handleUploadApply = () => {
    setUploadModalOpen(false);
  };

  const handleDeleteNotification = () => {
    dispatch(deleteNotification(selectedNotification.id));
  };

  useEffect(() => {
    if (isNotificationDeleted) {
      setToastMessage('Notification successfully deleted');
      setSnackOpen(true);
      dispatch(setIsNotificationDeleted());
    }
    if (isNotificationDrafted) {
      setToastMessage('Successfully canceled scheduled notification');
      setSnackOpen(true);
      dispatch(setIsNotificationDeleted());
    }
  }, [isNotificationDeleted, isNotificationDrafted]);

  const handleScheduleNotification = () => {
    const dateTimeString = extractDateTime(
      scheduleNotificationDate.toISOString(),
      scheduleNotificationTime.toISOString()
    );
    const data = {
      id: selectedNotification.id,
      notificationType: notificationTypes.SCHEDULED_ONCE,
      scheduledAt: dateTimeString,
    };
    dispatch(publishDraftNotification(data));
  };

  const handleReviewNotification = () => {
    setToastMessage('Notification sent');
    setSnackOpen(true);
    dispatch(
      publishDraftNotification({
        id: selectedNotification.id,
        notificationType: notificationTypes.INSTANT,
      })
    );
    setReviewNotificationModalOpen(false);
  };

  const handleCancelScheduleApply = () => {
    dispatch(updateScheduleNotificationToDraft(selectedNotification.id));
    setThreeDotModalOpen(false);
  };

  const handleDeleteNotificationApply = () => {
    handleDeleteNotification();
    setThreeDotModalOpen(false);
  };

  const handleCreateNotificationBtn = () => {
    navigate('/notification-centre/new');
  };

  useEffect(() => {
    if (isInstantPublishSuccess || isDraftPublishSuccess) {
      dispatch(setIsNotificationDeleted());
      dispatch(fetchNotifications({ status: currentTab.toUpperCase() }));
    }
  }, [isInstantPublishSuccess, isDraftPublishSuccess]);
  useEffect(() => {
    if (notifications.length) {
      const subscriberIds = joinNotificationsSubscriberIds(notifications);
      dispatch(fetchRecipientsList(subscriberIds));
    }
  }, [notifications]);

  useEffect(() => {
    if (recipientsDetails && Object.keys(recipientsDetails).length > 0) {
      const allUsers = Object.values(recipientsDetails).flatMap(
        (companyDetails) => companyDetails.users || []
      );
      setAllRecipientUsers(allUsers);
    }
  }, [recipientsDetails]);
  return (
    <div className="notification-centre" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Notification Centre</h1>
        <Button variant="contained" onClick={handleCreateNotificationBtn}>
          Create new notification
        </Button>
      </div>
      <div className="search">
        <Searchbar searchToken={searchToken} setSearchToken={setSerchToken} />
      </div>
      <ButtonGroup buttons={filterButtons} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeCurrentTab}
        tabIndex={tabIndex}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!notifications.length) {
            return (
              <Typography variant="h4" className="message-style">
                No {currentTab.toLowerCase()} notifications to show.
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginateNotifications.map((row) => (
                  <TableRow key={row.notificationId}>
                    {currentTab.toUpperCase() === 'DRAFT' && (
                      <>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          {row.createdAt && convertTimestampToDate(row.createdAt)}
                        </TableCell>
                        <TableCell>
                          {row.modifiedAt && convertTimestampToDateAndTime(row.modifiedAt)}
                        </TableCell>
                        <TableCell>
                          {row.subscriberId.includes('all') ? (
                            'All users from all companies'
                          ) : recipientsLoading ? (
                            <Skeleton width={100} height={20} />
                          ) : (
                            allRecipientUsers.length &&
                            getSubscribersNames(allRecipientUsers, row.subscriberId)
                          )}
                        </TableCell>
                      </>
                    )}
                    {currentTab.toUpperCase() === 'SCHEDULED' && (
                      <>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          {row.createdAt && convertTimestampToDate(row.createdAt)}
                        </TableCell>
                        <TableCell>
                          {row.subscriberId.includes('all') ? (
                            'All users from all companies'
                          ) : recipientsLoading ? (
                            <Skeleton width={100} height={20} />
                          ) : (
                            allRecipientUsers.length &&
                            getSubscribersNames(allRecipientUsers, row.subscriberId)
                          )}
                        </TableCell>

                        <TableCell>
                          {row.scheduledAt && convertTimestampToDateAndTime(row.scheduledAt)}
                        </TableCell>
                      </>
                    )}
                    {currentTab.toUpperCase() === 'SENT' && (
                      <>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          {row.subscriberId.includes('all') ? (
                            'All users from all companies'
                          ) : recipientsLoading ? (
                            <Skeleton width={100} height={20} />
                          ) : (
                            allRecipientUsers.length &&
                            getSubscribersNames(allRecipientUsers, row.subscriberId)
                          )}
                        </TableCell>
                        <TableCell>
                          {row.publishedAt && convertTimestampToDate(row.publishedAt)}
                        </TableCell>
                      </>
                    )}
                    {currentTab.toUpperCase() !== 'SENT' && (
                      <TableCell align="center">
                        <div className="button-action-style">
                          {actionButtons?.map((btn) => {
                            return (
                              <button
                                type="button"
                                className="action-btn"
                                onClick={(e) => handleActionBtn(btn.name.toLowerCase(), row, e)}
                              >
                                <Icon color="primary">{btn.icon}</Icon>
                              </button>
                            );
                          })}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <CheckboxDialog
        open={recipientSelectModalOpen}
        setOpen={setRecipientSelectModalOpen}
        values={recipientSelectValues}
        setValues={setRecipientSelectValues}
        options={options}
        position={position}
        handleApply={handleRecipientSelectApply}
        title={title}
      />
      <DateDialog
        open={lastEditedDateModalOpen}
        setOpen={setLastEditedDateModalOpen}
        date={lastEditedDate}
        setDate={setLastEditedDate}
        timeframe={lastEditedTimeframe}
        setTimeframe={setLastEditedTimeframe}
        handleApply={handleLastEditedDateApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={dateCreatedModalOpen}
        setOpen={setDateCreatedModalOpen}
        date={dateCreated}
        setDate={setDateCreated}
        timeframe={dateCreatedTimeframe}
        setTimeframe={setDateCreatedTimeframe}
        handleApply={handleDateCreatedApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={dateSentModalOpen}
        setOpen={setDateSentModalOpen}
        date={dateSent}
        setDate={setDateSent}
        timeframe={dateSentTimeframe}
        setTimeframe={setDateSentTimeframe}
        handleApply={handleDateSentApply}
        position={position}
        title={title}
      />
      <DeleteDialog
        open={deleteNotificationModalOpen}
        setOpen={setDeleteNotificationModelOpen}
        type="notification"
        additionalSubTitle="and be sent to the selected users"
        handleApply={handleDeleteNotification}
      />
      <ScheduleNotificationDialog
        open={scheduleNotificationModalOpen}
        setOpen={setScheduleNotificationModalOpen}
        date={scheduleNotificationDate}
        time={scheduleNotificationTime}
        subTitle="Canberra, Melbourne, Sydney"
        handleApply={handleScheduleNotification}
        setDate={setScheduleNotificationDate}
        setTime={setScheduleNotificationTime}
      />
      <ReviewNotificationDialog
        open={reviewNotificationModalOpen}
        setOpen={setReviewNotificationModalOpen}
        totalCompanies={12}
        totalUsers={28}
        sendNotificationText={sendNotificationText}
        handleApply={handleReviewNotification}
      />
      <ThreeDotModal
        open={threeDotModalOpen}
        setOpen={setThreeDotModalOpen}
        position={position}
        handleCancelScheduleApply={handleCancelScheduleApply}
        handleDeleteNotificationApply={handleDeleteNotificationApply}
      />
      <UploadDialog
        title="Upload credit check report"
        file={file}
        setFile={setFile}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        subTitle={`Company name: ${selectedCompany.companyName}`}
        handleApply={handleUploadApply}
        value={selectedCompany}
      />
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NotificationCentre;
