export const auth0Configuration = {
  // configurationBaseUrl: 'https://cdn.us.auth0.com',
  container: 'auth0-main',
  auth: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    // params: {
    //   scope: 'openid profile email',
    // },
    // params: config.internalOptions
    redirect: true,
    redirectUrl: process.env.REACT_APP_AUTH0_REDIRECT_URL,
    responseType: 'token id_token',
    sso: true,
  },
  // configurationBaseUrl: config.clientConfigurationBaseUrl,
  // overrides: {
  //   __tenant: config.auth0Tenant,
  //   __token_issuer: config.authorizationServer.issuer
  // },
  // assetsUrl:  config.assetsUrl,
  allowedConnections: ['Office365'],
  // rememberLastLogin: !prompt,
  // language: language,
  // languageBaseUrl: config.languageBaseUrl,
  languageDictionary: {
    loginLabel: 'Log in',
    loginSubmitLabel: 'Log in',
    title: '',
    signUpTitle: '',
    enterpriseLoginIntructions: '',
    loginAtLabel: 'Continue with',
  },
  theme: {
    primaryColor: '#00000',
    labeledSubmitButton: true,
    logo: 'Logo.svg',
    authButtons: {
      Office365: {
        primaryColor: '#000650',
      },
    },
  },
  additionalSignUpFields: [
    {
      name: 'phone',
      type: 'phone',
      placeholder: 'Phone Number (+14314314314)',
      // icon: '/phone_icon.png',
    },
  ],
  // prefill: loginHint ? { email: loginHint, username: loginHint } : null,
  closable: false,
  defaultADUsernameFromEmailPrefix: false,
  rememberLastLogin: true,
};
