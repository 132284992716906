import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePut } from '../../utilities/apiCaller';

export const fetchRepositoryUserInfo = createAsyncThunk(
  'repositoryUserInfo/fetchRepositoryUserInfo',
  async (userId, { rejectWithValue }) => {
    try {
      const url = `/data/b2c/user/user-info?userId=${userId}`;
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const putUserInfo = createAsyncThunk(
  'userInfo/putUserInfo',
  async ({ userId, updatedUserInfo }, { rejectWithValue }) => {
    try {
      const url = `/data/b2c/user/user-info?userId=${userId}`;
      const response = await privatePut(url, updatedUserInfo);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchUsersByCompanyId = createAsyncThunk(
  'userInfo/fetchUsersByCompanyId',
  async (companyId, { rejectWithValue }) => {
    try {
      const url = `/data/user/${companyId}/list`;
      const response = await privateGet(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const repositoryUserInfoSlice = createSlice({
  name: 'repositoryUserInfo',
  initialState: {
    userInfo: {},
    usersByCompanyId: [],
    isLoading: false,
    error: null,
    isUserInfoUpdated: false,
  },
  reducers: {
    setUserInfoUpdated: (state) => {
      state.isUserInfoUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepositoryUserInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRepositoryUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload.data;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchRepositoryUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.userInfo = {};
      })
      .addCase(putUserInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(putUserInfo.fulfilled, (state, action) => {
        state.isUserInfoUpdated = true;
        state.userInfo = action.payload.data;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putUserInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.data = null;
      })
      .addCase(fetchUsersByCompanyId.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsersByCompanyId.fulfilled, (state, action) => {
        state.usersByCompanyId = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchUsersByCompanyId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.usersByCompanyId = [];
      });
  },
});
export const { setUserInfoUpdated } = repositoryUserInfoSlice.actions;
export default repositoryUserInfoSlice.reducer;
