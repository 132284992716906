import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import React from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';
import './CreditCheckDialog.scss';

const CreditCheckDialog = (props) => {
  const { open, setOpen, value, setValue, handleApply, ...other } = props;
  const inputRef = React.useRef(null);
  const handleClose = () => {
    setOpen(false);
  };
  const emailData = {
    managerName: value.manager,
    receiverEmail: value.contactInfo,
    employeeId: value._id,
  };
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title="Credit check approved!"
      titleIcon={<CheckCircleIcon fontSize="large" />}
      titleMiddle
      actionButtons={[
        {
          label: `Send sign-up link to ${value.contactInfo} `,
          variant: 'contained',
          onClick: () => handleApply(emailData),
        },
        {
          label: "I'll do it manually",
          onClick: handleClose,
        },
      ]}
      ref={inputRef}
      className="credit-check-modal"
      {...other}
    >
      <Typography align="center" paragraph>
        {`You have approved ${value.company} credit check. Send the sign-up link with ${value.contactInfo} to grant
      them access to manage employee claims and repayments.`}
      </Typography>
    </BaseDialog>
  );
};

export default CreditCheckDialog;
