import React from 'react';
import { Card, CardContent, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import './AccountDetailsDialog.scss';

const AccountDetailsDialog = (props) => {
  const { open, setOpen, position, bankAccounts } = props;
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="account-details-dialog"
      style={{ position: 'absolute', top: position.top, left: position.left }}
    >
      <Card variant="outlined">
        <CardContent className="bank-account">
          <div className="title-icon">
            <Typography className="title">Bank Account Details</Typography>
            <IconButton onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </div>
          <p className="account-data">Acct. Name: {bankAccounts.accountName}</p>
          <p className="account-data">BSB: {bankAccounts.BSB}</p>
          <p className="account-data">Acct. No: {bankAccounts.accountNumber}</p>
        </CardContent>
      </Card>
    </Modal>
  );
};
AccountDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
  bankAccounts: PropTypes.shape({
    accountName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    BSB: PropTypes.string.isRequired,
  }).isRequired,
};
export default AccountDetailsDialog;
