/* eslint-disable no-await-in-loop */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGetV2 } from '../../utilities/apiCaller';

export const fetchB2BMyTasks = createAsyncThunk(
  'b2bMyTask/fetchB2BMyTasks',
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGetV2(`/data/b2b/dashboard/my-tasks`, token);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2bMyTaskSlice = createSlice({
  name: 'b2bMyTask',
  initialState: {
    task: {},
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2BMyTasks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2BMyTasks.fulfilled, (state, action) => {
        state.task = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2BMyTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.task = {};
        state.totalCount = 0;
      });
  },
});

export default b2bMyTaskSlice.reducer;
