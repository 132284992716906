import LayoutWithSidebar from './LayoutWithSidebar';

export const renderLayout = (layoutType, Component) => {
  if (layoutType) {
    if (layoutType.toLowerCase() === 'withSidebar'.toLowerCase()) {
      return <LayoutWithSidebar>{Component}</LayoutWithSidebar>;
    }
  }
  return Component;
};
