import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchB2BEmployees,
  setEmployeePaymentsBySearchToken,
  updateClaimById,
} from '../../app/b2bEmployeePayment/b2bEmployeePaymentSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { formatDateTimeStamp } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import { amountReuquestedSelect, companySelect } from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AccountDetailsDialog from '../Dialog/AccountDetailsDialog/AccountDetailsDialog';
import AmountSliderDialog from '../Dialog/AmountSliderDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './EmployeePayment.scss';

const EmployeePayment = () => {
  const dispatch = useDispatch();
  const { isLoading, b2bEmployees, totalCount } = useSelector((state) => state.b2bEmployees);
  const filterPending = [
    { name: 'Date Approved' },
    // { name: 'Company' },
    { name: 'Amount Requested' },
  ];
  const filterPaid = [
    { name: 'Date Approved' },
    // { name: 'Company' },
    { name: 'Date Paid' },
    { name: 'Amount Paid' },
  ];

  const pendingHeadings = [
    'Claim Id',
    'Requested By',
    'Company',
    'Date Approved',
    'Amount Requested',
  ];
  const paidHeadings = [
    'Claim ID',
    'Requested By',
    'Company',
    'Date Approved',
    'Date Paid',
    'Amount Paid',
    'Bank Account',
  ];
  const tabsData = ['Pending', 'Paid'];
  const [applyClicked, setApplyClicked] = useState(false);
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [companySelectModalOpen, setCompanySelectModalOpen] = useState(false);
  const [amountSelectModalOpen, setAmountSelectModalOpen] = useState(false);
  const [paidAmountSelectModalOpen, setPaidAmountSelectModalOpen] = useState(false);
  const [approvedDateSelectModalOpen, setApprovedDateSelectModalOpen] = useState(false);
  const [paidDateSelectModalOpen, setPaidDateSelectModalOpen] = useState(false);
  const [selectCompanyValues, setSelectCompanyValues] = useState([]);
  const [requestAmount, setRequestAmount] = useState([]);
  const [paidAmount, setPaidAmount] = useState([]);
  const [options, setOptions] = useState([]);
  const [paidDatetimeframe, setPaidDateTimeframe] = useState('before');
  const [approvedDatetimeframe, setApprovedDateTimeframe] = useState('before');
  const [approvedDate, setApprovedDate] = useState(dayjs(Date.now()));
  const [paidDate, setPaidDate] = useState(dayjs(Date.now()));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchToken, setSearchToken] = useState();
  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'company') {
      setTitle('Company');
      setOptions(companySelect);
      setCompanySelectModalOpen(!companySelectModalOpen);
    }
    if (type === 'amount-requested') {
      setTitle('Amount Requested');
      setOptions(amountReuquestedSelect);
      setAmountSelectModalOpen(!amountSelectModalOpen);
    }
    if (type === 'date-approved') {
      setTitle('Date approved');
      setApprovedDateSelectModalOpen(!approvedDateSelectModalOpen);
    }
    if (type === 'date-paid') {
      setPaidDateSelectModalOpen(!paidDateSelectModalOpen);
      setTitle('Date Paid');
    }
    if (type === 'amount-paid') {
      setPaidAmountSelectModalOpen(!paidAmountSelectModalOpen);
      setTitle('Amount Paid');
    }
  };

  const handleCompanySelectApply = () => {
    setCompanySelectModalOpen(false);
    setApplyClicked(true);
  };

  const handleApprovedDateSelectApply = () => {
    setApplyClicked(true);
    setApprovedDateSelectModalOpen(false);
  };
  const handlePaidDateSelectApply = () => {
    setApplyClicked(true);
    setApprovedDateSelectModalOpen(false);
  };

  const [status, setStatus] = useState(tabsData[0]);
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  const isPending = status.toUpperCase() === 'PENDING';
  const filterOptions = isPending ? filterPending : filterPaid;
  const headingsToRender = isPending ? pendingHeadings : paidHeadings;
  const handleMarkAsPaid = (claimId) => {
    dispatch(updateClaimById(claimId));
  };
  useEffect(() => {
    if (isPending) {
      dispatch(
        fetchB2BEmployees({
          status: 'APPROVED',
          limit: rowsPerPage,
          offset: page,
          requestAmount: requestAmount.length ? requestAmount.join('-') : 0,
        })
      );
    } else {
      dispatch(
        fetchB2BEmployees({
          status: 'PAID',
          limit: rowsPerPage,
          offset: page,
          requestAmount: requestAmount.length ? requestAmount.join('-') : 0,
        })
      );
    }
  }, [dispatch, isPending, rowsPerPage, page]);
  useEffect(() => {
    if (applyClicked) {
      if (isPending) {
        const filterApprovedDate =
          approvedDatetimeframe + approvedDate.toISOString().substring(0, 10);
        dispatch(
          fetchB2BEmployees({
            status: status.toUpperCase() === 'PENDING' ? 'APPROVED' : status.toUpperCase(),
            limit: rowsPerPage,
            offset: page,
            company: selectCompanyValues.join('$'),
            approvedDate: filterApprovedDate,
            requestAmount: requestAmount.length ? requestAmount.join('-') : 0,
          })
        );
      } else {
        const filterApprovedDate =
          approvedDatetimeframe + approvedDate.toISOString().substring(0, 10);
        const filterPaidDate = paidDatetimeframe + paidDate.toISOString().substring(0, 10);
        dispatch(
          fetchB2BEmployees({
            status: status.toUpperCase() === 'PENDING' ? 'APPROVED' : status.toUpperCase(),
            limit: rowsPerPage,
            offset: page,
            company: selectCompanyValues.join('$'),
            approvedDate: filterApprovedDate,
            paidDate: filterPaidDate,
            paidAmount: paidAmount.length ? paidAmount.join('-') : 0,
          })
        );
      }
      setApplyClicked(false);
    }
  }, [applyClicked]);

  const [bankAccounts, setBankAccounts] = useState({});
  const [accountModalOpen, setAccountModalOpen] = useState(false);

  const handleBankAccountModal = (e, account) => {
    const itemPosition = getItemPosition(e);
    itemPosition.left -= 80;
    setPosition(itemPosition);
    setBankAccounts(account);
    setAccountModalOpen(true);
  };
  const handleAmountSelectApply = () => {
    setApplyClicked(true);
    setAmountSelectModalOpen(false);
  };
  const handlePaidAmountSelectApply = () => {
    setApplyClicked(true);
    setPaidAmountSelectModalOpen(false);
  };
  useDidUpdateEffect(() => {
    dispatch(setEmployeePaymentsBySearchToken({ token: searchToken }));
  }, [searchToken]);
  return (
    <div className="employees-payments" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Employee payments</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterOptions} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!b2bEmployees.length) {
            return (
              <Typography variant="h4" className="message-style">
                No {status.toLowerCase()} employees payment to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}

                  {isPending && (
                    <>
                      <TableCell>Bank account</TableCell>
                      <TableCell>Action</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending
                  ? b2bEmployees.map((row) => (
                      <TableRow key={row.claimId}>
                        <TableCell>{row.claimId}</TableCell>
                        <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                        <TableCell>{row.company}</TableCell>
                        <TableCell>
                          {row.companyApprovalDate && formatDateTimeStamp(row.companyApprovalDate)}
                        </TableCell>
                        <TableCell>
                          $
                          {row.amountRequested &&
                            formatNumberWithThousandSeparator(row.amountRequested)}
                        </TableCell>
                        <TableCell>
                          <button
                            type="button"
                            className="view-details"
                            onClick={(e) => handleBankAccountModal(e, row.bankAccounts)}
                          >
                            View details
                          </button>
                        </TableCell>
                        <TableCell>
                          <button
                            className="mark-button"
                            type="button"
                            onClick={() => handleMarkAsPaid(row.claimId)}
                          >
                            Mark as Paid
                          </button>
                        </TableCell>
                      </TableRow>
                    ))
                  : b2bEmployees.map((row) => (
                      <TableRow key={row.claimId}>
                        <TableCell>{row.claimId}</TableCell>
                        <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                        <TableCell>{row.company}</TableCell>
                        <TableCell>
                          {row.companyApprovalDate && formatDateTimeStamp(row.companyApprovalDate)}
                        </TableCell>
                        <TableCell>{formatDateTimeStamp(row.datePaid)}</TableCell>
                        <TableCell>
                          $
                          {row.amountRequested &&
                            formatNumberWithThousandSeparator(row.amountRequested)}
                        </TableCell>
                        <TableCell>
                          <button
                            type="button"
                            className="view-details"
                            onClick={(e) => handleBankAccountModal(e, row.bankAccounts)}
                          >
                            View details
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>

      <CheckboxDialog
        open={companySelectModalOpen}
        setOpen={setCompanySelectModalOpen}
        values={selectCompanyValues}
        setValues={setSelectCompanyValues}
        options={options}
        position={position}
        handleApply={handleCompanySelectApply}
        title={title}
      />
      <DateDialog
        open={approvedDateSelectModalOpen}
        setOpen={setApprovedDateSelectModalOpen}
        date={approvedDate}
        setDate={setApprovedDate}
        timeframe={approvedDatetimeframe}
        setTimeframe={setApprovedDateTimeframe}
        handleApply={handleApprovedDateSelectApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={paidDateSelectModalOpen}
        setOpen={setPaidDateSelectModalOpen}
        date={paidDate}
        setDate={setPaidDate}
        timeframe={paidDatetimeframe}
        setTimeframe={setPaidDateTimeframe}
        handleApply={handlePaidDateSelectApply}
        position={position}
        title={title}
      />
      <AmountSliderDialog
        open={amountSelectModalOpen}
        setOpen={setAmountSelectModalOpen}
        values={requestAmount}
        setValues={setRequestAmount}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handleAmountSelectApply}
        title={title}
      />
      <AmountSliderDialog
        open={paidAmountSelectModalOpen}
        setOpen={setPaidAmountSelectModalOpen}
        values={paidAmount}
        setValues={setPaidAmount}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handlePaidAmountSelectApply}
        title={title}
      />
      <AccountDetailsDialog
        open={accountModalOpen}
        setOpen={setAccountModalOpen}
        position={position}
        bankAccounts={bankAccounts}
      />
    </div>
  );
};

export default EmployeePayment;
