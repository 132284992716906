import AttachmentIcon from '@mui/icons-material/Attachment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { convertTimestampToDateAndTime } from '../../../../../../utils/dataFormat';
import PendingActions from './actions';

function PendingPayslipsTable({
  paySlips,
  getItemPosition,
  setPersonalInfoDataDialog,
  setPersonalInfoDialogModal,
  setPosition,
  handlePayslipView,
  handleApproveClick,
}) {
  return (
    <Table aria-label="simple table" className="custom-table">
      <TableHead>
        <TableRow>
          <TableCell key="User Name">User Name</TableCell>
          <TableCell key="Date Uploaded">Date Uploaded</TableCell>
          <TableCell key="Attachment">Attachment</TableCell>
          <TableCell key="Bank Statement">Bank Statement</TableCell>
          <TableCell key="Bank Statement">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paySlips.map((payslip) => (
          <TableRow key={`pending-payslip-${payslip.userId}`}>
            <TableCell>
              {payslip.firstName && `${payslip.firstName} ${payslip.lastName}`}
              {payslip.personalInfo && (
                <span>
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ width: '16px', height: '16px', cursor: 'pointer' }}
                    onClick={(e) => {
                      const itemPosition = getItemPosition(e);
                      itemPosition.left -= 80;
                      setPosition(itemPosition);
                      setPersonalInfoDataDialog({
                        name: payslip.personalInfo.fullLegalName,
                        email: payslip.workEmail,
                        linkedIn: payslip.personalInfo.dateOfBirth,
                        phone: payslip.personalInfo.mobileNumber,
                        address: payslip.personalInfo.address,
                      });
                      setPersonalInfoDialogModal(true);
                    }}
                  />
                </span>
              )}
            </TableCell>
            <TableCell>{convertTimestampToDateAndTime(payslip.dateUploaded)}</TableCell>
            <TableCell>
              <button
                type="button"
                className="button-style"
                onClick={() => handlePayslipView(payslip.filename)}
              >
                <AttachmentIcon className="attatchment-rotate" />
                payslip
              </button>
            </TableCell>
            <TableCell>
              {payslip.bankStatementFilename ? (
                <button
                  type="button"
                  className="button-style"
                  onClick={() => handlePayslipView(payslip.bankStatementFilename)}
                >
                  <AttachmentIcon className="attatchment-rotate" />
                  bank statement
                </button>
              ) : (
                <span>--------------</span>
              )}
            </TableCell>
            <TableCell align="center">
              <PendingActions payslip={payslip} handleApproveFn={handleApproveClick} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default PendingPayslipsTable;
