import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCompanyInfoCount } from '../../app/companyDetails/companyInfoCountSlice';
import { fetchCompanyEmployees } from '../../app/companyEmployees/companyEmployeesSlice';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import Loader from '../Loader/Loader';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './CompanyEmployees.scss';

const CompanyEmployees = () => {
  const dispatch = useDispatch();
  const tabsData = ['All', 'Eligible', 'Not eligible'];
  const { isLoading: infoLoading, infoCount } = useSelector((state) => state.companyInfoCount);
  const { isLoading, employees, totalCount, companyName } = useSelector(
    (state) => state.companyEmployees
  );
  const [status, setStatus] = useState(tabsData[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const params = useParams();
  const isNotEligible = status.toUpperCase() === 'NOT ELIGIBLE';
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  useEffect(() => {
    let statusData = '';
    if (isNotEligible) {
      statusData = 'not-eligible';
    } else {
      statusData = status.toLowerCase();
    }
    dispatch(
      fetchCompanyEmployees({
        companyId: params.companyId,
        status: statusData,
        limit: rowsPerPage,
        offset: page,
      })
    );
  }, [status, page, rowsPerPage, params.companyId]);
  const headings = [
    'Employee name',
    'Total annual leave ($)',
    'Total annual leave (hours)',
    'Claimable amount ($)',
    'Eligible',
  ];
  useEffect(() => {
    dispatch(fetchCompanyInfoCount(params.companyId));
  }, [dispatch]);
  return (
    <div className="root-div" style={{ flex: 1, gap: '16px' }}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb" className="company-bredcumb">
          <Link underline="hover" color="inherit" to="/b2b-companies">
            <span className="icon-wrapper">
              <ArrowBackIosIcon className="arrow-icon" />
            </span>
            Companies
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            to={`/company/${params.companyId}`}
            aria-current="page"
          >
            <span>{companyName}</span>
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            href="/material-ui/react-breadcrumbs/"
            aria-current="page"
          >
            <span className="span-text">Employees</span>
          </Link>
        </Breadcrumbs>
      </div>
      <h4 className="company-name">Employees</h4>
      {infoLoading ? (
        <div className="box-container">
          {[...Array(4)].map(() => (
            <div className="box">
              <Skeleton variant="text" width={50} height={22} />
              <Skeleton variant="text" width={120} height={22} />
            </div>
          ))}
        </div>
      ) : (
        <div className="box-container">
          <div className="box">
            <h5>{infoCount.totalDownloads}</h5>
            <span className="box-span">Downloaded app</span>
          </div>
          <div className="box">
            <h5>
              $
              {infoCount.avgClaimAmount &&
                formatNumberWithThousandSeparator(infoCount.avgClaimAmount.toFixed(2))}
            </h5>
            <span className="box-span">Avg. claim amount</span>
          </div>
          <div className="box">
            <h5>{infoCount.totalClaims}</h5>
            <span className="box-span">Total claims</span>
          </div>
          <div className="box">
            <h5>
              $
              {(infoCount.totalUncliamedLeaves &&
                formatNumberWithThousandSeparator(infoCount.totalUncliamedLeaves.toFixed(2))) ||
                0}
            </h5>
            <span className="box-span">Total unclaimed leaves</span>
          </div>
        </div>
      )}
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!employees.length) {
            return (
              <Typography variant="h4" className="message-style">
                There are no new {status.toLowerCase()} requests yet
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headings.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((row) => (
                  <TableRow key={row} component={Link} to={`/employee-details/${row._id}`} hover>
                    <TableCell>{`${row.employeeFirstName} ${row.employeeLastName}`}</TableCell>
                    <TableCell>
                      {row.totalAnnualLeave &&
                        formatNumberWithThousandSeparator(row.totalAnnualLeave.toFixed(2))}
                    </TableCell>
                    <TableCell>
                      {row.totalAnnualLeaveTime && row.totalAnnualLeaveTime.toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {row.claimableAmount &&
                        formatNumberWithThousandSeparator(row.claimableAmount.toFixed(2))}
                    </TableCell>
                    <TableCell>
                      <div
                        className="eligible-div"
                        style={{
                          backgroundColor: `${row.eligibility ? '#00804A' : '#CCCDDC'}`,
                        }}
                      >
                        {row.isEligible ? (
                          <CheckIcon className="eligible-icon" />
                        ) : (
                          <ClearIcon className="not-eligible-icon" />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
    </div>
  );
};

export default CompanyEmployees;
