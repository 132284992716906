export const SafetyNetApplicationPayslipUpdateRequiredReason = {
  PAYSLIP_NOT_PAYSLIP: 'The uploaded document is not a pay slip',
  PAYSLIP_INFORMATION_NOT_AVAILABLE: 'The required information is not available and legible',
  PAYSLIP_NOT_LATEST: "It's not the latest payslip",
  PAYSLIP_PERSONAL_INFORMATION_DOES_NOT_MATCH:
    "Personal information doesn't match with information on payslip",
};

export const SafetyNetApplicationBankStatementUpdateRequiredReason = {
  BS_NOT_BS: 'The uploaded document is not a bank statement',
  BS_INFORMATION_NOT_AVAILABLE:
    'The required information on the bank statement is not available or legible',
  BS_NOT_LATEST: 'It’s not the latest bank statement',
  BS_ACCOUNT_MISMATCH:
    "The account displayed on the bank statement does not match the account where the user's salary is deposited",
  BS_PERSONAL_INFORMATION_MISMATCH:
    'Personal information doesn’t match with information on bank statement',
  BS_INSUFFICIENT_FUNDS:
    "The bank statement does not reflect sufficient funds or regular transactions to validate the user's financial status",
  BS_MINIMUM_HISTORY:
    'The bank statement doesn’t show a minimum of 3 months of transaction history',
};

export const SafetyNetApplicationUpdateRequiredReason = {
  ...SafetyNetApplicationPayslipUpdateRequiredReason,
  ...SafetyNetApplicationBankStatementUpdateRequiredReason,
};

export const SafetyNetApplicationPayslipRejectionReason = {
  PAYSLIP_NOT_ENOUGH_LEAVE: 'Not enough leave',
};

export const SafetyNetApplicationBankStatementRejectionReason = {
  BS_UNUSUAL_BEHAVIOURS: 'Unusual spending patterns or behaviours',
  BS_FAILED_CREDIT_ASSESSMENT: 'Fail credit assessment',
};

export const SafetyNetApplicationRejectionReason = {
  ...SafetyNetApplicationPayslipRejectionReason,
  ...SafetyNetApplicationBankStatementRejectionReason,
};
