import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, InputLabel, TextField } from '@mui/material';
import React from 'react';
import './CheckboxAutocompleteWithLabel.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckboxAutocompleteWithLabel = ({
  label,
  value,
  setValue,
  options,
  placeholder,
  disabled,
}) => {
  return (
    <div className="checkbox-autocomplete-with-label-wrapper">
      <InputLabel className="input-label">{label}</InputLabel>
      <Autocomplete
        multiple
        className="autocomplete-component"
        disableCloseOnSelect
        options={options}
        getOptionLabel={(option) => option.label}
        value={value}
        onChange={(e, newVal) => setValue(newVal)}
        placeholder={placeholder}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

export default CheckboxAutocompleteWithLabel;
