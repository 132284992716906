function sleep(ms) {
  let timeoutId;
  return new Promise((resolve) => {
    timeoutId = setTimeout(() => {
      clearTimeout(timeoutId);
      resolve();
    }, ms);
  });
}

export default sleep;
