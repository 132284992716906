import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePost, privatePut } from '../../utilities/apiCaller';

export const fetchPaySlips = createAsyncThunk(
  'paySlip/fetchPaySlips',
  async ({ status, limit, offset, uploadDate, rejectDate, rejectReason }, { rejectWithValue }) => {
    try {
      let url = `/data/b2c/payslips?limit=${limit}&offset=${
        offset + 1
      }&payslipStatus=${status.toLowerCase()}`;
      if (uploadDate) url += `&uploadDate=${uploadDate}`;
      if (rejectDate) url += `&rejectDate=${rejectDate}`;
      if (rejectReason) url += `&rejectReason=${rejectReason}`;
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaySlip = createAsyncThunk(
  'paySlip/updatePayslip',
  async (updateData, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2c/payslips', updateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSignUrl = createAsyncThunk('paySlip/signurl', async (data, { rejectWithValue }) => {
  try {
    const response = await privatePost('/data/b2c/payslips/signed-url', data);
    console.log(response);
    return response.URL;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const paySlipSlice = createSlice({
  name: 'paySlip',
  initialState: {
    paySlips: [],
    unfiltredPayslips: null,
    isLoading: false,
    totalCount: 0,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaySlips.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.paySlips = [];
      })
      .addCase(fetchPaySlips.fulfilled, (state, action) => {
        state.paySlips = action.payload.payslipsList;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
        state.unfiltredPayslips = null;
      })
      .addCase(fetchPaySlips.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.paySlips = [];
        state.totalCount = 0;
        state.unfiltredPayslips = null;
      })
      .addCase(updatePaySlip.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePaySlip.fulfilled, (state, { payload }) => {
        const { payslipData } = payload;
        state.paySlips = state.paySlips.filter((paySlip) => paySlip._id !== payslipData._id);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updatePaySlip.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
  reducers: {
    setPaySlipsBySearchToken: (state, action) => {
      if (!state.unfiltredPayslips) {
        state.unfiltredPayslips = [...state.paySlips];
      }
      const { token } = action.payload;
      state.paySlips = state.unfiltredPayslips.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
});

export const { setPaySlipsBySearchToken } = paySlipSlice.actions;

export default paySlipSlice.reducer;
