import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePost } from '../../utilities/apiCaller';

export const fetchB2BClaims = createAsyncThunk(
  'claimsB2B/fetchB2BClaims',
  async ({ status, limit, offset, company, dateRequested, requestAmount }, { rejectWithValue }) => {
    try {
      let url = `/data/b2b/claims?limit=${limit}&offset=${offset + 1}&claimStatus=${status}`;
      if (company) {
        url += `&company=${company}`;
      }
      if (dateRequested) {
        url += `&dateRequested=${dateRequested}`;
      }
      if (requestAmount) {
        url += `&requestAmount=${requestAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getB2BClaimsAgreementSignUrl = createAsyncThunk(
  'claimsB2B/getAgreementSignedUrl',
  async ({ agreementInfo }, { rejectWithValue }) => {
    try {
      const res = await privatePost('/data/view-agreement', agreementInfo);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const claimsB2BSlice = createSlice({
  name: 'claimsB2B',
  initialState: {
    b2bClaims: [],
    unfiltredClaims: null,
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    setb2bClaimsBySearchToken: (state, action) => {
      if (!state.unfiltredClaims) {
        state.unfiltredClaims = [...state.b2bClaims];
      }
      const { token } = action.payload;
      state.b2bClaims = state.unfiltredClaims.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2BClaims.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2BClaims.fulfilled, (state, action) => {
        state.b2bClaims = action.payload.claimData;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2BClaims.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.b2bClaims = [];
        state.totalCount = 0;
      });
  },
});
export const { setb2bClaimsBySearchToken } = claimsB2BSlice.actions;
export default claimsB2BSlice.reducer;
