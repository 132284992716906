import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGetV2 } from '../../utilities/apiCaller';

export const fetchB2CTasks = createAsyncThunk(
  'b2cMyTask/fetchB2CTasks',
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGetV2(`/data/b2c/dashboard/my-tasks`, token);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2cMyTaskSlice = createSlice({
  name: 'b2cMyTask',
  initialState: {
    task: {},
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2CTasks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2CTasks.fulfilled, (state, action) => {
        state.task = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2CTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.task = {};
        state.totalCount = 0;
      });
  },
});

export default b2cMyTaskSlice.reducer;
