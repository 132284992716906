/* eslint-disable no-await-in-loop */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGetV2 } from '../../utilities/apiCaller';

export const fetchB2BDashboardBusinessRepayments = createAsyncThunk(
  'b2bDashboardBusinessRepayments/fetchB2BDashboardBusinessRepayments',
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGetV2(`/data/b2b/dashboard/business-repayments`, token);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2bDashboardBusinessRepaymentsSlice = createSlice({
  name: 'b2bDashboardBusinessRepayments',
  initialState: {
    DashboardBusinessRepayments: {},
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2BDashboardBusinessRepayments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2BDashboardBusinessRepayments.fulfilled, (state, action) => {
        state.DashboardBusinessRepayments = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2BDashboardBusinessRepayments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.DashboardBusinessRepayments = {};
        state.totalCount = 0;
      });
  },
});

export default b2bDashboardBusinessRepaymentsSlice.reducer;
