/* eslint-disable no-unused-vars */
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './CompanyDetails.scss';

const CompanyInfoSkeleton = () => {
  return (
    <div className="company-info">
      <h2>
        <Skeleton variant="text" width={200} />
      </h2>

      <div className="child-row">
        {[...Array(4)].map((_) => (
          <div className="box-content">
            <h3>
              <Skeleton variant="text" width={80} />
            </h3>
            <h5>
              <Skeleton variant="text" width={120} />
            </h5>
          </div>
        ))}
      </div>
      <div className="child-row">
        {[...Array(4)].map((_) => (
          <div className="box-content">
            <h3>
              <Skeleton variant="text" width={100} />
            </h3>
            <h5>
              <Skeleton variant="text" width={120} />
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyInfoSkeleton;
