import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const getAnalyticsData = createAsyncThunk(
  'analyticsData/getAnalyticsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await privateGet('/data/analytics/url');
      return response.embedUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const analyticsDataSlice = createSlice({
  name: 'analyticsData',
  initialState: {
    embedUrl: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAnalyticsData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAnalyticsData.fulfilled, (state, action) => {
        state.embedUrl = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAnalyticsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.embedUrl = null;
      });
  },
});

export default analyticsDataSlice.reducer;
