import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import * as React from 'react';
import BaseSelectModal from './BaseSelectModal';
import './Dialog.scss';

const DateDialog = (props) => {
  const {
    date,
    setDate,
    open,
    setOpen,
    timeframe,
    setTimeframe,
    title,
    position,
    handleApply,
    ...other
  } = props;

  // const handleChange = (event) => { };

  const handleSelectChange = (e) => {
    setTimeframe(e.target.value);
  };

  const onDateChange = (newDate) => {
    setDate(dayjs(newDate));
  };
  const handleReset = () => {
    setDate(dayjs(Date.now()));
    setTimeframe('before');
  };

  return (
    <BaseSelectModal
      title={title}
      open={open}
      setOpen={setOpen}
      position={position}
      handleApply={handleApply}
      handleReset={handleReset}
      {...other}
    >
      <Box className="date-select-content-wrapper">
        <FormControl className="select-formcontrol" size="small" sx={{ mr: 1, minWidth: 80 }}>
          <Select
            value={timeframe}
            onChange={handleSelectChange}
            displayEmpty
            className="select"
            inputProps={{ 'aria-label': 'Timeframe' }}
          >
            <MenuItem value="before">Before</MenuItem>
            <MenuItem value="after">After</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="datepicker-formcontrol" size="small">
          <DatePicker
            slotProps={{
              inputAdornment: { position: 'start' },
              textField: { size: 'small', color: 'primary' },
            }}
            value={date}
            onChange={onDateChange}
          />
        </FormControl>
      </Box>
    </BaseSelectModal>
  );
};

DateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  timeframe: PropTypes.string.isRequired,
  setTimeframe: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
};

export default DateDialog;
