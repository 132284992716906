import { combineReducers } from '@reduxjs/toolkit';
import storage from 'localforage';
import analyticsDataReducer from './Analytics/AnalyticsUrlSlice';
import b2bBusinessRepaymentsReducer from './b2bBusinessRepayments/b2bBusinessRepaymentsSlice';
import b2bCompanyDashboardInfoSlice from './b2bCompanyInfo/b2bCompanyInfoSlice';
import b2bDashboardBusinesssSlice from './b2bCompanyInfo/b2bDashboardBusinessSlice';
import b2bDashboardMyTaskSlice from './b2bCompanyInfo/b2bMyTaskSlice';
import b2bEmployeesReducer from './b2bEmployeePayment/b2bEmployeePaymentSlice';
import b2cBusinessRepaymentsReducer from './b2cBusinessRepayments/b2cBusinessRepaymentsSlice';
import b2cDashboardCompanyInfoSlice from './b2cDashboard/b2cCompanyInfoSlice';
import b2cDashboardBusinessSlice from './b2cDashboard/b2cDashboardBusinessSlice';
import b2cDashboardMyTaskSlice from './b2cDashboard/b2cMyTaskSlice';
import b2cEmployeesReducer from './b2cEmployeePayment/b2cEmployeePayment';
import calimsb2bReducer from './claimsb2b/calimsb2bSlice';
import B2CclaimsReducer from './claimsb2c/claimsb2cSlice';
import companyReducer from './companiesb2b/companySlice';
import companyInfoCountReducer from './companyDetails/companyInfoCountSlice';
import companyInfoReducer from './companyDetails/companyInfoSlice';
import companyEmployeeDetailsReducer from './companyEmployees/companyEmployeeDetailsSlice';
import companyEmployeesReducer from './companyEmployees/companyEmployeesSlice';
import employeeClaimsReducer from './companyEmployees/employeeClaimsSlice';
import creditCheckB2BReducer from './creditCheckb2b/creditCheckBtoBSlice';
import loginReducer from './login/loginSlice';
import notificationReducer from './notificationCentre/notificationSlice';
import notificationsRecipientsReducer from './notificationCentre/recipientsSlice';
import paySlipsReducer from './paySlips/paySlipSlice';
import userDetailsReducer from './userRepository/userDetailsSlice';
import repositoryUserInfoReducer from './userRepository/userInfoSlice';
import userRepositorieSlice from './userRepository/userRepositorySlice';

const appReducer = combineReducers({
  loginReducer,
  b2bEmployees: b2bEmployeesReducer,
  b2cEmployees: b2cEmployeesReducer,
  b2bClaims: calimsb2bReducer,
  b2cClaims: B2CclaimsReducer,
  companies: companyReducer,
  creditChecksB2B: creditCheckB2BReducer,
  paySlips: paySlipsReducer,
  b2bBusinessRepayments: b2bBusinessRepaymentsReducer,
  b2cBusinessRepayments: b2cBusinessRepaymentsReducer,
  userRepositories: userRepositorieSlice,
  b2bCompanyDashboardInfo: b2bCompanyDashboardInfoSlice,
  b2bDashboardBusiness: b2bDashboardBusinesssSlice,
  b2cDashboardBusinessRepayments: b2cDashboardBusinessSlice,
  b2bDashboardMyTask: b2bDashboardMyTaskSlice,
  b2cDashboardMyTask: b2cDashboardMyTaskSlice,
  b2cCompanyInfo: b2cDashboardCompanyInfoSlice,
  companyEmployees: companyEmployeesReducer,
  companyInfoData: companyInfoReducer,
  companyInfoCount: companyInfoCountReducer,
  notification: notificationReducer,
  repositoryUserDetails: userDetailsReducer,
  repositoryUserInfo: repositoryUserInfoReducer,
  companyEmployeeDetails: companyEmployeeDetailsReducer,
  companyEmployeeClaims: employeeClaimsReducer,
  analytics: analyticsDataReducer,
  notificationsRecipients: notificationsRecipientsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'loginReducer/logout') {
    state = {};
    storage.removeItem('persist:loginReducer');
  }

  return appReducer(state, action);
};

export default rootReducer;
