import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchB2CCompanyInfo = createAsyncThunk(
  'b2cCompanyInfo/fetchB2CCompanyInfo',
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet(`/data/b2c/dashboard/company-info`, token);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2cCompanyInfoSlice = createSlice({
  name: 'b2cCompanyInfo',
  initialState: {
    companyInfo: {},
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2CCompanyInfo.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2CCompanyInfo.fulfilled, (state, action) => {
        state.companyInfo = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2CCompanyInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.companyInfo = {};
        state.totalCount = 0;
      });
  },
});

export default b2cCompanyInfoSlice.reducer;
