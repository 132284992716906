import AttachmentIcon from '@mui/icons-material/Attachment';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteB2BCreditCheckFile,
  fetchCreditCheck,
  getB2BCreditCheckSignedUrl,
  setCreditChecksBySearchToken,
  updateCreditCheckStatus,
  uploadCreditCheckDoc,
} from '../../app/creditCheckb2b/creditCheckBtoBSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { convertTimestampToDate } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import { industrySelect, locationSelect } from '../../utils/selectOptions';
import { shortStringWithEllipsis } from '../../utils/shortStrWithEllipsis';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import UploadDialog from '../Dialog/UploadDialog/UploadDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './CreditCheck.scss';
import CreditCheckPopup from '../Tooltip/CreditCheckPopup';

const CreditCheck = () => {
  const dispatch = useDispatch();
  const { isLoading, creditChecks, totalCount } = useSelector((state) => state.creditChecksB2B);
  const filterButtons = [{ name: 'Joined in' }, { name: 'Industry' }, { name: 'Location' }];

  const pendingHeadings = ['Date', 'Name', 'ABN', 'Industry', 'Location', 'Credit Check Report'];
  const rejectedHeadings = ['Date Joined', 'Name', 'ABN', 'Industry', 'Location', 'Date Rejected'];
  const tabsData = ['Pending', 'Rejected'];
  const [status, setStatus] = useState(tabsData[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [applyClicked, setApplyClicked] = useState(false);
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  const [searchToken, setSearchToken] = useState();
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [locationSelectModalOpen, setLocationSelectModalOpen] = useState(false);
  const [industrySelectModalOpen, setIndustrySelectModalOpen] = useState(false);
  const [dateSelectModalOpen, setDateSelectModalOpen] = useState(false);
  const [selectIndustryValues, setSelectIndustryValues] = useState([]);
  const [selectLocationValues, setSelectLocationValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [timeframe, setTimeframe] = useState('before');
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [file, setFile] = useState(null);
  const [joinDate, setJoinDate] = useState(dayjs(Date.now()));

  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'industry') {
      setTitle('Industry');
      setOptions(industrySelect);
      setIndustrySelectModalOpen(!industrySelectModalOpen);
    }
    if (type === 'location') {
      setTitle('Location');
      setOptions(locationSelect);
      setLocationSelectModalOpen(!locationSelectModalOpen);
    }
    if (type === 'joined-in') {
      setTitle('Joined in');
      setDateSelectModalOpen(!dateSelectModalOpen);
    }
  };

  const handleIndustrySelectApply = () => {
    setApplyClicked(true);
    setIndustrySelectModalOpen(false);
  };
  const handleLocationSelectApply = () => {
    setApplyClicked(true);
    setLocationSelectModalOpen(false);
  };

  const handleDateSelectApply = () => {
    setApplyClicked(true);
    setDateSelectModalOpen(false);
  };
  const isPending = status.toUpperCase() === 'PENDING';
  const headingsToRender = isPending ? pendingHeadings : rejectedHeadings;
  const handleChangeCreditCheckStatus = (data) => {
    dispatch(updateCreditCheckStatus(data));
  };
  useEffect(() => {
    dispatch(fetchCreditCheck({ status: status.toUpperCase(), limit: rowsPerPage, offset: page }));
  }, [dispatch, status, rowsPerPage, page]);

  const uploadClickHandler = (row) => {
    setSelectedCompany(row);
    setUploadModalOpen(true);
  };

  const handleUploadApply = () => {
    dispatch(
      uploadCreditCheckDoc({
        companyId: selectedCompany.companyId,
        file: file[0],
        filename: file[0]?.name.slice(0, -4),
      })
    );
    setUploadModalOpen(false);
  };

  useEffect(() => {
    if (applyClicked) {
      const filterDate = timeframe + joinDate.toISOString().substring(0, 10);
      dispatch(
        fetchCreditCheck({
          status: status.toUpperCase(),
          limit: rowsPerPage,
          offset: page,
          industry: selectIndustryValues.join('$'),
          location: selectLocationValues.map((value) => value.toUpperCase()).join('$'),
          joinedDate: filterDate,
        })
      );
      setApplyClicked(false);
    }
  }, [applyClicked]);
  useDidUpdateEffect(() => {
    dispatch(setCreditChecksBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const [isCreditCheckFileHovered, setIsCreditCheckFileHovered] = useState(false);
  const showUploadedFile = async (fileName) => {
    const resp = await dispatch(getB2BCreditCheckSignedUrl({ fileName })).unwrap();
    window.open(resp?.URL, '_blank');
  };

  const deleteUploadedFile = async (e, companyId) => {
    e.stopPropagation();
    dispatch(deleteB2BCreditCheckFile({ companyId }));
  };

  return (
    <div className="main-credit" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Credit check</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterButtons} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!creditChecks.length) {
            return (
              <Typography variant="h4" className="message-style">
                No {status.toLowerCase()} Credit check to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                  {isPending && <TableCell align="center">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending
                  ? creditChecks.map((row) => (
                      <TableRow key={row.companyId} hover>
                        <TableCell>
                          {row.joinedDate && convertTimestampToDate(row.joinedDate)}
                        </TableCell>
                        <TableCell>
                          <CreditCheckPopup row={row}>
                            <span>{row.companyName}</span>
                          </CreditCheckPopup>
                        </TableCell>
                        <TableCell>{row.abn}</TableCell>
                        <TableCell>{row.industry}</TableCell>
                        <TableCell>
                          {row.abnBusinessLocationPostalCode &&
                            `${row.abnBusinessLocationPostalCode} ${row.abnBusinessLocationState}`}
                        </TableCell>
                        <TableCell>
                          {row.creditCheckReport ? (
                            <Button
                              onMouseEnter={() => setIsCreditCheckFileHovered(true)}
                              onMouseLeave={() => setIsCreditCheckFileHovered(false)}
                              type="button"
                              variant="outlined"
                              className="attachment-file-wrapper"
                              onClick={() => showUploadedFile(row.creditCheckReport)}
                            >
                              <AttachmentIcon
                                style={{ transform: 'rotate(90deg)', marginRight: '4px' }}
                              />
                              {row.attachment}{' '}
                              <span>
                                {shortStringWithEllipsis(row.creditCheckReport.split('/')[1], 5)}
                              </span>
                              {isCreditCheckFileHovered && (
                                <CancelIcon
                                  onClick={(e) => deleteUploadedFile(e, row.companyId)}
                                  fontSize="small"
                                  className="cancel-icon"
                                />
                              )}
                            </Button>
                          ) : (
                            <Button variant="outlined" onClick={() => uploadClickHandler(row)}>
                              Upload
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="button-action-style">
                            <button
                              type="button"
                              className="reject"
                              onClick={() =>
                                handleChangeCreditCheckStatus({
                                  action: 'REJECTED',
                                  companyId: row.companyId,
                                })
                              }
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              className="approve"
                              onClick={() =>
                                handleChangeCreditCheckStatus({
                                  action: 'APPROVED',
                                  companyId: row.companyId,
                                })
                              }
                            >
                              Approve
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  : creditChecks.map((row) => (
                      <TableRow key={row.companyId}>
                        <TableCell>{convertTimestampToDate(row.joinedDate)}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        <TableCell>{row.abn}</TableCell>
                        <TableCell>{row.industry}</TableCell>
                        <TableCell>{`${row.abnBusinessLocationState} ${row.abnBusinessLocationPostalCode}`}</TableCell>
                        <TableCell>
                          {row.dateRejected && convertTimestampToDate(row.dateRejected)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <CheckboxDialog
        open={industrySelectModalOpen}
        setOpen={setIndustrySelectModalOpen}
        values={selectIndustryValues}
        setValues={setSelectIndustryValues}
        options={options}
        position={position}
        handleApply={handleIndustrySelectApply}
        title={title}
      />
      <CheckboxDialog
        open={locationSelectModalOpen}
        setOpen={setLocationSelectModalOpen}
        values={selectLocationValues}
        setValues={setSelectLocationValues}
        options={options}
        position={position}
        handleApply={handleLocationSelectApply}
        title={title}
      />
      <DateDialog
        open={dateSelectModalOpen}
        setOpen={setDateSelectModalOpen}
        date={joinDate}
        setDate={setJoinDate}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        handleApply={handleDateSelectApply}
        position={position}
        title={title}
      />
      <UploadDialog
        title="Upload credit check report"
        file={file}
        setFile={setFile}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        subTitle={`Company name: ${selectedCompany.companyName}`}
        handleApply={handleUploadApply}
        value={selectedCompany}
      />
    </div>
  );
};

export default CreditCheck;
