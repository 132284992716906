import WarningIcon from '@mui/icons-material/Warning';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';
import './DeleteDialog.scss';

const DeleteDialog = (props) => {
  const { open, setOpen, type, additionalSubTitle, handleApply, ...other } = props;

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteNotification = () => {
    setOpen(false);
    handleApply();
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title={`Sure you want to delete ${type}?`}
      titleMiddle
      titleIcon={
        <Avatar sx={{ borderRadius: '10%', background: 'white' }}>
          <WarningIcon color="yellow" className="warning-icon" fontSize="large" />
        </Avatar>
      }
      actionButtons={[
        {
          label: `Delete ${type}`,
          variant: 'contained',
          className: 'delete-btn',
          onClick: handleDeleteNotification,
          color: 'red',
        },
        { label: 'Cancel', onClick: handleClose },
      ]}
      subTitle={`Deleting this ${type} means it will no longer exist ${additionalSubTitle}`}
      className="delete-dialog"
      {...other}
    />
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  additionalSubTitle: PropTypes.string.isRequired,
  handleApply: PropTypes.func.isRequired,
};

export default DeleteDialog;
