import { Autocomplete, InputLabel, TextField, CircularProgress } from '@mui/material';
import React from 'react';
import './SelectAutocompleteWithLabel.scss';

const SelectAutocompleteWithLabel = ({
  label,
  value,
  setValue,
  options,
  placeholder,
  isLoading,
}) => {
  return (
    <div className="select-autocomplete-with-label-wrapper">
      <InputLabel className="input-label">{label}</InputLabel>
      <Autocomplete
        className="autocomplete-component"
        value={value}
        onChange={(e, newVal) => setValue(newVal)}
        options={options}
        placeholder={placeholder}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        // renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

export default SelectAutocompleteWithLabel;
