import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchB2CBusinessRepayments = createAsyncThunk(
  'b2cBusinessRepayments/fetchData',
  async (
    { status, limit, offset, company, approvedDate, totalAmount, paymentMethod },
    { rejectWithValue }
  ) => {
    try {
      let url = `/data/b2c/business-repayments?limit=${limit}&offset=${
        offset + 1
      }&status=${status}`;
      if (company) {
        url += `&company=${company}`;
      }
      if (paymentMethod) {
        url += `&paymentMethod=${paymentMethod}`;
      }
      if (approvedDate) {
        url += `&approvedDate=${approvedDate}`;
      }
      if (totalAmount) {
        url += `&totalAmount=${totalAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2cBusinessRepaymentsSlice = createSlice({
  name: 'b2cBusinessRepayments',
  initialState: {
    repaymentsData: [],
    unfiltredrepaymentsData: null,
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    setRepaymentsDataBySearchToken: (state, action) => {
      if (!state.unfiltredrepaymentsData) {
        state.unfiltredrepaymentsData = [...state.repaymentsData];
      }
      const { token } = action.payload;
      state.repaymentsData = state.unfiltredrepaymentsData.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2CBusinessRepayments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2CBusinessRepayments.fulfilled, (state, action) => {
        state.repaymentsData = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2CBusinessRepayments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.repaymentsData = [];
        state.totalCount = 0;
      });
  },
});
export const { setRepaymentsDataBySearchToken } = b2cBusinessRepaymentsSlice.actions;
export default b2cBusinessRepaymentsSlice.reducer;
