import { Grid as MuiGrid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import PropTypes from 'prop-types';
import { fromDayJSToString, fromStringToDayJS } from '../../helpers/date-helper';
import StyledDatePicker from './components/styled-date-picker';
import StyledInputLabel from './components/styled-input-label';

function DateInput({ label, value, setValue, name, required, ...props }) {
  const handlePayDayOnChange = (dateDayJS) => {
    if (!dateDayJS) {
      setValue(undefined);
      return;
    }
    const parsedDate = fromDayJSToString(dateDayJS);
    setValue(parsedDate);
  };

  return (
    <MuiGrid item xs={12}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <StyledInputLabel>
          {label}
          {required ? '*' : ''}
        </StyledInputLabel>
        <StyledDatePicker
          name={name}
          value={fromStringToDayJS(value)}
          onChange={handlePayDayOnChange}
          {...props}
        />
      </LocalizationProvider>
    </MuiGrid>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

DateInput.defaultProps = {
  required: false,
  value: undefined,
};

export default DateInput;
