import { Modal, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import './SendModal.scss';

const SendModal = (props) => {
  const { open, setOpen, position, handleApply, ...other } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="send-modal"
      style={{ position: 'absolute', top: position.top, left: position.left }}
      {...other}
    >
      <Paper elevation={12}>
        <Button className="schedule-send-btn" onClick={handleApply}>
          Schedule send
        </Button>
      </Paper>
    </Modal>
  );
};

SendModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
};

export default SendModal;
