import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePost, privatePut } from '../../utilities/apiCaller';

export const fetchCreditCheck = createAsyncThunk(
  'CreditCheckB2B/fetchCreditCheck',
  async ({ status, limit, offset, location, industry, joinedDate }, { rejectWithValue }) => {
    try {
      let url = `/data/b2b/credit-check?limit=${limit}&offset=${
        offset + 1
      }&creditCheckStatus=${status}`;
      if (industry) {
        url += `&industry=${industry}`;
      }
      if (location) {
        url += `&location=${location}`;
      }
      if (joinedDate) {
        url += `&joinedDate=${joinedDate}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const uploadCreditCheckDoc = createAsyncThunk(
  'CreditCheckB2B/uploadCreditCheckDoc',
  async ({ companyId, file, filename }, { rejectWithValue }) => {
    try {
      const response = await privatePost(
        `/data/b2b/credit-check/upload?companyId=${companyId}`,
        {
          file,
          filename,
        },
        { 'Content-Type': 'multipart/form-data' }
      );
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const uploadCreditCheckDocFromCompany = createAsyncThunk(
  'CreditCheckB2B/uploadCreditCheckDocFromCompany',
  async ({ companyId, file, filename }, { rejectWithValue }) => {
    try {
      const response = await privatePost(
        `/data/b2b/credit-check/upload?companyId=${companyId}`,
        {
          file,
          filename,
        },
        { 'Content-Type': 'multipart/form-data' }
      );
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const updateCreditCheckStatus = createAsyncThunk(
  'CreditCheckB2B/updateCreditCheckStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2b/credit-check', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getB2BCreditCheckSignedUrl = createAsyncThunk(
  'CreditCheckB2B/getB2BCreditCheckSignedUrl',
  async ({ fileName }, { rejectWithValue }) => {
    try {
      const res = await privateGet(`/data/b2b/credit-check/signed-url?fileName=${fileName}`);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteB2BCreditCheckFile = createAsyncThunk(
  'CreditCheckB2B/deleteB2BCreditCheckFile',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const res = await privatePut('/data/b2b/credit-check/delete', { companyId });
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const creditCheckBtoBSlice = createSlice({
  name: 'CreditCheck',
  initialState: {
    creditChecks: [],
    unfiltredCreditChecks: null,
    isLoading: false,
    error: false,
    success: false,
    errorMessage: '',
    totalCount: 0,
  },
  reducers: {
    setCreditChecksBySearchToken: (state, action) => {
      if (!state.unfiltredCreditChecks) {
        state.unfiltredCreditChecks = [...state.creditChecks];
      }
      const { token } = action.payload;
      state.creditChecks = state.unfiltredCreditChecks.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditCheck.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCreditCheck.fulfilled, (state, action) => {
        state.creditChecks = action.payload.creditCheckList;
        state.isLoading = false;
        state.errorMessage = '';
        state.success = true;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchCreditCheck.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.message;
        state.creditChecks = [];
        state.totalCount = 0;
      })
      .addCase(updateCreditCheckStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCreditCheckStatus.fulfilled, (state, action) => {
        const { _id: companyId } = action.payload;
        state.creditChecks = state.creditChecks.filter(
          (creditCheck) => creditCheck.companyId !== companyId
        );
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCreditCheckStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadCreditCheckDoc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadCreditCheckDoc.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.creditChecks.findIndex((item) => item.companyId === action.payload._id);
        state.creditChecks[index].creditCheckReport = action.payload.creditCheckFile;
      })
      .addCase(uploadCreditCheckDoc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.creditCheckFile = null;
      })
      .addCase(deleteB2BCreditCheckFile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteB2BCreditCheckFile.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.creditChecks.findIndex((item) => item.companyId === action.payload._id);
        delete state.creditChecks[index].creditCheckReport;
      })
      .addCase(deleteB2BCreditCheckFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setCreditChecksBySearchToken } = creditCheckBtoBSlice.actions;
export default creditCheckBtoBSlice.reducer;
