import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchCompanies = createAsyncThunk(
  'Companies/fetchCompanies',
  async (
    { limit, offset, location, industry, joinedDate, fteCount, integration },
    { rejectWithValue }
  ) => {
    try {
      let url = `/data/b2b/companies?limit=${limit}&offset=${offset + 1}`;
      if (industry) {
        url += `&industry=${industry}`;
      }
      if (location) {
        url += `&location=${location}`;
      }
      if (joinedDate) {
        url += `&joinedDate=${joinedDate}`;
      }
      if (fteCount) {
        url += `&fte=${fteCount}`;
      }
      if (integration) {
        url += `&integration=${integration}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const companiesSlice = createSlice({
  name: 'Companies',
  initialState: {
    companies: [],
    unfiltredCompanies: null,
    isLoading: false,
    error: false,
    success: false,
    errorMessage: '',
    totalCount: 0,
  },
  reducers: {
    setCompaniesBySearchToken: (state, action) => {
      if (!state.unfiltredCompanies) {
        state.unfiltredCompanies = [...state.companies];
      }
      const { token } = action.payload;
      state.companies = state.unfiltredCompanies.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload.companyList;
        state.isLoading = false;
        state.errorMessage = '';
        state.success = true;
        state.error = false;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.errorMessage = action.payload.message;
        state.companies = [];
        state.totalCount = 0;
      });
  },
});
export const { setCompaniesBySearchToken } = companiesSlice.actions;
export default companiesSlice.reducer;
