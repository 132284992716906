import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import React from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';
import './FeedbackDialog.scss';

const FeedbackDialog = (props) => {
  const { open, setOpen, value, setValue, title, subTitle, handleApply, options, ...other } = props;
  const radioGroupRef = React.useRef(null);

  const handleChange = (checkBoxValue) => {
    if (value.includes(checkBoxValue)) {
      setValue((prevState) => prevState.filter((item) => item !== checkBoxValue));

      return;
    }

    setValue((prevState) => [...prevState, checkBoxValue]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title={title}
      actionButtons={[{ label: 'Submit', variant: 'contained', onClick: handleApply }]}
      ref={radioGroupRef}
      subTitle={subTitle}
      className="feedback-dialog"
      {...other}
    >
      {options.map((option) => {
        return (
          <>
            <Typography sx={{ marginY: 1 }} variant="h5">
              {option.title}
            </Typography>
            <FormGroup>
              {option.options.map((suboption) => (
                <FormControlLabel
                  checked={value.includes(suboption.value)}
                  control={<Checkbox defaultChecked />}
                  label={suboption.label}
                  onChange={() => handleChange(suboption.value)}
                />
              ))}
            </FormGroup>
          </>
        );
      })}
    </BaseDialog>
  );
};

export default FeedbackDialog;
