import { Button, CircularProgress, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import './CustomDialogAction.scss';

const CustomDialogAction = ({ buttons }) => {
  return (
    <DialogActions className="custom-dialog-action">
      {buttons.map((button) => (
        <Button
          variant={button.variant || 'text'}
          onClick={button.onClick}
          disabled={button.disabled || false}
          className={button.className || ''}
          color={button.color || 'primary'}
        >
          {button.isLoading ? <CircularProgress size="1.8rem" color="grey" /> : button.label}
        </Button>
      ))}
    </DialogActions>
  );
};

CustomDialogAction.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
      disabled: PropTypes.bool,
      onclick: PropTypes.func.isRequired,
      className: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
};

export default CustomDialogAction;
