import { InputLabel, TextField } from '@mui/material';
import React from 'react';
import './TextInputWithLabel.scss';

const TextInputWithLabel = ({ label, value, setValue, placeholder, type, isRequired }) => {
  return (
    <div className="text-input-with-label-wrapper">
      <InputLabel className="input-label">
        {label}
        {isRequired ? <span>*</span> : <span> (Optional)</span>}
      </InputLabel>
      {(() => {
        if (type === 'textarea') {
          return (
            <TextField
              name={label.toLowerCase()}
              fullWidth
              multiline
              minRows={3}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder}
              className="textarea"
            />
          );
        }
        return (
          <TextField
            name={label.toLowerCase()}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="text-field"
          />
        );
      })()}
    </div>
  );
};

export default TextInputWithLabel;
