import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchB2BClaims,
  getB2BClaimsAgreementSignUrl,
  setb2bClaimsBySearchToken,
} from '../../app/claimsb2b/calimsb2bSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { convertTimestampToDate } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import { amountReuquestedSelect, companySelect } from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AmountSliderDialog from '../Dialog/AmountSliderDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './Claims.scss';

const Claims = () => {
  const dispatch = useDispatch();
  const { isLoading, b2bClaims, totalCount } = useSelector((state) => state.b2bClaims);
  const filterPending = [
    { name: 'Date Requested' },
    // { name: 'Company' },
    { name: 'Amount Requested' },
  ];
  const filterApproved = [
    { name: 'Date Requested' },
    // { name: 'Company' },
    { name: 'Amount Requested' },
    { name: 'Date Approved' },
  ];
  const filterRejected = [
    { name: 'Date Requested' },
    // { name: 'Company' },
    { name: 'Amount Requested' },
    { name: 'Date Rejected' },
  ];
  const pendingHeadings = [
    'Claim Id',
    'Requested By',
    'Company',
    'Date Requested',
    'Amount Requested',
  ];
  const approveHeadings = [
    'Claim Id',
    'Requested By',
    'Company',
    'Date Requested',
    'Amount Requested',
    'Date Approved',
  ];
  const rejectHeadings = [
    'Claim Id',
    'Requested By',
    'Company',
    'Date Requested',
    'Amount Requested',
    'Date Rejected',
  ];
  const tabsData = ['Pending', 'Approved / Paid', 'Rejected'];
  const [applyClicked, setApplyClicked] = useState(false);
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [amountSelectModalOpen, setAmountSelectModalOpen] = useState(false);
  const [companySelectModalOpen, setCompanySelectModalOpen] = useState(false);
  const [dateSelectModalOpen, setDateSelectModalOpen] = useState(false);
  const [approveDateSelectModalOpen, setApproveDateSelectModalOpen] = useState(false);
  const [rejectDateSelectModalOpen, setRejectDateSelectModalOpen] = useState(false);
  const [selectCompanyValues, setSelectCompanyValues] = useState([]);
  const [requestAmount, setRequestAmount] = useState([]);
  const [options, setOptions] = useState([]);
  const [timeframe, setTimeframe] = useState('before');
  const [rejectTimeframe, setRejectTimeframe] = useState('before');
  const [approveTimeframe, setApproveTimeframe] = useState('before');
  const [requestDate, setRequestDate] = useState(dayjs(Date.now()));
  const [rejectedDate, setRejectedDate] = useState(dayjs(Date.now()));
  const [approvedDate, setApprovedDate] = useState(dayjs(Date.now()));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchToken, setSearchToken] = useState();
  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'company') {
      setTitle('Company');
      setOptions(companySelect);
      setCompanySelectModalOpen(!companySelectModalOpen);
    }
    if (type === 'amount-requested') {
      setTitle('Amount Requested');
      setOptions(amountReuquestedSelect);
      setAmountSelectModalOpen(!amountSelectModalOpen);
    }
    if (type === 'date-requested') {
      setTitle('Date requested');
      setDateSelectModalOpen(!dateSelectModalOpen);
    }
    if (type === 'date-rejected') {
      setTitle('Date rejected');
      setRejectDateSelectModalOpen(!rejectDateSelectModalOpen);
    }
    if (type === 'date-approved') {
      setTitle('Date approved');
      setApproveDateSelectModalOpen(!approveDateSelectModalOpen);
    }
  };
  const handleCompanySelectApply = () => {
    setApplyClicked(true);
    setCompanySelectModalOpen(false);
  };
  const handleRequestedDateSelectApply = () => {
    setApplyClicked(true);
    setDateSelectModalOpen(false);
  };
  const handleRejectedDateSelectApply = () => {
    setApplyClicked(true);
    setRejectDateSelectModalOpen(false);
  };
  const handleApprovedDateSelectApply = () => {
    setApplyClicked(true);
    setApproveDateSelectModalOpen(false);
  };
  const handleAmountSelectApply = () => {
    setApplyClicked(true);
    setAmountSelectModalOpen(false);
  };
  const [status, setStatus] = useState(tabsData[0]);
  const handleChangeStatus = (newStatus) => {
    setStatus(newStatus === 1 ? 'Approved' : tabsData[newStatus]);
  };
  const isPending = status.toUpperCase() === 'PENDING';
  const isApproved = status.toUpperCase() === 'APPROVED';

  // eslint-disable-next-line no-nested-ternary
  const filterOptions = isPending ? filterPending : isApproved ? filterApproved : filterRejected;
  // eslint-disable-next-line no-nested-ternary
  const headingsToRender = isPending
    ? pendingHeadings
    : isApproved
    ? approveHeadings
    : rejectHeadings;

  useEffect(() => {
    dispatch(
      fetchB2BClaims({
        status: status.toUpperCase(),
        limit: rowsPerPage,
        offset: page,
        requestAmount: requestAmount.length ? requestAmount.join('-') : 0,
      })
    );
  }, [dispatch, status, rowsPerPage, page]);
  useEffect(() => {
    if (applyClicked) {
      const filterDateRequested = timeframe + requestDate.toISOString().substring(0, 10);
      dispatch(
        fetchB2BClaims({
          status: status.toUpperCase(),
          limit: rowsPerPage,
          offset: page,
          company: selectCompanyValues.join('$'),
          dateRequested: filterDateRequested,
          requestAmount: requestAmount.length ? requestAmount.join('-') : 0,
        })
      );
      setApplyClicked(false);
    }
  }, [applyClicked]);
  useDidUpdateEffect(() => {
    dispatch(setb2bClaimsBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const handleViewAgreement = async (e, row) => {
    const agreementInfo = {
      claimId: row.claimId,
      employerName: row.employerName || '',
      employeeName: row.requestedBy || '',
      claimHours: row.claimHours ? row.claimHours.toFixed(2) : 0,
      claimAmount: row.amountRequested ? row.amountRequested.toFixed(2) : 0,
      claimApprovedDate: row.dateApproved ? convertTimestampToDate(row.dateApproved) : '',
      claimRequestedDate: row.dateRequested ? convertTimestampToDate(row.dateRequested) : '',
    };
    const resp = await dispatch(getB2BClaimsAgreementSignUrl({ agreementInfo })).unwrap();
    window.open(resp?.URL, '_blank');
  };

  const renderTableRows = (data) => {
    return data.map((row) => (
      <TableRow key={row.claimId}>
        <TableCell>{row.claimId}</TableCell>
        <TableCell>{row.requestedBy}</TableCell>
        <TableCell>{row.companyName}</TableCell>
        <TableCell>{row.dateRequested && convertTimestampToDate(row.dateRequested)}</TableCell>
        <TableCell>
          ${row.amountRequested && formatNumberWithThousandSeparator(row.amountRequested)}
        </TableCell>
        {!isPending && !isApproved && (
          <TableCell>{row.dateRejected && convertTimestampToDate(row.dateRejected)}</TableCell>
        )}
        {isApproved && (
          <>
            <TableCell>{row.dateApproved && convertTimestampToDate(row.dateApproved)}</TableCell>
            <TableCell>
              <button
                type="button"
                className="view-agreement"
                onClick={(e) => handleViewAgreement(e, row)}
              >
                View agreement
              </button>
            </TableCell>
          </>
        )}
      </TableRow>
    ));
  };
  return (
    <div className="claims" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Claims</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterOptions} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!b2bClaims.length) {
            return (
              <Typography variant="h4" className="message-style">
                No Claims to show
              </Typography>
            );
          }
          return (
            <Table aria-label="simple table" className="table-style">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows(b2bClaims)}</TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>

      <AmountSliderDialog
        open={amountSelectModalOpen}
        setOpen={setAmountSelectModalOpen}
        values={requestAmount}
        setValues={setRequestAmount}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handleAmountSelectApply}
        title={title}
      />
      <CheckboxDialog
        open={companySelectModalOpen}
        setOpen={setCompanySelectModalOpen}
        values={selectCompanyValues}
        setValues={setSelectCompanyValues}
        options={options}
        position={position}
        handleApply={handleCompanySelectApply}
        title={title}
      />
      <DateDialog
        open={dateSelectModalOpen}
        setOpen={setDateSelectModalOpen}
        date={requestDate}
        setDate={setRequestDate}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        handleApply={handleRequestedDateSelectApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={rejectDateSelectModalOpen}
        setOpen={setRejectDateSelectModalOpen}
        date={rejectedDate}
        setDate={setRejectedDate}
        timeframe={rejectTimeframe}
        setTimeframe={setRejectTimeframe}
        handleApply={handleRejectedDateSelectApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={approveDateSelectModalOpen}
        setOpen={setApproveDateSelectModalOpen}
        date={approvedDate}
        setDate={setApprovedDate}
        timeframe={approveTimeframe}
        setTimeframe={setApproveTimeframe}
        handleApply={handleApprovedDateSelectApply}
        position={position}
        title={title}
      />
    </div>
  );
};

export default Claims;
