import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePut } from '../../utilities/apiCaller';

export const fetchEmployeeClaims = createAsyncThunk(
  'employeeClaims/fetchEmployeeClaims',
  async ({ employeeId, claimStatus, limit, offset }, { rejectWithValue }) => {
    try {
      const url = `/data/b2b/company-employees/employee-claims?limit=${limit}&offset=${offset}&employeeId=${employeeId}&claimStatus=${claimStatus}`;
      const response = await privateGet(url);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const putEmployeeClaims = createAsyncThunk(
  'employeeClaims/putEmployeeClaims',
  async (updateData, { rejectWithValue }) => {
    try {
      const url = '/data/b2b/company-employees/employee-claims';
      const response = await privatePut(url, updateData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const employeeClaimsSlice = createSlice({
  name: 'employeeClaims',
  initialState: {
    employeeClaims: [],
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeClaims.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEmployeeClaims.fulfilled, (state, action) => {
        state.employeeClaims = action.payload.claimData;
        state.totalCount = action.payload.totalCount;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEmployeeClaims.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.employeeClaims = [];
        state.totalCount = 0;
      })
      .addCase(putEmployeeClaims.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(putEmployeeClaims.fulfilled, (state, { payload }) => {
        state.employeeClaims = state.employeeClaims.filter((claims) => claims._id !== payload._id);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(putEmployeeClaims.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default employeeClaimsSlice.reducer;
