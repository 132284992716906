/* eslint-disable no-unused-vars */
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyList } from '../../../../app/companyDetails/companyInfoSlice';
import { fetchUsersByCompanyId } from '../../../../app/userRepository/userInfoSlice';
import CheckboxAutocompleteWithLabel from '../../../Input/CheckboxAutocompleteWithLabel/CheckboxAutocompleteWithLabel';
import SelectAutocompleteWithLabel from '../../../Input/SelectAutocompleteWithLabel/SelectAutocompleteWithLabel';
import './SelectCompanyUser.scss';

const CompanyUserPair = ({ setSelectedCompanyUsersPair, companies, isLoading, pair }) => {
  const [company, setCompany] = useState({ label: '', value: '' });
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const { usersByCompanyId } = useSelector((state) => state.repositoryUserInfo);

  useEffect(() => {
    if (pair) {
      const key = Object.keys(pair)[0];
      setCompany({ label: pair[key]?.name, value: key });
      setUsers(pair[key]?.users?.map((usr) => ({ label: usr.name, value: usr._id })));
    }
  }, [pair]);

  useEffect(() => {
    if (company) {
      setSelectedCompanyUsersPair((prev) => ({
        ...prev,
        [company.value]: users,
      }));
    }
  }, [company, users]);

  useEffect(() => {
    if (company?.value) {
      dispatch(fetchUsersByCompanyId(company.value));
    }
  }, [company]);

  return (
    <div className="company-user-pair">
      <SelectAutocompleteWithLabel
        label="Company"
        isLoading={isLoading}
        value={company}
        setValue={setCompany}
        options={companies}
      />
      <CheckboxAutocompleteWithLabel
        label="User"
        value={users}
        setValue={setUsers}
        options={usersByCompanyId
          .filter((usr) => usr._id)
          .map((user) => ({ label: user.name, value: user._id }))}
        disabled={!company?.value}
      />
    </div>
  );
};

const SelectCompanyUser = ({ setSelectedCompanyUsersPair }) => {
  const { companyUsersPair } = useSelector((state) => state.notification);
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const { companyList, isLoading } = useSelector((state) => state.companyInfoData);
  useEffect(() => {
    dispatch(fetchCompanyList());
  }, []);

  useEffect(() => {
    if (companyUsersPair.length > 1) {
      setCounter(companyUsersPair.length);
    }
  }, [companyUsersPair]);

  return (
    <div className="select-company-user">
      {[...Array(counter)].map((_, i) => (
        <CompanyUserPair
          pair={companyUsersPair[i]}
          companies={companyList.map((cmp) => ({ label: cmp.name, value: cmp._id }))}
          isLoading={isLoading}
          setSelectedCompanyUsersPair={setSelectedCompanyUsersPair}
        />
      ))}
      <Button
        sx={{ mt: '1.5rem' }}
        color="secondary"
        onClick={() => setCounter((prev) => prev + 1)}
      >
        + Add company
      </Button>
    </div>
  );
};

export default SelectCompanyUser;
