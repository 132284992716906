/* eslint-disable no-await-in-loop */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGetV2 } from '../../utilities/apiCaller';

export const fetchB2BCompanyInfo = createAsyncThunk(
  'b2bCompanyInfo/fetchB2BCompanyInfo',
  async (accessToken, { rejectWithValue }) => {
    try {
      const response = await privateGetV2(`/data/b2b/dashboard/company-info`, accessToken);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2bCompanyInfoSlice = createSlice({
  name: 'b2bCompanyInfo',
  initialState: {
    companyInfo: {},
    isLoading: false,
    error: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2BCompanyInfo.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchB2BCompanyInfo.fulfilled, (state, action) => {
        state.companyInfo = action.payload.data;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchB2BCompanyInfo.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
        state.companyInfo = {};
      });
  },
});

export default b2bCompanyInfoSlice.reducer;
