import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { fetchCompanyInfo } from '../../app/companyDetails/companyInfoSlice';
import { convertTimestampToDate } from '../../utils/dataFormat';
import { formatNumberWithThousandSeparator } from '../../utils/helperMethod';
import AdminInfoSkeleton from './AdminInfoSkeleton';
import './CompanyDetails.scss';
import CompanyInfoSkeleton from './CompanyInfoSkeleton';

const CompanyDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { isLoading, companyDetailsData } = useSelector((state) => state.companyInfoData);
  useEffect(() => {
    dispatch(fetchCompanyInfo(params.companyId));
  }, [dispatch]);
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  return (
    <div className="root-div" style={{ flex: 1, gap: '16px' }}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb" className="company-bredcumb">
          <Link underline="hover" color="inherit" to="/b2b-companies">
            <span className="icon-wrapper">
              <ArrowBackIosIcon className="arrow-icon" />
            </span>
            Companies
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            href="/material-ui/react-breadcrumbs/"
            aria-current="page"
          >
            <span className="span-text">{companyDetailsData?.companyInfo?.name}</span>
          </Link>
        </Breadcrumbs>
      </div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <h4 className="company-name">{companyDetailsData?.companyInfo?.name}</h4>
            </Grid>
            <Grid item>
              <h6>
                Date joined:
                {companyDetailsData?.companyInfo?.dateJoined &&
                  convertTimestampToDate(companyDetailsData?.companyInfo?.dateJoined)}
              </h6>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={3}
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 12px' }}
        >
          <div style={{ fontWeight: '500', display: 'flex', alignItems: 'center' }}>
            <GroupsIcon style={{ marginRight: '5px' }} />
            <Link to={`/company/employees/${params.companyId}`}>View employees</Link>
          </div>
        </Grid>
      </Grid>
      {isLoading ? (
        <div className="box-container">
          {[...Array(6)].map(() => (
            <div className="box">
              <Skeleton variant="text" width={100} height={22} />
              <Skeleton variant="text" width={60} height={22} />
            </div>
          ))}
        </div>
      ) : (
        <div className="box-container">
          <div className="box">
            <h5>{companyDetailsData?.approvedClaimCount}</h5>
            <span className="box-span">Claims approved</span>
          </div>
          <div className="box">
            <h5>{companyDetailsData?.avgApprovalTime ?? '-'}</h5>
            <span className="box-span">Avg. approval time</span>
          </div>
          <div className="box">
            <h5>
              $
              {companyDetailsData?.revenue &&
                formatNumberWithThousandSeparator(companyDetailsData?.revenue.toFixed(2))}
            </h5>
            <span className="box-span">Revenue</span>
          </div>
          <div className="box">
            <h5>
              $
              {companyDetailsData?.outstanding &&
                formatNumberWithThousandSeparator(companyDetailsData?.outstanding.toFixed(2))}
            </h5>
            <span className="box-span">Outstanding</span>
          </div>
          <div className="box">
            <h5>
              $
              {companyDetailsData?.maxLiabilities &&
                formatNumberWithThousandSeparator(companyDetailsData?.maxLiabilities.toFixed(2))}
            </h5>
            <span className="box-span">Maximum liability</span>
          </div>
          <div className="box">
            <h5>${companyDetailsData?.agedReceivable}</h5>
            <span className="box-span">Aged receivables</span>
          </div>
        </div>
      )}
      {isLoading ? (
        <CompanyInfoSkeleton />
      ) : (
        <div className="company-info">
          <h2>COMPANY INFORMATION</h2>

          <div className="child-row">
            <div className="box-content">
              <h3>Name</h3>
              <h5>{companyDetailsData?.companyInfo?.name}</h5>
            </div>
            <div className="box-content">
              <h3>ABN</h3>
              <h5>{companyDetailsData?.companyInfo?.abn}</h5>
            </div>
            <div className="box-content">
              <h3>Credit check status</h3>
              <h5>{companyDetailsData?.companyInfo?.creditCheck ? 'Valid' : 'Invalid'}</h5>
            </div>
            <div className="box-content">
              <h3>FTE count</h3>
              <h5>{companyDetailsData?.companyInfo?.fte}</h5>
            </div>
          </div>
          <div className="child-row">
            <div className="box-content">
              <h3>Industry</h3>
              <h5>{companyDetailsData?.companyInfo?.industry}</h5>
            </div>
            <div className="box-content">
              <h3>Location</h3>
              <h5>{companyDetailsData?.companyInfo?.abnBusinessLocationState}</h5>
            </div>
            <div className="box-content">
              <h3>Last credit check</h3>
              <h5>30/10/2020</h5>
            </div>
            <div className="box-content">
              <h3>Integration</h3>
              <h5>{companyDetailsData?.companyInfo?.integration}</h5>
            </div>
          </div>
        </div>
      )}
      <div className="parent-container">
        {isLoading ? (
          <AdminInfoSkeleton />
        ) : (
          <div className="company-info">
            <Box sx={{ textTransform: 'uppercase' }}>
              <h2>Admin</h2>
            </Box>
            <div className="child-row">
              <div className="box-content">
                <h3>First name</h3>
                <h5>{companyDetailsData?.admin?.name}</h5>
              </div>
              <div className="box-content">
                <h3>Last name</h3>
                <h5>{companyDetailsData?.admin?.lastName}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Email</h3>
                <h5>{companyDetailsData?.admin?.email}</h5>
              </div>
              <div className="box-content">
                <h3>Position</h3>
                <h5>{companyDetailsData?.admin?.jobPosition}</h5>
              </div>
            </div>
          </div>
        )}
        {isLoading ? (
          <AdminInfoSkeleton />
        ) : (
          <div className="company-info">
            <Box sx={{ textTransform: 'uppercase' }}>
              <h2>Authorised signatory</h2>
            </Box>

            <div className="child-row">
              <div className="box-content">
                <h3>First name</h3>
                <h5>{companyDetailsData?.authorizedSignatory?.name}</h5>
              </div>
              <div className="box-content">
                <h3>Last name</h3>
                <h5>{companyDetailsData?.authorizedSignatory?.lastName}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Email</h3>
                <h5>{companyDetailsData?.authorizedSignatory?.email}</h5>
              </div>
              <div className="box-content">
                <h3>Position</h3>
                <h5>{companyDetailsData?.authorizedSignatory?.jobPosition}</h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
