import { Button, Modal, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import './ThreeDotModal.scss';

import React from 'react';

const ThreeDotModal = (props) => {
  const {
    open,
    setOpen,
    position,
    handleCancelScheduleApply,
    handleDeleteNotificationApply,
    ...other
  } = props;

  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="notification-three-dot-modal"
      style={{ position: 'absolute', top: position.top, left: position.left }}
      {...other}
    >
      <Paper className="three-dot-paper" elevation={12} sx={{ minWidth: '40px' }}>
        <Button className="cancel-schedule-btn" onClick={handleCancelScheduleApply}>
          Cancel schedule and save to draft
        </Button>
        <Button
          color="red"
          className="delete-notification-btn"
          onClick={handleDeleteNotificationApply}
        >
          Delete notification
        </Button>
      </Paper>
    </Modal>
  );
};

ThreeDotModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleCancelScheduleApply: PropTypes.func.isRequired,
  handleDeleteNotificationApply: PropTypes.func.isRequired,
  position: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
};

export default ThreeDotModal;
