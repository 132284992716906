import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';
import './ReviewNotificationDialog.scss';

const ReviewNotificationDialog = (props) => {
  const {
    open,
    setOpen,
    isPending,
    setIsPending,
    totalUsers,
    totalCompanies,
    handleApply,
    sendNotificationText,
    ...other
  } = props;
  const radioGroupRef = React.useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title="Review your notification"
      titleMiddle
      actionButtons={[
        {
          label: 'Send now',
          variant: 'contained',
          onClick: handleApply,
          isLoading: isPending,
          disabled: !!isPending,
        },
        { label: 'Cancel', onClick: handleClose },
      ]}
      ref={radioGroupRef}
      subTitle="Send now"
      subTitleMiddle
      className="review-notification-dialog"
      {...other}
    >
      <Typography>You&apos;re about to send to:</Typography>
      <Typography>
        <span className="highlighted-text">
          {sendNotificationText || 'All users from all companies'}
        </span>
      </Typography>
    </BaseDialog>
  );
};

ReviewNotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  setIsPending: PropTypes.func.isRequired,
  totalUsers: PropTypes.number.isRequired,
  totalCompanies: PropTypes.string.isRequired,
  handleApply: PropTypes.func.isRequired,
};

export default ReviewNotificationDialog;
