import { useState } from 'react';

const useNotificationAlert = () => {
  const [openSnack, setSnackOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  return { openSnack, setSnackOpen, handleSnackClose, toastMessage, setToastMessage };
};

export default useNotificationAlert;
