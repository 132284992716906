import dayjs from 'dayjs';

export const convertTimestampToDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const convertTimestampToDateAndTime = (isoDate) => {
  return dayjs(isoDate).format('DD/MM/YYYY hh:mmA');
};

export const formatDateTimeStamp = (dateString) => {
  const dateObject = new Date(dateString);

  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const hour = dateObject.getHours().toString().padStart(2, '0');
  const minute = dateObject.getMinutes().toString().padStart(2, '0');
  const period = hour < 12 ? 'AM' : 'PM';

  const formattedDateTime = `${month}/${day}/${year} ${hour % 12}:${minute} ${period}`;

  return formattedDateTime;
};

export const syncDateFormat = (dateString) => {
  const originalDate = new Date(dateString);

  const hours = originalDate.getHours();
  const minutes = originalDate.getMinutes();

  const day = originalDate.getDate();
  const month = originalDate.getMonth() + 1;
  const year = originalDate.getFullYear();

  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${formattedHours}:${formattedMinutes} ${ampm} ${formattedDay}/${formattedMonth}/${year}`;
};

export const extractDateTime = (dateString, timeString) => {
  const datePart = dateString.substring(0, 10);
  const timePart = timeString.substring(11, 19);
  const dateTimeString = `${datePart}T${timePart}Z`;
  const formattedDateTime = dayjs(dateTimeString);
  return formattedDateTime;
};
