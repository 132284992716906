export const b2bMenu = [
  {
    title: 'Dashboard',
    link: '/b2b-dashboard',
    icon: 'home',
  },
  {
    title: 'Credit check',
    link: '/b2b-credit-check',
    icon: 'account_balance',
  },
  {
    title: 'Companies',
    link: '/b2b-companies',
    icon: 'business',
  },
  {
    title: 'Claims',
    link: '/b2b-claims',
    icon: 'support',
  },
  {
    title: 'Employee payments',
    link: '/b2b-employee-payments',
    icon: 'payments',
  },
  {
    title: 'Business repayments',
    link: '/b2b-business-repayments',
    icon: 'redeem',
  },
];

export const b2cMenu = [
  {
    title: 'Dashboard',
    link: '/b2c-dashboard',
    icon: 'home',
  },
  {
    title: 'Applications',
    link: '/b2c-payslips',
    icon: 'insert_drive_file',
  },
  // {
  //   title: 'Claims',
  //   link: '/b2c-claims',
  //   icon: 'support',
  // },
  {
    title: 'Employee payments',
    link: '/b2c-employee-payments',
    icon: 'payments',
  },
  {
    title: 'Employee repayments',
    link: '/b2c-business-repayments',
    icon: 'redeem',
  },
  {
    title: 'User repository',
    link: '/b2c-user-repository',
    icon: 'group',
  },
];

export const sidebarBottomMenu = [
  {
    title: 'Analytics',
    link: '/b2b-analytics',
    icon: 'leaderboard',
  },
  // {
  //   title: 'Settings',
  //   link: '/settings',
  //   icon: 'settings',
  // },

  {
    title: 'Logout',
    icon: 'logout',
  },
];

export const sidebarTopMenu = [
  {
    title: 'Notification centre',
    link: '/notification-centre',
    icon: 'notifications_active',
  },
];
