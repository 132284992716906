import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchUserRepositories = createAsyncThunk(
  'userRepositories/fetchUserRepositories',
  async ({ limit, offset, claimableAmount, company }, { rejectWithValue }) => {
    try {
      let url = `/data/b2c/user-repository?limit=${limit}&offset=${offset + 1}`;
      if (company) {
        url += `&companyName=${company}`;
      }
      if (claimableAmount) {
        url += `&claimableAmount=${claimableAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const userRepositoriesSlice = createSlice({
  name: 'userRepositories',
  initialState: {
    repositories: [],
    unfiltredUserRepo: null,
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    setUserRepositoryBySearchToken: (state, action) => {
      if (!state.unfiltredUserRepo) {
        state.unfiltredUserRepo = [...state.repositories];
      }
      const { token } = action.payload;
      state.repositories = state.unfiltredUserRepo.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRepositories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserRepositories.fulfilled, (state, action) => {
        state.repositories = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchUserRepositories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.repositories = [];
        state.totalCount = 0;
      });
  },
});

export const { setUserRepositoryBySearchToken } = userRepositoriesSlice.actions;
export default userRepositoriesSlice.reducer;
