import { createAsyncThunk } from '@reduxjs/toolkit';
import { privatePost } from '../../utilities/apiCaller';

export const sendRemindEmail = createAsyncThunk(
  'email/sendRemindEmail',
  async (emailData, { rejectWithValue }) => {
    try {
      const response = await privatePost('/data/email/remind-payment', emailData);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendRequestMail = createAsyncThunk(
  'email/sendRequestMail',
  async (emailData, { rejectWithValue }) => {
    try {
      const response = await privatePost('/data/email/contact-info', emailData);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const sendCompanySignupEmail = createAsyncThunk(
  'email/sendRequestMail',
  async (emailData, { rejectWithValue }) => {
    try {
      const response = await privatePost('/data/email/signup-link', emailData);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
