import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';

const FeedbackDialogue = ({
  open,
  setOpen,
  value,
  setValue,
  title,
  subTitle,
  handleApply,
  options,
  ...other
}) => {
  const radioGroupRef = React.useRef(null);

  const handleChange = (checked, contextId, _value) => {
    setValue((prevState) => {
      // Ensure the current context key exists in the state, default to an empty array if not
      const currentArray = prevState[contextId] || [];

      let newArray;
      if (checked) {
        // Add item if checked
        newArray = [...currentArray, _value];
      } else {
        // Remove item if not checked
        newArray = currentArray.filter((item) => item !== _value);
      }

      // Check if the resulting array is empty and conditionally return the new state
      if (newArray.length === 0) {
        // Use destructuring to omit the empty key
        const { [contextId]: _, ...newState } = prevState;
        return newState;
      }
      // Otherwise, update the state as usual
      return {
        ...prevState,
        [contextId]: newArray,
      };
    });
  };

  const handleInputChange = (contextId, event) => {
    const _input = event.target.value;

    if (!_input) {
      setValue((prev) => {
        const _prev = { ...prev };
        delete prev[contextId];
        return _prev;
      });
      return;
    }

    setValue((prev) => ({ ...prev, [contextId]: [_input] }));
  };

  const handleRadioChange = (event) => {
    const [contextId, _value] = event.target.value.split('::');

    setValue((prev) => {
      if (prev.OTHER) return { [contextId]: [_value], OTHER: prev.OTHER };
      return { [contextId]: [_value] };
    });
  };

  const handleClose = () => {
    setValue({}); // Reset the selections
    setOpen(false);
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title={title}
      actionButtons={[{ label: 'Submit', variant: 'contained', onClick: handleApply }]}
      ref={radioGroupRef}
      subTitle={subTitle}
      className="feedback-dialog"
      {...other}
    >
      <RadioGroup onChange={handleRadioChange}>
        {options.map((option) => {
          if (option.type === 'input') {
            return (
              <div key={option.id}>
                <Typography sx={{ marginY: 1 }} variant="h5">
                  {option.title}
                </Typography>
                <FormGroup>
                  <Input multiline onChange={(event) => handleInputChange(option.id, event)} />
                </FormGroup>
              </div>
            );
          }

          if (option.type === 'radio') {
            return (
              <React.Fragment key={option.id}>
                <Typography sx={{ marginY: 1 }} variant="h5">
                  {option.title}
                </Typography>
                <FormGroup>
                  {option.options.map((suboption) => (
                    <FormControlLabel
                      key={suboption.value}
                      value={`${option.id}::${suboption.value}`}
                      control={<Radio />}
                      label={suboption.label}
                    />
                  ))}
                </FormGroup>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={option.id}>
              <Typography sx={{ marginY: 1 }} variant="h5">
                {option.title}
              </Typography>
              <FormGroup>
                {option.options.map((suboption) => (
                  <FormControlLabel
                    key={suboption.value}
                    checked={(value[option.id] ?? []).includes(suboption.value)}
                    control={<Checkbox defaultChecked />}
                    label={suboption.label}
                    onChange={(_, checked) => handleChange(checked, option.id, suboption.value)}
                  />
                ))}
              </FormGroup>
            </React.Fragment>
          );
        })}
      </RadioGroup>
    </BaseDialog>
  );
};

export default FeedbackDialogue;
