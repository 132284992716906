import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePost, privatePut } from '../../utilities/apiCaller';

export const fetchB2CClaims = createAsyncThunk(
  'b2cClaims/fetchB2CClaims',
  async (
    { status, limit, offset, company, uploadDate, submittedDate, contactedDate, requestAmount },
    { rejectWithValue }
  ) => {
    try {
      let url = `/data/b2c/claims?status=${status}&limit=${limit}&offset=${offset + 1}`;
      if (company) url += `&company=${company}`;
      if (uploadDate) url += `&uploadDate=${uploadDate}`;
      if (submittedDate) url += `&submittedDate=${submittedDate}`;
      if (contactedDate) url += `&contactedDate=${contactedDate}`;
      if (requestAmount) {
        url += `&amount=${requestAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateCreditCheckPendingStatus = createAsyncThunk(
  'b2cClaims/updateCreditCheckStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2c/claims/credit-check-status', data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateClaimsContactPending = createAsyncThunk(
  'b2cClaims/updateClaimsContactPending',
  async (employeeId, { rejectWithValue }) => {
    try {
      await privatePut(`/data/b2c/claims/contact-pending?employeeId=${employeeId}`);
      return employeeId;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadB2cCreditCheckDoc = createAsyncThunk(
  'b2cClaims/uploadCreditCheckDoc',
  async ({ employeeId, file, filename }, { rejectWithValue }) => {
    try {
      const response = await privatePost(
        `/data/b2c/credit-check/upload?employeeId=${employeeId}`,
        {
          file,
          filename,
        },
        { 'Content-Type': 'multipart/form-data' }
      );
      return response?.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const getB2cCreditCheckSignedUrl = createAsyncThunk(
  'b2cClaims/getB2BCreditCheckSignedUrl',
  async ({ fileName }, { rejectWithValue }) => {
    try {
      const res = await privateGet(`/data/b2c/credit-check/signed-url?fileName=${fileName}`);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const getB2cClaimsPendingSignedUrl = createAsyncThunk(
  'b2cClaims/getB2cClaimsPendingSignedUrl',
  async ({ fileName }, { rejectWithValue }) => {
    try {
      const res = await privatePost(`/data/b2c/payslips/signed-url`, { fileName });
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const deleteB2cCreditCheckFile = createAsyncThunk(
  'b2cClaims/deleteB2BCreditCheckFile',
  async ({ employeeId }, { rejectWithValue }) => {
    try {
      const res = await privatePut('/data/b2c/credit-check/delete', { employeeId });
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const createB2cCompany = createAsyncThunk(
  'b2cClaims/createCompanyUponCreditCheckApproval',
  async (data, { rejectWithValue }) => {
    try {
      const res = await privatePost('/data/b2c/claims/credit-check/company-employee', data);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const b2cClaimsSlice = createSlice({
  name: 'b2cClaims',
  initialState: {
    b2cClaims: [],
    isLoading: false,
    error: null,
    totalCount: 0,
    unfiltredB2cClaims: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2CClaims.pending, (state) => {
        state.b2cClaims = [];
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2CClaims.fulfilled, (state, action) => {
        state.b2cClaims = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
        state.unfiltredB2cClaims = null;
      })
      .addCase(fetchB2CClaims.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.b2cClaims = [];
        state.totalCount = 0;
        state.unfiltredB2cClaims = null;
      })
      .addCase(updateClaimsContactPending.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClaimsContactPending.fulfilled, (state, { payload }) => {
        state.b2cClaims = state.b2cClaims.filter((b2cClaim) => b2cClaim.employeeId !== payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateClaimsContactPending.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateCreditCheckPendingStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCreditCheckPendingStatus.fulfilled, (state, { payload }) => {
        state.b2cClaims = state.b2cClaims.filter((b2cClaim) => b2cClaim._id !== payload._id);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCreditCheckPendingStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadB2cCreditCheckDoc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadB2cCreditCheckDoc.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.b2cClaims.findIndex((item) => item._id === action.payload._id);
        state.b2cClaims[index].creditCheckReport = action.payload.employer.creditCheckFile;
      })
      .addCase(uploadB2cCreditCheckDoc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteB2cCreditCheckFile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteB2cCreditCheckFile.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.b2cClaims.findIndex((item) => item._id === action.payload._id);
        delete state.b2cClaims[index].creditCheckReport;
      })
      .addCase(deleteB2cCreditCheckFile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
  reducers: {
    setb2cClaimsBySearchToken: (state, action) => {
      if (!state.unfiltredB2cClaims) {
        state.unfiltredB2cClaims = [...state.b2cClaims];
      }
      const { token } = action.payload;
      state.b2cClaims = state.unfiltredB2cClaims.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
});

export const { setb2cClaimsBySearchToken } = b2cClaimsSlice.actions;

export default b2cClaimsSlice.reducer;
