import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchB2BBusinessRepayments,
  setRepaymentsDataBySearchToken,
} from '../../app/b2bBusinessRepayments/b2bBusinessRepaymentsSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { convertTimestampToDate } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import {
  calculatePaidItemTotal,
  centsToDollars,
  colorBasedOnDate,
  findDueDateOfFirstUnpaid,
  formatNumberWithThousandSeparator,
} from '../../utils/helperMethod';
import {
  amountReuquestedSelect,
  companySelect,
  paymentMethodSelect,
} from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AmountSliderDialog from '../Dialog/AmountSliderDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import InvoiceDialog from '../Dialog/InvoiceDialog/InvoiceDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './BusinessRepayments.scss';

const BusinessRepayments = () => {
  const dispatch = useDispatch();
  const { isLoading, repaymentsData, totalCount } = useSelector(
    (state) => state.b2bBusinessRepayments
  );
  const filterPending = [
    { name: 'Date Approved' },
    // { name: 'Company' },
    { name: 'Total Amount' },
  ];
  const filterPaid = [
    { name: 'Date Approved' },
    // { name: 'Company' },
    { name: 'Total Amount' },
    { name: 'Payment Method' },
  ];

  const tabsData = ['Pending', 'Paid'];
  const [applyClicked, setApplyClicked] = useState(false);
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [companySelectModalOpen, setCompanySelectModalOpen] = useState(false);
  const [approvedDateSelectModalOpen, setApprovedDateSelectModalOpen] = useState(false);
  const [amountSelectModalOpen, setAmountSelectModalOpen] = useState(false);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [amountValues, setAmountValues] = useState([]);
  const [paymentValues, setPaymentMethodValues] = useState([]);
  const [companyValues, setCompanyValues] = useState([]);
  const [approvedDatetimeframe, setApprovedDateTimeframe] = useState('before');
  const [approvedDate, setApprovedDate] = useState(dayjs(Date.now()));
  const [options, setOptions] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);
    if (type === 'company') {
      setTitle('Company');
      setOptions(companySelect);
      setCompanySelectModalOpen(!companySelectModalOpen);
    }
    if (type === 'total-amount') {
      setTitle('Total amount');
      setOptions(amountReuquestedSelect);
      setAmountSelectModalOpen(!amountSelectModalOpen);
    }
    if (type === 'date-approved') {
      setTitle('Date approved');
      setApprovedDateSelectModalOpen(!approvedDateSelectModalOpen);
    }
    if (type === 'payment-method') {
      setTitle('Payment Method');
      setOptions(paymentMethodSelect);
      setPaymentMethodModalOpen(!paymentMethodModalOpen);
    }
  };
  const [searchToken, setSearchToken] = useState();
  const handleCompanySelectApply = () => {
    setCompanySelectModalOpen(false);
    setApplyClicked(true);
  };
  const handleAmountSelectApply = () => {
    setAmountSelectModalOpen(false);
    setApplyClicked(true);
  };

  const handleDateSelectApply = () => {
    setApprovedDateSelectModalOpen(false);
    setApplyClicked(true);
  };
  const handlePaymentMethodSelectApply = () => {
    setPaymentMethodModalOpen(false);
    setApplyClicked(true);
  };

  const [status, setStatus] = useState(tabsData[0]);
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  const pendingHeadings = [
    'Invoice No.',
    'Requested By',
    'Company',
    'Date Approved',
    'Next Due Date',
    'Paid',
    'Outstanding',
    'Total Amount',
  ];
  const paidHeadings = [
    'Invoice No.',
    'Requested By',
    'Company',
    'Date Approved',
    'Payment Method',
    'Total Amount',
  ];
  const isPending = status.toUpperCase() === 'PENDING';
  const filterOptions = isPending ? filterPending : filterPaid;
  const headingsToRender = isPending ? pendingHeadings : paidHeadings;

  const [modalOpen, setModalOpen] = useState(false);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    dispatch(
      fetchB2BBusinessRepayments({
        status: status.toUpperCase(),
        limit: rowsPerPage,
        offset: page,
        totalAmount: amountValues.length ? amountValues.join('-') : 0,
      })
    );
  }, [dispatch, status, rowsPerPage, page]);
  useEffect(() => {
    if (applyClicked) {
      if (isPending) {
        const filterApprovedDate =
          approvedDatetimeframe + approvedDate.toISOString().substring(0, 10);
        dispatch(
          fetchB2BBusinessRepayments({
            status: status.toUpperCase(),
            limit: rowsPerPage,
            offset: page,
            company: companyValues.join('$'),
            approvedDate: filterApprovedDate,
            totalAmount: amountValues.length ? amountValues.join('-') : 0,
          })
        );
      } else {
        const filterApprovedDate =
          approvedDatetimeframe + approvedDate.toISOString().substring(0, 10);
        dispatch(
          fetchB2BBusinessRepayments({
            status: status.toUpperCase(),
            limit: rowsPerPage,
            offset: page,
            company: companyValues.join('$'),
            approvedDate: filterApprovedDate,
            totalAmount: amountValues.length ? amountValues.join('-') : 0,
            paymentMethod: paymentValues.length ? paymentValues.join('-') : 0,
          })
        );
      }
      setApplyClicked(false);
    }
  }, [applyClicked]);
  const handleInvoiceClick = (e, row) => {
    setInvoice(row);
    setModalOpen(true);
  };
  useDidUpdateEffect(() => {
    dispatch(setRepaymentsDataBySearchToken({ token: searchToken }));
  }, [searchToken]);
  return (
    <div className="business-repayments" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Business repayments</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterOptions} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!repaymentsData.length) {
            return (
              <Typography variant="h4" className="message-style">
                No {status.toLowerCase()} business repayments to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isPending
                  ? repaymentsData.map((row) => (
                      <TableRow key={row.invoiceNumber}>
                        <TableCell>{row.invoiceNumber}</TableCell>
                        <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                        <TableCell>{row.company}</TableCell>
                        <TableCell>
                          {row.dateApproved && convertTimestampToDate(row.dateApproved)}
                        </TableCell>
                        <TableCell style={{ color: `${colorBasedOnDate(row.items)}` }}>
                          {findDueDateOfFirstUnpaid(row.items)}
                        </TableCell>
                        <TableCell className="paid-color">
                          ${formatNumberWithThousandSeparator(calculatePaidItemTotal(row.items))}
                        </TableCell>
                        <TableCell className="outstanding-color">
                          $
                          {formatNumberWithThousandSeparator(
                            centsToDollars(row.total) - calculatePaidItemTotal(row.items)
                          )}
                        </TableCell>
                        <TableCell>
                          ${formatNumberWithThousandSeparator(centsToDollars(row.total))}
                        </TableCell>
                        <TableCell className="invoice-text">
                          <span
                            role="button"
                            tabIndex={0}
                            onClick={(e) => handleInvoiceClick(e, row)}
                            className="view-details"
                          >
                            Invoice <NavigateNextIcon style={{ color: '#E6E6EE' }} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  : repaymentsData.map((row) => (
                      <TableRow key={row.invoiceNo}>
                        <TableCell key={row.invoiceNo}>{row.invoiceNumber}</TableCell>
                        <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                        <TableCell>{row.company}</TableCell>
                        <TableCell>
                          {row.dateApproved && convertTimestampToDate(row.dateApproved)}
                        </TableCell>
                        <TableCell>{row.paymentMethod}</TableCell>
                        <TableCell>
                          {formatNumberWithThousandSeparator(centsToDollars(row.total))}
                        </TableCell>
                        <TableCell className="invoice-text">
                          <span
                            role="button"
                            tabIndex={0}
                            onClick={(e) => handleInvoiceClick(e, row)}
                            className="view-details"
                          >
                            Invoice <NavigateNextIcon style={{ color: '#E6E6EE' }} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>

      <CheckboxDialog
        open={companySelectModalOpen}
        setOpen={setCompanySelectModalOpen}
        values={companyValues}
        setValues={setCompanyValues}
        options={options}
        position={position}
        handleApply={handleCompanySelectApply}
        title={title}
      />
      <CheckboxDialog
        open={paymentMethodModalOpen}
        setOpen={setPaymentMethodModalOpen}
        values={paymentValues}
        setValues={setPaymentMethodValues}
        options={options}
        position={position}
        handleApply={handlePaymentMethodSelectApply}
        title={title}
      />

      <DateDialog
        open={approvedDateSelectModalOpen}
        setOpen={setApprovedDateSelectModalOpen}
        date={approvedDate}
        setDate={setApprovedDate}
        timeframe={approvedDatetimeframe}
        setTimeframe={setApprovedDateTimeframe}
        handleApply={handleDateSelectApply}
        position={position}
        title={title}
      />
      <AmountSliderDialog
        open={amountSelectModalOpen}
        setOpen={setAmountSelectModalOpen}
        values={amountValues}
        setValues={setAmountValues}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handleAmountSelectApply}
        title={title}
      />
      <InvoiceDialog open={modalOpen} setOpen={setModalOpen} invoice={invoice} />
    </div>
  );
};

export default BusinessRepayments;
