import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchCompanyInfoCount = createAsyncThunk(
  'companyInfoCount/fetchCompanyInfoCount',
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/data/b2b/company-employees/info-count?companyId=${companyId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const companyInfoCountSlice = createSlice({
  name: 'companyInfoCount',
  initialState: {
    infoCount: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyInfoCount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyInfoCount.fulfilled, (state, action) => {
        state.infoCount = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyInfoCount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.infoCount = {};
      });
  },
});

export default companyInfoCountSlice.reducer;
