import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './CustomDialogTitle.scss';

const CustomDialogTitle = ({ title, handleClose, titleMiddle, hasCloseIcon, titleIcon }) => {
  return (
    <div
      className={`dialog-title-wrapper ${titleMiddle ? 'title-middle' : ''} ${
        hasCloseIcon ? 'has-close-icon' : ''
      } ${titleIcon ? 'has-title-icon' : ''}`}
    >
      <div className="icon-content-wrapper">
        {titleIcon && <div className="title-icon">{titleIcon}</div>}
        <DialogTitle variant="h4">{title}</DialogTitle>
      </div>
      {hasCloseIcon && <CloseIcon className="close-icon" color="primary" onClick={handleClose} />}
    </div>
  );
};

CustomDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  titleMiddle: PropTypes.bool,
  hasCloseIcon: PropTypes.bool,
  titleIcon: PropTypes.elementType,
};

CustomDialogTitle.defaultProps = {
  titleMiddle: false,
  hasCloseIcon: false,
  titleIcon: null,
};

export default CustomDialogTitle;
