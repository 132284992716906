import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout as reduxLogout } from '../../app/login/loginSlice';
import authLockObj from '../../config/authLock';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [authLock, setAuthLock] = useState(authLockObj);

  useEffect(() => {
    setAuthLock(authLockObj);
  }, [authLockObj]);

  const authLockValue = useMemo(() => ({ authLock }), [authLock]);

  return <AuthContext.Provider value={authLockValue}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const useLogout = () => {
  const { authLock } = useAuth();
  const dispatch = useDispatch();
  const logout = () => {
    authLock.logout({
      returnTo: window.location.origin,
    });
    dispatch(reduxLogout());
  };
  return logout;
};

export { AuthProvider, useAuth, useLogout };
