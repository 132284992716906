import { Skeleton } from '@mui/material';
import React from 'react';
import './EmployeeDetails.scss';

const SkeletonBoxContainer = () => {
  return (
    <div className="box-container">
      <div className="box">
        <Skeleton animation="wave" variant="text" width={200} />
        <Skeleton animation="wave" variant="text" width={150} height={40} />
      </div>
      <div className="box">
        <Skeleton animation="wave" variant="text" width={250} />
        <Skeleton animation="wave" variant="text" width={100} height={40} />
      </div>
      <div className="box">
        <Skeleton animation="wave" variant="text" width={200} />
        <Skeleton animation="wave" variant="text" width={150} height={40} />
      </div>
    </div>
  );
};

export default SkeletonBoxContainer;
