import React from 'react';
import Typography from '@mui/material/Typography';
import './B2CAnalytics.scss';

const B2CAnalytics = () => {
  return (
    <div className="b2c-analytics">
      <Typography variant="h4" className="message-style">
        Coming Soon
      </Typography>
    </div>
  );
};

export default B2CAnalytics;
