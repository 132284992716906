import React from 'react';
import { Card, CardContent, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import './DirectorInfoDialog.scss';

const DirectorInfoDialog = (props) => {
  const { open, setOpen, position, directorInfo } = props;
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="director-details-dialog"
      style={{ position: 'absolute', top: position.top, left: position.left }}
    >
      <Card variant="outlined">
        <CardContent className="director-info">
          <div className="title-icon">
            <Typography className="title">{directorInfo.name}</Typography>
            <IconButton size="small" onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </div>
          <p className="info-data">{directorInfo.email}</p>
          <p className="info-data">{directorInfo.linkedIn}</p>
          <p className="info-data">{directorInfo.phone}</p>
        </CardContent>
      </Card>
    </Modal>
  );
};
DirectorInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
  directorInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    linkedin: PropTypes.string,
  }).isRequired,
};
export default DirectorInfoDialog;
