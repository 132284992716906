import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  authResult: {},
};

export const loginSlice = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    authResultFetching: (state) => {
      state.fetchingAuthResult = true;
    },
    resetAuth: (state) => {
      state.fetchingAuthResult = false;
    },
    setAuthResult: (state, action) => {
      state.authResult = action.payload;
    },
    setTokenExpireTime: (state, action) => {
      state.tokenExpireTime = action.payload;
    },
    setIsLogoutClicked: (state) => {
      state.isLogoutClicked = true;
    },
    logout: (state) => {
      state.token = '';
      state.authResult = {};
    },
  },
});

export const {
  setToken,
  logout,
  setAuthResult,
  authResultFetching,
  resetAuth,
  setIsLogoutClicked,
  setIsSignup,
  businessUserCreateInitiate,
  businessUserCreateSucceed,
  businessUserCreateFail,
  setTokenExpireTime,
} = loginSlice.actions;

export default loginSlice.reducer;
