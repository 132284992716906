const bytesToMB = (fileSizeInBytes) => {
  if (typeof fileSizeInBytes !== 'number') {
    throw new Error('Input must be a number representing file size in bytes');
  }

  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  return parseFloat(fileSizeInMB.toFixed(2));
};

export default bytesToMB;
