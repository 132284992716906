/* eslint-disable import/no-extraneous-dependencies */
// import 'flowbite';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import PrivateRoute from './Components/Auth/PrivateRoute';
import PublicRoute from './Components/Auth/PublicRoute';
import B2BAnalytics from './Components/B2BAnalytics/B2BAnalytics';
import B2CAnalytics from './Components/B2CAnalytics/B2CAnalytics';
import B2CBusinessRepayments from './Components/B2CBusinessRepayments/B2CBusinessRepayments';
import B2CDashboard from './Components/B2CDashboard/B2CDashboard';
import B2CEmployeePayment from './Components/B2CEmployeePayment/B2CEmployeePayment';
import B2Cclaims from './Components/B2Cclaims/B2Cclaims';
import BusinessRepayments from './Components/BusinessRepayments/BusinessRepayments';
import Claims from './Components/Claims/Claims';
import Companies from './Components/Companies/Companies';
import CompanyDetails from './Components/CompanyDetails/CompanyDetails';
import CompanyEmployees from './Components/CompanyEmployees/CompanyEmployees';
import CreditCheck from './Components/CreditCheck/CreditCheck';
import Dashboard from './Components/Dashboard/Dashboard';
import EditNewNotification from './Components/EditNewNotification/EditNewNotification';
import EmployeeDetails from './Components/EmployeeDetails/EmployeeDetails';
import EmployeePayment from './Components/EmployeePayment/EmployeePayment';
import Login from './Components/Login/Login';
import LoginForm from './Components/Login/LoginForm';
import NotificationCentre from './Components/NotificationCentre/NotificationCentre';
import Payslip from './Components/Payslip/Payslip';
import RepositoryUserDetails from './Components/RepositoryUserDetails/RepositoryUserDetails';
import SettingsPage from './Components/Settings/Settings';
import UserRepository from './Components/UserRepository/UserRepository';
import LayoutWithSidebar from './layouts/LayoutWithSidebar';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PublicRoute element={<Login />} />} />
        <Route path="/login" element={<PublicRoute element={<LoginForm />} />} />
        <Route path="/dashboard" element={<Navigate to="/b2b-dashboard" />} />
        <Route path="/b2b-dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route path="/b2c-dashboard" element={<PrivateRoute element={<B2CDashboard />} />} />
        <Route path="/b2b-credit-check" element={<PrivateRoute element={<CreditCheck />} />} />
        <Route path="/b2b-companies" element={<PrivateRoute element={<Companies />} />} />
        <Route path="/b2b-claims" element={<PrivateRoute element={<Claims />} />} />
        <Route
          path="/b2b-employee-payments"
          element={<PrivateRoute element={<EmployeePayment />} />}
        />
        <Route
          path="/b2b-business-repayments"
          element={<PrivateRoute element={<BusinessRepayments />} />}
        />
        <Route path="/b2c-payslips" element={<PrivateRoute element={<Payslip />} />} />
        <Route
          path="/b2c-employee-payments"
          element={<PrivateRoute element={<B2CEmployeePayment />} />}
        />
        <Route path="/b2c-claims" element={<PrivateRoute element={<B2Cclaims />} />} />
        <Route
          path="/b2c-business-repayments"
          element={<PrivateRoute element={<B2CBusinessRepayments />} />}
        />
        <Route
          path="/b2c-user-repository"
          element={<PrivateRoute element={<UserRepository />} />}
        />
        <Route path="/company/:companyId" element={<PrivateRoute element={<CompanyDetails />} />} />
        <Route
          path="/company/employees/:companyId"
          element={<PrivateRoute element={<CompanyEmployees />} />}
        />
        <Route
          path="/settings"
          element={
            <LayoutWithSidebar>
              <SettingsPage />
            </LayoutWithSidebar>
          }
        />
        <Route path="/b2b-analytics" element={<PrivateRoute element={<B2BAnalytics />} />} />
        <Route path="/b2c-analytics" element={<PrivateRoute element={<B2CAnalytics />} />} />
        <Route
          path="/notification-centre"
          element={<PrivateRoute element={<NotificationCentre />} />}
        />
        <Route
          path="/notification-centre/new"
          element={<PrivateRoute element={<EditNewNotification />} />}
        />
        <Route
          path="/notification-centre/edit/:notificationId"
          element={<PrivateRoute element={<EditNewNotification />} />}
        />
        <Route
          path="/user-details/:userId"
          element={<PrivateRoute element={<RepositoryUserDetails />} />}
        />
        <Route
          path="/employee-details/:employeeId"
          element={<PrivateRoute element={<EmployeeDetails />} />}
        />
      </Routes>
    </div>
  );
}

export default App;
