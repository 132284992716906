import { useEffect, useRef } from 'react';

export default function useDidUpdateEffect(fn, inputs) {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isMountingRef.current) {
      return fn();
    }
    isMountingRef.current = false;
  }, inputs);
}
