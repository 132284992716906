import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePaySlip } from '../../app/paySlips/paySlipSlice';
import FeedbackDialogue from '../Dialog/feedback-dialogue';
import {
  SafetyNetApplicationBankStatementUpdateRequiredReason,
  SafetyNetApplicationPayslipUpdateRequiredReason,
  SafetyNetApplicationUpdateRequiredReason,
} from './types';

const DOCUMENT_UPDATE_REASONS = [
  {
    title: 'Payslip',
    id: 'PAYSLIP',
    options: Object.keys(SafetyNetApplicationPayslipUpdateRequiredReason).map((key) => ({
      label: SafetyNetApplicationPayslipUpdateRequiredReason[key],
      value: key,
    })),
  },
  {
    title: 'Bank Statement',
    id: 'BANK_STATEMENT',
    options: Object.keys(SafetyNetApplicationBankStatementUpdateRequiredReason).map((key) => ({
      label: SafetyNetApplicationBankStatementUpdateRequiredReason[key],
      value: key,
    })),
  },
  {
    title: 'Admin Comments (not visible to users)',
    id: 'OTHER',
    type: 'input',
  },
];

const ApplicationUpdateRequiredModal = ({ payslipId, user, children }) => {
  const dispatch = useDispatch();
  const [selections, setSelections] = useState({});
  const [open, setOpen] = useState(false);

  const handleApply = () => {
    // Destructure the comments
    const { OTHER: otherComments, ...finalSelections } = selections;

    /**
     * The 'readable' version of the reasons--an array of sentences rather than
     * enum codes.
     */
    let _reasons = Object.keys(selections).reduce((acc, key) => {
      // Map the sub-array and then spread it into the accumulator array
      const reasonsFromKey = selections[key].map(
        (_key) => SafetyNetApplicationUpdateRequiredReason[_key]
      );
      return acc.concat(reasonsFromKey);
    }, []);

    _reasons = _reasons.filter(Boolean);

    if (_reasons.length === 0) {
      // eslint-disable-next-line
      alert('Please select reason(s)');
      return;
    }

    if (otherComments) _reasons = _reasons.concat(otherComments);

    dispatch(
      updatePaySlip({
        action: 'APPLICATION_UPDATE_REQUIRED',
        payslipId,
        payslipStatus: 'APPLICATION_UPDATE_REQUIRED',
        rejectReason: _reasons,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        latestSafetyNetApplicationMetadata: finalSelections,
      })
    );
    setOpen(false);
  };

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <div role="button" tabIndex={0} onClick={toggleModal}>
        {children}
      </div>
      <FeedbackDialogue
        open={open}
        setOpen={setOpen}
        value={selections}
        setValue={setSelections}
        options={DOCUMENT_UPDATE_REASONS}
        title="Please provide reason(s) for the application update"
        subTitle="Message displayed to user in the Subi app depends on the update reason"
        handleApply={handleApply}
      />
    </>
  );
};

const userType = {
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

ApplicationUpdateRequiredModal.propTypes = {
  payslipId: PropTypes.string.isRequired,
  user: PropTypes.shape(userType).isRequired,
};

export default ApplicationUpdateRequiredModal;
