import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import * as React from 'react';
import './CustomDatatable.scss';

const CustomDatatable = ({ children, rowsPerPage, setRowsPerPage, page, setPage, totalCount }) => {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div className="table-wrapper">
      <TableContainer
        component={Paper}
        style={{ border: '1px solid #E6E6EE', minHeight: '550px', padding: '24px' }}
      >
        {children}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="table-pagination"
        slotProps={{
          select: {
            style: { marginRight: 'auto', borderRadius: 0 },
            variant: 'filled',
          },
        }}
      />
    </div>
  );
};

CustomDatatable.propTypes = {
  children: PropTypes.elementType.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default CustomDatatable;
