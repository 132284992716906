/* eslint-disable react/no-array-index-key */
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const AdminInfoSkeleton = () => {
  return (
    <div className="company-info">
      <Box sx={{ textTransform: 'uppercase' }}>
        <h2>
          <Skeleton variant="text" width={100} />
        </h2>
      </Box>
      <div className="child-row">
        {[...Array(2)].map((_, index) => (
          <div className="box-content" key={index}>
            <h3>
              <Skeleton variant="text" width={80} />
            </h3>
            <h5>
              <Skeleton variant="text" width={120} />
            </h5>
          </div>
        ))}
      </div>
      <div className="child-row">
        {[...Array(2)].map((_, index) => (
          <div className="box-content" key={index}>
            <h3>
              <Skeleton variant="text" width={80} />
            </h3>
            <h5>
              <Skeleton variant="text" width={120} />
            </h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminInfoSkeleton;
