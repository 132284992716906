import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendRemindEmail } from '../../../app/sendEmail/sendEmailSlice';
import { businessApiUrl } from '../../../config/variable';
import { centsToDollars } from '../../../utils/helperMethod';
import BaseDialog from '../BaseDialog/BaseDialog';
import './invoiceDialog.scss';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const InvoiceDialog = (props) => {
  const { open, setOpen, handleApply, invoice, ...other } = props;
  const dispatch = useDispatch();
  const [openSnack, setSnackOpen] = useState(false);
  const [sendEmailMessage, setSendEmailMessage] = useState('');

  const handleOnInvoiceLinkClick = () => {
    window.open(`${businessApiUrl}/invoices/${invoice._id}/download`);
  };

  // eslint-disable-next-line no-unused-vars
  const handleBtnClick = async (e, type, val) => {
    if (type === 'receipt') {
      window.open(val.receiptUrl);
    }
    if (type === 'remind') {
      const emailData = {
        paymentName: val.itemDescription,
        dueDate: val.dueDate.iso,
        receiverEmail: invoice.email,
        firstName: invoice.firstName,
      };
      const response = await dispatch(sendRemindEmail(emailData)).unwrap();
      setSendEmailMessage(response.message);
      setSnackOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const dateNow = Date.now();
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      hasCloseIcon
      title={`Invoice ${invoice?.invoiceNumber}`}
      subTitle={`Requested by: ${`${invoice?.firstName} ${invoice?.lastName}`}`}
      actionButtons={[]}
      className="invoice-dialog"
      titleMiddle
      subTitleMiddle
      {...other}
    >
      <Typography variant="muted1" className="totalAmount-wrapper">
        Total amount: <span className="totalAmount">${centsToDollars(invoice?.total)}</span>
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="underlined"
          onClick={handleOnInvoiceLinkClick}
          sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}
        >
          Invoice
        </Button>
      </Box>
      <List className="list-container" sx={{ bgcolor: 'background.paper' }}>
        {invoice?.items?.map((val, i) => (
          <Box key={val.itemId}>
            <ListItem alignItems="center">
              <ListItemAvatar>
                {val.isPaid ? (
                  <CheckCircleOutlineIcon color="green" />
                ) : (
                  <DonutLargeIcon color="grey" />
                )}
              </ListItemAvatar>
              <Box className="list-item-content">
                <Grid container>
                  <Grid item xs={6.5}>
                    <Typography className="bold-text" color="noir">
                      {val.itemDescription}
                    </Typography>
                    <Typography
                      sx={{ display: 'inline' }}
                      color={
                        !val.isPaid && new Date(val.dueDate?.iso).getTime() - dateNow < 0
                          ? 'red.100'
                          : 'grey.60'
                      }
                    >
                      Due on: {dayjs(val.dueDate?.iso).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item alignSelf="center" xs={3}>
                    <Typography className="bold-text" sx={{ textAlign: 'end' }} color="noir">
                      ${centsToDollars(val.itemAmount)}
                    </Typography>
                    {val.paidDate && (
                      <Typography sx={{ textAlign: 'end' }} color="green.main">
                        Paid {dayjs(val.paidDate?.iso).format('DD/MM/YYYY')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item alignSelf="center" xs={2} className="action-btn-wrapper">
                    {(() => {
                      if (val.isPaid) {
                        return (
                          <Button
                            variant="underlined"
                            onClick={(e) => handleBtnClick(e, 'receipt', val)}
                            sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                          >
                            Receipt
                          </Button>
                        );
                      }
                      if (new Date(val.dueDate?.iso).getTime() - dateNow < 0) {
                        return (
                          <Button
                            variant="contained"
                            onClick={(e) => handleBtnClick(e, 'remind', val)}
                          >
                            Remind
                          </Button>
                        );
                      }
                      return <div />;
                    })()}
                  </Grid>
                </Grid>
              </Box>
              {/* <Box className="action-btn-wrapper">
                {(() => {
                  if (val.isPaid) {
                    return (
                      <Button
                        variant="underlined"
                        onClick={(e) => handleBtnClick(e, 'receipt', val)}
                        sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}
                      >
                        Receipt
                      </Button>
                    );
                  }
                  if (new Date(val.dueDate?.iso).getTime() - dateNow < 0) {
                    return (
                      <Button variant="contained" onClick={(e) => handleBtnClick(e, 'remind', val)}>
                        Remind
                      </Button>
                    );
                  }
                  return <div />;
                })()}
              </Box> */}
            </ListItem>
            {i !== invoice.items.length - 1 && <Divider component="li" />}
          </Box>
        ))}
      </List>
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {sendEmailMessage}
        </Alert>
      </Snackbar>
    </BaseDialog>
  );
};

InvoiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  invoice: PropTypes.isRequired,
};

export default InvoiceDialog;
