import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet } from '../../utilities/apiCaller';

export const fetchCompanyEmployees = createAsyncThunk(
  'companyEmployees/fetchCompanyEmployees',
  async ({ companyId, status, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/data/b2b/company-employees?companyId=${companyId}&status=${status}&limit=${limit}&offset=${
          offset + 1
        }`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const companyEmployeesSlice = createSlice({
  name: 'companyEmployees',
  initialState: {
    employees: [],
    isLoading: false,
    totalCount: 0,
    companyName: '',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyEmployees.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.totalCount = 0;
      })
      .addCase(fetchCompanyEmployees.fulfilled, (state, action) => {
        state.companyName = action.payload.companyName;
        state.employees = action.payload?.data;
        state.totalCount = action.payload?.totalCount;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.employees = [];
      });
  },
});

export default companyEmployeesSlice.reducer;
