import Typography from '@mui/material/Typography';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApprovedPayslipsTable,
  PendingPayslipsTable,
  RejectedPayslipsTable,
} from '../../app/features/payslips/components/tables';
import PendingUpdatesPayslipsTable from '../../app/features/payslips/components/tables/pending/PendingUpdatesPayslipsTable';
import {
  fetchPaySlips,
  getSignUrl,
  setPaySlipsBySearchToken,
  updatePaySlip,
} from '../../app/paySlips/paySlipSlice';
import { stripePublishableKey } from '../../config/variable';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import getItemPosition from '../../utils/getItemPosition';
import { rejectOptions } from '../../utils/selectOptions';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AddFallbackDialog from '../Dialog/AddFallbackDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import DateDialog from '../Dialog/DateDialog';
import EmployeeApproveDialog from '../Dialog/EmployeeApproveDialog/EmployeeApproveDialog';
import ManagerInfoDialog from '../Dialog/ManagerInfoDialog/ManagerInfoDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TabStatus from '../Tabs/TabStatus';
import './Payslip.scss';

const stripePromise = loadStripe(stripePublishableKey);

const Payslip = () => {
  const dispatch = useDispatch();
  const { isLoading, paySlips, totalCount } = useSelector((state) => state.paySlips);

  const tabsData = ['Pending Approval', 'Pending Update', 'Approved', 'Rejected'];
  const [status, setStatus] = useState(tabsData[0]);

  const filterPending = [{ name: 'Date Uploaded' }];
  const filterRejected = [
    { name: 'Date Uploaded' },
    { name: 'Date Rejected' },
    { name: 'Rejected Reason' },
  ];

  const [title, setTitle] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [checkboxSelectModalOpen, setCheckboxSelectModalOpen] = useState(false);
  const [dateSelectModalOpen, setDateSelectModalOpen] = useState(false);
  const [selectModalValues, setSelectModalValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [uploadedDateTimeframe, setUploadedDateTimeframe] = useState('before');
  const [uploadedDate, setUploadedDate] = useState(dayjs(Date.now()));
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [approveValue, setApproveValue] = useState(null);
  const [selectedPayslipId, setSelectedPayslipId] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dateRejectedSelectModalOpen, setDateRejectedSelectModalOpen] = useState(false);
  const [rejectedDate, setRejectedDate] = useState(dayjs(Date.now()));
  const [rejectedDateTimeframe, setRejectedDateTimeframe] = useState('before');
  const [rejectedReasonSelectModalOpen, setRejectedReasonSelectModalOpen] = useState(false);
  const [rejectedReasonSelectModalValues, setRejectedReasonSelectModalValues] = useState([]);
  const [personalInfoDialogModal, setPersonalInfoDialogModal] = useState(false);
  const [personalInfoDataDialog, setPersonalInfoDataDialog] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  /**
   * Fallback modal
   */
  const [fallbackModal, setFallbackModal] = useState({
    isOpen: false,
    userId: null,
    email: null,
    fullName: null,
  });

  const handleFallbackModalOnClose = () => {
    setFallbackModal({
      isOpen: false,
      userId: null,
      email: null,
      fullName: null,
    });
  };

  const handleFallbackModalOnOpen = (userId, email, fullName) => {
    if (!userId || !email) return;
    setFallbackModal({
      isOpen: true,
      userId,
      email,
      fullName,
    });
  };

  /**
   * End Fallback modal
   */

  const clearAllFilters = () => {
    setRejectedReasonSelectModalValues([]);
    setSelectModalValues([]);
    setRejectedDateTimeframe('before');
    setRejectedDate(dayjs(Date.now()));
    setUploadedDateTimeframe('before');
    setUploadedDate(dayjs(Date.now()));
  };

  const handleChangeStatus = (newStatus) => {
    setFilterList([]);
    clearAllFilters();
    setStatus(tabsData[newStatus]);
  };

  const handleApproveClick = (payslipId, user) => {
    setSelectedPayslipId(payslipId);
    setSelectedUser(user);
    setOpenApproveModal(true);
  };

  const handleApproveApply = () => {
    console.log('submit test debug... ', approveValue);

    if (!approveValue || !approveValue.payday) {
      // eslint-disable-next-line no-alert
      window.alert('Payday is a required field');
      return;
    }

    dispatch(
      updatePaySlip({
        payslipId: selectedPayslipId,
        action: 'APPROVED',
        payslipStatus: 'PAYSLIP_RECEIVED',
        firstName: approveValue.firstName,
        lastName: approveValue.lastName,
        email: approveValue.email,
        companyName: approveValue.companyName,
        hourlyRate: approveValue.hourlyRate,
        totalAnnualLeaveInHours: approveValue.totalAnnualLeaveInHours,
        workingHoursPerWeek: 38,
        cashOutLimit: approveValue.cashOutLimit,
        bankStatementPayday: approveValue.payday,
      })
    );
    setOpenApproveModal(false);
  };

  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);

    if (type === 'date-uploaded') {
      setTitle('Date uploaded');
      setDateSelectModalOpen(!dateSelectModalOpen);
    }
    if (type === 'date-rejected') {
      setTitle('Date rejected');
      setDateRejectedSelectModalOpen(!dateRejectedSelectModalOpen);
    }
    if (type === 'rejected-reason') {
      setTitle('Rejected reason');
      setOptions(rejectOptions);
      setRejectedReasonSelectModalOpen(!rejectedReasonSelectModalOpen);
    }
  };

  const handleCheckboxSelectApply = () => {
    setCheckboxSelectModalOpen(false);
  };

  const handleDateUploadedSelectApply = () => {
    setFilterList([...filterList, 'uploadedDate']);
    setDateSelectModalOpen(false);
  };

  const handlePayslipView = async (fileName) => {
    const data = {
      fileName,
    };
    const signurl = await dispatch(getSignUrl(data)).unwrap();

    /**
     * Open the pdf in a new window--avoids an auto download.
     */
    const pdfWindow = window.open('_blank');
    pdfWindow?.document.write(
      `<embed
    src="${signurl}"
    type="application/pdf"
    width="100%"
    height="100%"
    />`
    );
  };

  const isPending = status.toUpperCase().startsWith('PENDING');

  const filterOptions = isPending ? filterPending : filterRejected;
  useEffect(() => {
    const PayslipParams = {
      status: status.toLowerCase(),
      limit: rowsPerPage,
      offset: page,
    };

    if (filterList.includes('uploadedDate')) {
      PayslipParams.uploadDate =
        uploadedDateTimeframe + uploadedDate.toISOString().substring(0, 10);
    }

    if (filterList.includes('rejectedDate')) {
      PayslipParams.rejectDate =
        rejectedDateTimeframe + rejectedDate.toISOString().substring(0, 10);
    }

    if (filterList.includes('rejectedReason')) {
      PayslipParams.rejectReason = rejectedReasonSelectModalValues.join('$');
    }

    dispatch(fetchPaySlips(PayslipParams));
  }, [dispatch, status, rowsPerPage, page, filterList]);

  const [searchToken, setSearchToken] = useState();
  useDidUpdateEffect(() => {
    dispatch(setPaySlipsBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const handleRejectedDateSelectApply = () => {
    setFilterList([...filterList, 'rejectedDate']);
    setDateRejectedSelectModalOpen(false);
  };

  const handleRejectedReasonSelectApply = () => {
    setFilterList([...filterList, 'rejectedReason']);
    setRejectedReasonSelectModalOpen(false);
  };

  const tablesByStatusMap = {
    Approved: (
      <ApprovedPayslipsTable
        paySlips={paySlips}
        getItemPosition={getItemPosition}
        setPersonalInfoDataDialog={setPersonalInfoDataDialog}
        setPersonalInfoDialogModal={setPersonalInfoDialogModal}
        setPosition={setPosition}
        handlePayslipView={handlePayslipView}
        handleFallbackModalOnOpen={handleFallbackModalOnOpen}
      />
    ),
    'Pending Approval': (
      <PendingPayslipsTable
        paySlips={paySlips}
        getItemPosition={getItemPosition}
        setPersonalInfoDataDialog={setPersonalInfoDataDialog}
        setPersonalInfoDialogModal={setPersonalInfoDialogModal}
        setPosition={setPosition}
        handlePayslipView={handlePayslipView}
        handleApproveClick={handleApproveClick}
      />
    ),
    'Pending Update': (
      <PendingUpdatesPayslipsTable
        paySlips={paySlips}
        getItemPosition={getItemPosition}
        setPersonalInfoDataDialog={setPersonalInfoDataDialog}
        setPersonalInfoDialogModal={setPersonalInfoDialogModal}
        setPosition={setPosition}
        handlePayslipView={handlePayslipView}
        handleApproveClick={handleApproveClick}
      />
    ),
    Rejected: (
      <RejectedPayslipsTable
        paySlips={paySlips}
        getItemPosition={getItemPosition}
        setPersonalInfoDataDialog={setPersonalInfoDataDialog}
        setPersonalInfoDialogModal={setPersonalInfoDialogModal}
        setPosition={setPosition}
        handlePayslipView={handlePayslipView}
      />
    ),
  };

  const renderTableRows = () => {
    return tablesByStatusMap[status];
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="payslip">
        <div className="title">
          <h1>Payslips</h1>
        </div>
        <div className="search">
          <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
        </div>
        <ButtonGroup buttons={filterOptions} handleSelectClick={handleSelectClick} />
        <TabStatus
          tabsData={tabsData}
          handleChangeStatus={handleChangeStatus}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <CustomDatatable
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalCount={totalCount}
        >
          {(() => {
            if (isLoading) {
              return (
                <div className="loader-container">
                  <Loader />
                </div>
              );
            }
            if (!paySlips.length) {
              return (
                <Typography variant="h4" className="message-style">
                  No payslips to show
                </Typography>
              );
            }
            return <>{renderTableRows()}</>;
          })()}
        </CustomDatatable>

        <CheckboxDialog
          open={checkboxSelectModalOpen}
          setOpen={setCheckboxSelectModalOpen}
          values={selectModalValues}
          setValues={setSelectModalValues}
          options={options}
          position={position}
          handleApply={handleCheckboxSelectApply}
          title={title}
        />
        <DateDialog
          open={dateSelectModalOpen}
          setOpen={setDateSelectModalOpen}
          date={uploadedDate}
          setDate={setUploadedDate}
          timeframe={uploadedDateTimeframe}
          setTimeframe={setUploadedDateTimeframe}
          handleApply={handleDateUploadedSelectApply}
          position={position}
          title={title}
        />
        <DateDialog
          open={dateRejectedSelectModalOpen}
          setOpen={setDateRejectedSelectModalOpen}
          date={rejectedDate}
          setDate={setRejectedDate}
          timeframe={rejectedDateTimeframe}
          setTimeframe={setRejectedDateTimeframe}
          handleApply={handleRejectedDateSelectApply}
          position={position}
          title={title}
        />
        <CheckboxDialog
          open={rejectedReasonSelectModalOpen}
          setOpen={setRejectedReasonSelectModalOpen}
          values={rejectedReasonSelectModalValues}
          setValues={setRejectedReasonSelectModalValues}
          options={options}
          position={position}
          handleApply={handleRejectedReasonSelectApply}
          title={title}
        />
        <EmployeeApproveDialog
          open={openApproveModal}
          setOpen={setOpenApproveModal}
          value={approveValue}
          setValue={setApproveValue}
          handleApply={handleApproveApply}
          selectedUser={selectedUser}
        />
        <ManagerInfoDialog
          open={personalInfoDialogModal}
          setOpen={setPersonalInfoDialogModal}
          position={position}
          managerInfo={personalInfoDataDialog}
        />
        <ManagerInfoDialog
          open={personalInfoDialogModal}
          setOpen={setPersonalInfoDialogModal}
          position={position}
          managerInfo={personalInfoDataDialog}
        />
        <AddFallbackDialog
          isOpen={fallbackModal.isOpen}
          userId={fallbackModal.userId}
          email={fallbackModal.email}
          fullName={fallbackModal.fullName}
          handleClose={handleFallbackModalOnClose}
        />
      </div>
    </Elements>
  );
};

export default Payslip;
