import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import bytesToMB from '../../../utils/bytesToMB';
import BaseDialog from '../BaseDialog/BaseDialog';
import './UploadDialog.scss';

const UploadDialog = (props) => {
  const { open, setOpen, file, setFile, title, subTitle, handleApply, value, ...other } = props;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop: (files) => setFile(files),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const cancelHandler = () => {
    acceptedFiles.pop();
    setFile(null);
  };

  const files = acceptedFiles.map((singleFile) => (
    <li className="list-item" key={singleFile.path}>
      <div className="item-close-icon-wrapper">
        <div className="list-inner-item-wrapper">
          <div>
            <span className="filename">{singleFile.path}</span>
            <span className="filesize"> - {bytesToMB(singleFile.size)} MB</span>
          </div>
          <div>
            <Typography>
              100% <CheckCircleIcon color="green" fontSize="small" />
            </Typography>
          </div>
        </div>
        <div className="green-line" />
      </div>
      <CancelIcon className="cancel-icon" color="grey" fontSize="small" onClick={cancelHandler} />
    </li>
  ));

  return (
    <BaseDialog
      title={title}
      subTitle={subTitle}
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      hasCloseIcon
      actionButtons={[
        { label: 'Upload', disabled: !file, variant: 'contained', onClick: handleApply },
      ]}
      titleMiddle
      subTitleMiddle
      className="upload-dialog"
      {...other}
    >
      <Typography align="center" className="abn">
        ABN: {value.abn}
      </Typography>
      <section className="dropzone-container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Typography className="upload-text">
            <span className="sec-colored-text">Upload a file</span> or drag and drop here
          </Typography>
        </div>
        <aside className="filename-wrapper">
          <ul>{files}</ul>
        </aside>
      </section>
    </BaseDialog>
  );
};

UploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  file: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  handleApply: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
};

UploadDialog.defaultProps = {
  value: {},
};

export default UploadDialog;
