/* eslint-disable no-underscore-dangle */
import AttachmentIcon from '@mui/icons-material/Attachment';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  createB2cCompany,
  deleteB2cCreditCheckFile,
  fetchB2CClaims,
  getB2cClaimsPendingSignedUrl,
  getB2cCreditCheckSignedUrl,
  setb2cClaimsBySearchToken,
  updateClaimsContactPending,
  updateCreditCheckPendingStatus,
  uploadB2cCreditCheckDoc,
} from '../../app/claimsb2c/claimsb2cSlice';
import { sendCompanySignupEmail, sendRequestMail } from '../../app/sendEmail/sendEmailSlice';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import { convertTimestampToDate } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import { formatNumberWithThousandSeparator, getMarkModalPosition } from '../../utils/helperMethod';
import { amountReuquestedSelect, companySelect, statusOptions } from '../../utils/selectOptions';
import { shortStringWithEllipsis } from '../../utils/shortStrWithEllipsis';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import AmountSliderDialog from '../Dialog/AmountSliderDialog';
import CheckboxDialog from '../Dialog/CheckboxDialog';
import CreditCheckDialog from '../Dialog/CreditCheckDialog/CreditCheckDialog';
import DateDialog from '../Dialog/DateDialog';
import DirectorInfoDialog from '../Dialog/DirectorInfoDialog/DirectorInfoDialog';
import ManagerInfoDialog from '../Dialog/ManagerInfoDialog/ManagerInfoDialog';
import MarkAsContactDialog from '../Dialog/MarkAsContactDialog/MarkAsContactDialog';
import UploadDialog from '../Dialog/UploadDialog/UploadDialog';
import Loader from '../Loader/Loader';
import Searchbar from '../Searchbar/Searchbar';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './B2Cclaims.scss';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const B2Cclaims = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const tabParam = Number(searchParams.get('tab'));
  const [selectEmployee, setSelectEmployee] = useState({});
  const { isLoading, b2cClaims, totalCount } = useSelector((state) => state.b2cClaims);
  const [isClaimPending, setIsClaimPending] = useState(false);
  const [isContactPending, setIsContactPending] = useState(false);
  const [isCreditCheckPending, setIsCreditCheckPending] = useState(false);
  const [isManagerApprovalPending, setIsManagerApprovalPending] = useState(false);
  const tabsData = [
    'Claim Pending',
    'Contact Pending',
    'Credit Check Pending',
    'Manager Approval Pending',
  ];
  const [status, setStatus] = useState(tabsData[tabParam || 0]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [headings, setHeadings] = useState([]);
  const checkPendingStatus = (currentStatus) => {
    const claimPending = currentStatus.toUpperCase() === 'CLAIM PENDING';
    const contactPending = currentStatus.toUpperCase() === 'CONTACT PENDING';
    const creditCheckPending = currentStatus.toUpperCase() === 'CREDIT CHECK PENDING';
    const managerApprovalPending = currentStatus.toUpperCase() === 'MANAGER APPROVAL PENDING';
    setIsClaimPending(claimPending);
    setIsContactPending(contactPending);
    setIsCreditCheckPending(creditCheckPending);
    setIsManagerApprovalPending(managerApprovalPending);
    return {
      claimPending,
      contactPending,
      creditCheckPending,
      managerApprovalPending,
    };
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const claimPendingHeadings = [
    'User Id',
    'Employee',
    'Company',
    'Date Uploaded',
    'Attachment',
    'Payslip Verified',
  ];
  const contactPendingHeadings = [
    'Claim Id',
    'Employee',
    'Company',
    'Date Submitted',
    'Amount Requested',
    'Manager',
    'Director',
  ];
  const creditCheckHeadings = ['Company', 'ABN', 'Contact Info', 'Credit Check Report', 'Status'];
  const managerApprovalHeadings = [
    'Claim Id',
    'Employee',
    'Company',
    'Date Submitted',
    'Amount Requested',
    'Date Contacted',
  ];
  const [applyClicked, setApplyClicked] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [requestAmount, setRequestAmount] = useState([]);
  const [uploadedDateTimeframe, setUploadedDateTimeframe] = useState('before');
  const [uploadedDate, setUploadedDate] = useState(dayjs(Date.now()));
  const [title, setTitle] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [companySelectModalOpen, setCompanySelectModalOpen] = useState(false);
  const [uploadedDateSelectModalOpen, setUploadedDateSelectModalOpen] = useState(false);
  const [approvedDateSelectModalOpen, setApprovedDateSelectModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [companySelectModalValues, setCompanySelectModalValues] = useState([]);
  const [options, setOptions] = useState([]);
  const [dateSubmittedSelectModalOpen, setDateSubmittedSelectModalOpen] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(dayjs(Date.now()));
  const [submittedDateTimeframe, setSubmittedDateTimeframe] = useState('before');
  const [statusSelectModalOpen, setStatusSelectModalOpen] = useState(false);
  const [statusSelectModalValues, setStatusSelectModalValues] = useState([]);
  const [dateContactedSelectModalOpen, setDateContactedSelectModalOpen] = useState(false);
  const [contactedDate, setContactedDate] = useState(dayjs(Date.now()));
  const [contactedDateTimeframe, setContactedDateTimeframe] = useState('before');
  const [approveValue, setApproveValue] = useState({});

  useEffect(() => {
    const { claimPending, contactPending, creditCheckPending, managerApprovalPending } =
      checkPendingStatus(status);

    const commonParams = {
      limit: rowsPerPage,
      offset: page,
    };
    if (claimPending && filterList.includes('uploadedDate')) {
      commonParams.uploadDate = uploadedDateTimeframe + uploadedDate.toISOString().substring(0, 10);
    }
    if (!creditCheckPending && filterList.includes('company')) {
      commonParams.company = companySelectModalValues.join('$');
    }
    if ((contactPending || managerApprovalPending) && filterList.includes('submittedDate')) {
      commonParams.submittedDate =
        submittedDateTimeframe + submittedDate.toISOString().substring(0, 10);
    }
    if (managerApprovalPending && filterList.includes('contactedDate')) {
      commonParams.contactedDate =
        contactedDateTimeframe + contactedDate.toISOString().substring(0, 10);
    }
    if (creditCheckPending && filterList.includes('status')) {
      commonParams.status = statusSelectModalValues.join('$');
    }
    if ((contactPending || managerApprovalPending) && requestAmount.length) {
      commonParams.requestAmount = requestAmount.join('-');
    }
    if (creditCheckPending) {
      dispatch(fetchB2CClaims({ status: 'creditcheck_pending', ...commonParams }));
    } else if (contactPending) {
      dispatch(fetchB2CClaims({ status: 'contact_pending', ...commonParams }));
      setApplyClicked(false);
    } else if (managerApprovalPending) {
      dispatch(fetchB2CClaims({ status: 'manager_approval_pending', ...commonParams }));
      setApplyClicked(false);
    } else {
      dispatch(fetchB2CClaims({ status: 'claims_pending', ...commonParams }));
    }

    const filterClaimPending = [
      // { name: 'Company' },
      { name: 'Date Uploaded' },
    ];
    const filterContactPending = [
      // { name: 'Company' },
      { name: 'Date Submitted' },
      { name: 'Amount Requested' },
    ];
    const filterCreditCheckPending = [{ name: 'Status' }];
    const filterManagerApprovalPending = [
      // { name: 'Company' },
      { name: 'Date Submitted' },
      { name: 'Amount Requested' },
      { name: 'Date Contacted' },
    ];
    const filterOptionsMap = {
      isClaimPending: claimPending ? filterClaimPending : null,
      isContactPending: contactPending ? filterContactPending : null,
      isCreditCheckPending: creditCheckPending ? filterCreditCheckPending : null,
      isManagerApprovalPending: managerApprovalPending ? filterManagerApprovalPending : null,
    };

    const pendingStatus = Object.keys(filterOptionsMap).find(
      (option) => filterOptionsMap[option] !== null
    );

    // eslint-disable-next-line no-shadow
    const options = pendingStatus ? filterOptionsMap[pendingStatus] : filterClaimPending;
    setFilterOptions(options);

    const headingsMap = {
      isClaimPending: claimPending ? claimPendingHeadings : null,
      isContactPending: contactPending ? contactPendingHeadings : null,
      isCreditCheckPending: creditCheckPending ? creditCheckHeadings : null,
      isManagerApprovalPending: managerApprovalPending ? managerApprovalHeadings : null,
    };

    const selectedHeadings = pendingStatus ? headingsMap[pendingStatus] : claimPendingHeadings;
    setHeadings(selectedHeadings);
  }, [status, rowsPerPage, page, filterList, applyClicked]);

  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [amountSelectModalOpen, setAmountSelectModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [openSnack, setSnackOpen] = useState(false);
  const [sendEmailMessage, setSendEmailMessage] = useState('');
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const handleSelectClick = (e, type) => {
    const itemPosition = getItemPosition(e);
    setPosition(itemPosition);

    if (type === 'company') {
      setTitle('Company');
      setOptions(companySelect);
      setCompanySelectModalOpen(!companySelectModalOpen);
    }
    if (type === 'amount-requested') {
      setTitle('Amount Requested');
      setOptions(amountReuquestedSelect);
      setAmountSelectModalOpen(!amountSelectModalOpen);
    }
    if (type === 'date-approved') {
      setTitle('Date approved');
      setApprovedDateSelectModalOpen(!approvedDateSelectModalOpen);
    }
    if (type === 'date-uploaded') {
      setTitle('Date uploaded');
      setUploadedDateSelectModalOpen(!uploadedDateSelectModalOpen);
    }
    if (type === 'date-submitted') {
      setTitle('Date submitted');
      setDateSubmittedSelectModalOpen(!dateSubmittedSelectModalOpen);
    }
    if (type === 'status') {
      setTitle('Status');
      setOptions(statusOptions);
      setStatusSelectModalOpen(!statusSelectModalOpen);
    }
    if (type === 'date-contacted') {
      setTitle('Date contacted');
      setDateContactedSelectModalOpen(!dateContactedSelectModalOpen);
    }
  };

  const handleCompanySelectApply = () => {
    setFilterList([...filterList, 'company']);
    setCompanySelectModalOpen(false);
  };

  const handleUploadedDateSelectApply = () => {
    setFilterList([...filterList, 'uploadedDate']);
    setUploadedDateSelectModalOpen(false);
  };

  const handleSubmittedDateSelectApply = () => {
    setFilterList([...filterList, 'submittedDate']);
    setDateSubmittedSelectModalOpen(false);
  };
  const handleStatusSelectApply = () => {
    setFilterList([...filterList, 'status']);
    setStatusSelectModalOpen(false);
  };
  const handleContactedDateSelectApply = () => {
    setFilterList([...filterList, 'contactedDate']);
    setDateContactedSelectModalOpen(false);
  };
  const handleMarkAsContactModal = (e, row) => {
    const itemPosition = getMarkModalPosition(e);
    setSelectEmployee(row);
    setPosition(itemPosition);
    setContactModalOpen(true);
  };
  const handleAmountSelectApply = () => {
    setApplyClicked(true);
    setAmountSelectModalOpen(false);
  };
  const updateContactPendingByEmployeeId = () => {
    dispatch(updateClaimsContactPending(selectEmployee.employeeId));
  };

  const [searchToken, setSearchToken] = useState();
  useDidUpdateEffect(() => {
    dispatch(setb2cClaimsBySearchToken({ token: searchToken }));
  }, [searchToken]);

  const handleChangeCreditCheckStatus = async (data) => {
    await dispatch(updateCreditCheckPendingStatus(data)).unwrap();
    setSendEmailMessage(`Credit check pending ${data.action.toLowerCase()}`);
    setSnackOpen(true);
  };
  const handleChangeCreditCheckStatusApproved = async (data) => {
    await dispatch(
      updateCreditCheckPendingStatus({
        action: 'APPROVED',
        employeeId: data._id,
      })
    ).unwrap();
    setApproveValue(data);
    await dispatch(createB2cCompany({ employeeId: data._id }));
    setOpenApproveModal(true);
  };

  const handleSendEmail = async (emailData) => {
    setOpenApproveModal(false);
    const response = await dispatch(sendCompanySignupEmail(emailData)).unwrap();
    setSendEmailMessage(response.message);
    setSnackOpen(true);
  };
  const uploadClickHandler = (row) => {
    setSelectedEmployee(row);
    setUploadModalOpen(true);
  };

  const handleUploadApply = () => {
    dispatch(
      uploadB2cCreditCheckDoc({
        employeeId: selectedEmployee._id,
        file: file[0],
        filename: file[0]?.name.slice(0, -4),
      })
    );
    setUploadModalOpen(false);
  };
  const [managerInfo, setManagerInfo] = useState({});
  const [managerInfoModalOpen, setManagerInfoModalOpen] = useState(false);

  const handleManagerInfoModal = async (e, info) => {
    const itemPosition = getItemPosition(e);
    itemPosition.left -= 80;
    setPosition(itemPosition);
    setManagerInfo(info);
    setManagerInfoModalOpen(true);
  };

  const [directorInfo, setDirectorInfo] = useState({});
  const [directorInfoModalOpen, setDirectorInfoModalOpen] = useState(false);

  const handleDirectorInfoModal = async (e, info) => {
    const itemPosition = getItemPosition(e);
    itemPosition.left -= 160;
    setPosition(itemPosition);
    setDirectorInfo(info);
    setDirectorInfoModalOpen(true);
  };

  const handleRequestClick = async (e, row) => {
    const emailData = {
      firstName: row.firstName,
      receiverEmail: row.userEmail,
      employeeId: row.employeeId,
    };
    const response = await dispatch(sendRequestMail(emailData)).unwrap();
    setSendEmailMessage(response.message);
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const [isCreditCheckFileHovered, setIsCreditCheckFileHovered] = useState(false);
  const showCreditCheckUploadedFile = async (fileName) => {
    const resp = await dispatch(getB2cCreditCheckSignedUrl({ fileName })).unwrap();
    window.open(resp?.URL, '_blank');
  };

  const deleteCreditCheckUploadedFile = async (e, employeeId) => {
    e.stopPropagation();
    dispatch(deleteB2cCreditCheckFile({ employeeId }));
  };

  const claimsPendingFileHandler = async (fileName) => {
    const resp = await dispatch(getB2cClaimsPendingSignedUrl({ fileName })).unwrap();
    window.open(resp?.URL, '_blank');
  };

  return (
    <div className="claims" style={{ flex: 1, gap: '16px' }}>
      <div className="title">
        <h1>Claims</h1>
      </div>
      <div className="search">
        <Searchbar setSearchToken={setSearchToken} searchToken={searchToken} />
      </div>
      <ButtonGroup buttons={filterOptions} handleSelectClick={handleSelectClick} />
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        tabIndex={tabParam || 0}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!b2cClaims.length) {
            return (
              <Typography variant="h4" className="message-style">
                No claims to show
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headings.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isClaimPending &&
                  b2cClaims.map((row, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={`${row.userId}_${i}`}>
                      <TableCell>{row.userId}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>
                        {row.dateUploaded && convertTimestampToDate(row.dateUploaded)}
                      </TableCell>
                      <TableCell>
                        <button
                          type="button"
                          className="button-style"
                          onClick={() => claimsPendingFileHandler(row?.filename)}
                        >
                          <AttachmentIcon
                            style={{ transform: 'rotate(90deg)', marginRight: '4px' }}
                          />
                          {row.attachment} payslip
                        </button>
                      </TableCell>
                      <TableCell>
                        {row.payslipVerifiedDate && convertTimestampToDate(row.payslipVerifiedDate)}
                      </TableCell>
                    </TableRow>
                  ))}
                {isContactPending &&
                  b2cClaims.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row._id}</TableCell>
                      <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                      <TableCell>{row.company}</TableCell>
                      <TableCell>
                        {row._created_at && convertTimestampToDate(row._created_at)}
                      </TableCell>
                      <TableCell>
                        {row.claimAmount && formatNumberWithThousandSeparator(row.claimAmount)}
                      </TableCell>
                      <TableCell>
                        {row.manager}{' '}
                        {row.manager && (
                          <span>
                            <InfoOutlinedIcon
                              fontSize="small"
                              sx={{ width: '16px', height: '16px', cursor: 'pointer' }}
                              onClick={(e) => handleManagerInfoModal(e, row.managerInfo)}
                            />
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="director-cell">
                        {row.director ? (
                          <span>
                            {row.director}&nbsp;
                            <InfoOutlinedIcon
                              fontSize="small"
                              sx={{ width: '16px', height: '16px', cursor: 'pointer' }}
                              onClick={(e) => handleDirectorInfoModal(e, row.directorInfo)}
                            />
                          </span>
                        ) : (
                          <button
                            type="button"
                            className="request-button"
                            onClick={(e) => handleRequestClick(e, row)}
                          >
                            Request
                          </button>
                        )}
                        <MoreVertIcon
                          onClick={(e) => handleMarkAsContactModal(e, row)}
                          className="three-dot"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {isCreditCheckPending &&
                  b2cClaims.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>{row.company}</TableCell>
                      <TableCell>{row.abn}</TableCell>
                      <TableCell>{row.contactInfo}</TableCell>
                      <TableCell>
                        {row.creditCheckReport ? (
                          <Button
                            onMouseEnter={() => setIsCreditCheckFileHovered(true)}
                            onMouseLeave={() => setIsCreditCheckFileHovered(false)}
                            type="button"
                            variant="outlined"
                            className="attachment-file-wrapper"
                            onClick={() => showCreditCheckUploadedFile(row.creditCheckReport)}
                          >
                            <AttachmentIcon
                              style={{ transform: 'rotate(90deg)', marginRight: '4px' }}
                            />
                            {row.attachment}{' '}
                            <span>
                              {shortStringWithEllipsis(row.creditCheckReport?.split('/')[1], 5)}
                            </span>
                            {isCreditCheckFileHovered && (
                              <CancelIcon
                                onClick={(e) => deleteCreditCheckUploadedFile(e, row._id)}
                                fontSize="small"
                                className="cancel-icon"
                              />
                            )}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => uploadClickHandler(row)}
                          >
                            Upload
                          </Button>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="button-action-style">
                          <button
                            type="button"
                            className="reject"
                            onClick={() =>
                              handleChangeCreditCheckStatus({
                                action: 'REJECTED',
                                employeeId: row._id,
                              })
                            }
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            className="approve"
                            onClick={() => handleChangeCreditCheckStatusApproved(row)}
                          >
                            Approve
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                {isManagerApprovalPending &&
                  b2cClaims.map((row) => (
                    <TableRow key={row.claimId}>
                      <TableCell>{row.claimId}</TableCell>
                      <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                      <TableCell>{row.company}</TableCell>
                      <TableCell>{convertTimestampToDate(row.dateSubmitted)}</TableCell>
                      <TableCell>
                        {row.claimAmount && formatNumberWithThousandSeparator(row.claimAmount)}
                      </TableCell>
                      <TableCell>
                        {row.dateContacted && convertTimestampToDate(row.dateContacted)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <CheckboxDialog
        open={companySelectModalOpen}
        setOpen={setCompanySelectModalOpen}
        values={companySelectModalValues}
        setValues={setCompanySelectModalValues}
        options={options}
        position={position}
        handleApply={handleCompanySelectApply}
        title={title}
      />
      <DateDialog
        open={uploadedDateSelectModalOpen}
        setOpen={setUploadedDateSelectModalOpen}
        date={uploadedDate}
        setDate={setUploadedDate}
        timeframe={uploadedDateTimeframe}
        setTimeframe={setUploadedDateTimeframe}
        handleApply={handleUploadedDateSelectApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={dateSubmittedSelectModalOpen}
        setOpen={setDateSubmittedSelectModalOpen}
        date={submittedDate}
        setDate={setSubmittedDate}
        timeframe={submittedDateTimeframe}
        setTimeframe={setSubmittedDateTimeframe}
        handleApply={handleSubmittedDateSelectApply}
        position={position}
        title={title}
      />
      <DateDialog
        open={dateContactedSelectModalOpen}
        setOpen={setDateContactedSelectModalOpen}
        date={contactedDate}
        setDate={setContactedDate}
        timeframe={contactedDateTimeframe}
        setTimeframe={setContactedDateTimeframe}
        handleApply={handleContactedDateSelectApply}
        position={position}
        title={title}
      />
      <CheckboxDialog
        open={statusSelectModalOpen}
        setOpen={setStatusSelectModalOpen}
        values={statusSelectModalValues}
        setValues={setStatusSelectModalValues}
        options={options}
        position={position}
        handleApply={handleStatusSelectApply}
        title={title}
      />
      <MarkAsContactDialog
        open={contactModalOpen}
        setOpen={setContactModalOpen}
        position={position}
        updateContactPendingByEmployeeId={updateContactPendingByEmployeeId}
      />
      <UploadDialog
        title="Upload credit check report"
        file={file}
        setFile={setFile}
        open={uploadModalOpen}
        setOpen={setUploadModalOpen}
        subTitle={`Company name: ${selectedEmployee.company}`}
        handleApply={handleUploadApply}
        value={selectedEmployee}
      />
      <CreditCheckDialog
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        value={approveValue}
        handleApply={handleSendEmail}
      />
      <AmountSliderDialog
        open={amountSelectModalOpen}
        setOpen={setAmountSelectModalOpen}
        values={requestAmount}
        setValues={setRequestAmount}
        options={options}
        position={position}
        minValue={1}
        maxValue={10000}
        handleApply={handleAmountSelectApply}
        title={title}
      />
      <ManagerInfoDialog
        open={managerInfoModalOpen}
        setOpen={setManagerInfoModalOpen}
        position={position}
        managerInfo={managerInfo}
      />
      <DirectorInfoDialog
        open={directorInfoModalOpen}
        setOpen={setDirectorInfoModalOpen}
        position={position}
        directorInfo={directorInfo}
      />
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {sendEmailMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default B2Cclaims;
