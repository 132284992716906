import React from 'react';
import Sidebar from '../Components/Sidebar/Sidebar';

const LayoutWithSidebar = ({ children }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '270px' }}>
        <Sidebar />
      </div>
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );
};

export default LayoutWithSidebar;
