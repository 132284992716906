import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  authResultFetching,
  resetAuth,
  setAuthResult,
  setToken,
  setTokenExpireTime,
} from '../../app/login/loginSlice';
import lock from '../../config/authLock';
import './Login.scss';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    lock.show();
    lock.on('signin submit', () => {
      dispatch(authResultFetching());
    });

    lock.on('signup submit', () => {
      dispatch(authResultFetching());
    });

    lock.on('unrecoverable_error', (error) => {
      console.log('unrecoverable_error', error);
      lock.show({
        flashMessage: {
          type: 'error',
          text: error.errorDescription,
        },
      });
      dispatch(resetAuth());
    });

    lock.on('authorization_error', (error) => {
      console.log('unrecoverable_error', error);
      lock.show({
        flashMessage: {
          type: 'error',
          text: error.errorDescription,
        },
      });
      dispatch(resetAuth());
    });

    lock.on('authenticated', (authResult) => {
      console.log('authenticated');
      lock.hide();
      dispatch(setAuthResult(authResult));
      const date = new Date();
      dispatch(setTokenExpireTime(date.setSeconds(date.getSeconds() + authResult.expiresIn)));
      lock.getUserInfo(authResult.accessToken, (error, profileResult) => {
        console.log('authResult', authResult);
        console.log('profileResult', profileResult);
        const stringifiedProfileData = JSON.stringify(profileResult);
        localStorage.setItem('profileResult', stringifiedProfileData);
        dispatch(setToken(authResult.accessToken));
        // dispatch(setUserData(profileResult));
        if (error) {
          console.log(error);
        }
        return navigate('/dashboard');
      });
    });
  }, []);

  // dymaically add background: white to the lock screen
  useEffect(() => {
    const addInlineStyle = (_, observer) => {
      const targetElement = document.querySelector('.auth0-lock-header-bg');
      if (targetElement) {
        targetElement.setAttribute('style', 'background: white;');
        observer.disconnect();
      }
    };

    const mutationObserver = new MutationObserver(addInlineStyle);

    const observerConfig = {
      attributes: true,
      childList: true,
      subtree: true,
    };

    const observingNode = document.getElementById('auth0-main');
    mutationObserver.observe(observingNode, observerConfig);
  }, []);

  return (
    <div>
      <div id="auth0-main" />
    </div>
  );
};

export default Login;
