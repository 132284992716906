import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  createNotification,
  editNotification,
  getNotification,
  getUsersList,
  publishNotification,
} from '../../app/notificationCentre/notificationSlice';
import { notificationStatuses, notificationTypes } from '../../config/variable';
import useNotificationAlert from '../../hooks/useNotificationAlert';
import { extractDateTime } from '../../utils/dataFormat';
import getItemPosition from '../../utils/getItemPosition';
import sleep from '../../utils/sleep';
import ReviewNotificationDialog from '../Dialog/ReviewNotificationDialog/ReviewNotificationDialog';
import ScheduleNotificationDialog from '../Dialog/ScheduleNotificationDialog/ScheduleNotificationDialog';
import TextInputWithLabel from '../Input/TextInputWithLabel/TextInputWithLabel';
import './EditNewNotification.scss';
import BoxedRadioOption from './components/BoxedRadioOption/BoxedRadioOption';
import SelectCompanyUser from './components/SelectCompanyUser/SelectCompanyUser';
import SendModal from './components/SendModal/SendModal';

const userTypes = {
  ALL_USERS: 'all-users',
  SELECTED_USERS: 'selected-users',
};

const EditNewNotification = () => {
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [body, setBody] = useState('');
  const [recipientsRadioValue, setRecipientsRadioValue] = useState(userTypes.ALL_USERS);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [scheduleNotificationModalOpen, setScheduleNotificationModalOpen] = useState(false);
  const [scheduleNotificationTime, setScheduleNotificationTime] = useState(
    dayjs()
      .startOf('hour')
      .add(Math.ceil(dayjs().minute() / 15) * 15, 'minutes')
  );
  const [scheduleNotificationDate, setScheduleNotificationDate] = useState(dayjs(Date.now()));
  const [selectedCompanyUsersPair, setSelectedCompanyUsersPair] = useState({});
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);
  const { notificationId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [reviewNotificationModalOpen, setReviewNotificationModalOpen] = useState(false);
  const [sendNotificationText, setSendNotificationText] = useState('');
  const [isNotificationPending, setIsNotificationPending] = useState(false);
  const { setSnackOpen, setToastMessage } = useNotificationAlert();

  function handleBreadcrumbWrapperClick(event) {
    event.preventDefault();
  }
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const titleClickHandler = () => {
    setIsTitleFocused(true);
  };

  const handleCloseIconClick = () => {
    setIsTitleFocused(false);
  };

  const handleSaveIconClick = (e) => {
    e.preventDefault();
    setTitle(e.target.upperTitle.value);
    setIsTitleFocused(false);
  };

  const handleRecipientsRadioChange = (e) => {
    setRecipientsRadioValue(e.target.value);
  };
  const handleDownArrowClick = (e) => {
    e.stopPropagation();
    const itemPosition = getItemPosition(e);
    itemPosition.left -= 100;
    itemPosition.top += 20;
    setPosition(itemPosition);
    setSendModalOpen(true);
  };

  const handleSendModal = () => {
    setScheduleNotificationModalOpen(true);
    setSendModalOpen(false);
  };

  const getNotificationUserIds = () => {
    let userIds = [];
    if (recipientsRadioValue === userTypes.ALL_USERS) {
      userIds = ['all'];
    }
    if (recipientsRadioValue === userTypes.SELECTED_USERS) {
      Object.keys(selectedCompanyUsersPair).forEach((key) => {
        userIds = [...userIds, ...selectedCompanyUsersPair[key].map((pair) => pair.value)];
      });
    }
    return userIds;
  };

  const getNotificationUserNames = () => {
    if (recipientsRadioValue === userTypes.ALL_USERS) {
      return null;
    }
    if (recipientsRadioValue === userTypes.SELECTED_USERS) {
      let userNamesArr = [];
      Object.keys(selectedCompanyUsersPair).forEach((key) => {
        userNamesArr = [
          ...userNamesArr,
          ...selectedCompanyUsersPair[key].map((pair) => pair.label),
        ];
      });
      const userNames = userNamesArr.join(', ');
      const truncatedNames = userNames.length > 22 ? `${userNames.slice(0, 22)}..` : userNames;
      return truncatedNames;
    }
    return null;
  };

  const saveDraft = async () => {
    const data = {
      title,
      subTitle,
      body,
    };

    data.subscriberId = getNotificationUserIds();

    if (pathname.includes(`edit/${notificationId}`)) {
      dispatch(editNotification({ id: notificationId, ...data }));
    }
    if (pathname.includes('new')) {
      dispatch(createNotification(data));
    }
    await sleep(200);
    navigate(`/notification-centre?tab=0`);
  };

  useEffect(() => {
    if (notificationId) {
      dispatch(getNotification(notificationId));
    }
  }, [notificationId]);

  useEffect(() => {
    if (notification) {
      setTitle(notification.title);
      setSubTitle(notification.subTitle);
      setBody(notification.body);
      if (Array.isArray(notification.subscriberId)) {
        if (notification.subscriberId[0] === 'all') {
          setRecipientsRadioValue(userTypes.ALL_USERS);
        } else if (notification.subscriberId.length > 0) {
          setRecipientsRadioValue(userTypes.SELECTED_USERS);
        }
      }
    }
  }, [notification]);

  useEffect(() => {
    if (pathname.includes('new')) {
      setTitle('');
      setSubTitle('');
      setBody('');
      setRecipientsRadioValue(userTypes.ALL_USERS);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      pathname.includes(`edit/${notificationId}`) &&
      Array.isArray(notification?.subscriberId) &&
      notification.subscriberId[0] !== 'all' &&
      notification.subscriberId.length > 0
    ) {
      dispatch(getUsersList(notification.subscriberId));
    }
  }, [pathname, notification?.subscriberId]);

  const handleInstantSend = () => {
    const notificationText = getNotificationUserNames();
    setSendNotificationText(notificationText);
    setIsNotificationPending(false);
    setReviewNotificationModalOpen(true);
  };

  const handleScheduleNotification = async () => {
    const dateTimeString = extractDateTime(
      scheduleNotificationDate.toISOString(),
      scheduleNotificationTime.toISOString()
    );
    const data = {
      title,
      subTitle,
      body,
      notificationType: notificationTypes.SCHEDULED_ONCE,
      subscriberId: getNotificationUserIds(),
      scheduledAt: dateTimeString.toISOString(),
    };
    dispatch(publishNotification(data));
    setToastMessage('Notification scheduled');
    setSnackOpen(true);
    await sleep(200);
    navigate(`/notification-centre?tab=1`);
  };

  const handleReviewNotification = async () => {
    setIsNotificationPending(true);
    const { notificationId: publishedNotificationId } = await dispatch(
      publishNotification({
        title,
        subTitle,
        body,
        notificationType: notificationTypes.INSTANT,
        subscriberId: getNotificationUserIds(),
      })
    ).unwrap();
    let pollCounter = 0;
    let toastMsg = 'Timed out';

    const checkNotificationStatus = async () => {
      if (pollCounter > 15) {
        setIsNotificationPending(false);
        return;
      }
      const { notificationStatus } = await dispatch(
        getNotification(publishedNotificationId)
      ).unwrap();
      if (notificationStatus === notificationStatuses.UNPUBLISHED) {
        pollCounter += 1;
        await sleep(200);
        await checkNotificationStatus();
      }
      if (notificationStatus === notificationStatuses.PUBLISHED) {
        setIsNotificationPending(false);
        toastMsg = 'Notification sent';
      }
      if (notificationStatus === notificationStatuses.FAILED) {
        setIsNotificationPending(false);
        toastMsg = 'Notification failed';
      }
    };

    await checkNotificationStatus();
    setToastMessage(toastMsg);
    setSnackOpen(true);
    navigate(`/notification-centre?tab=2`);
  };

  return (
    <div className="edit-new-notification">
      <div role="presentation" onClick={handleBreadcrumbWrapperClick}>
        <Breadcrumbs aria-label="breadcrumb" className="notification-breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            to="/notification-centre"
            className="link-wrapper"
          >
            <ArrowBackIosIcon fontSize="tiny" className="arrow-icon" />
            Notification centre
          </Link>
          <Link
            underline="hover"
            color="text.primary"
            href="/material-ui/react-breadcrumbs/"
            aria-current="page"
            className="link-wrapper"
          >
            <span className="span-text">{title || 'Untitled'}</span>
          </Link>
        </Breadcrumbs>
      </div>
      <Box className="notification-title-wrapper">
        <Box>
          {isTitleFocused ? (
            <form className="title-form-wrapper" onSubmit={handleSaveIconClick}>
              <TextField className="title-input" defaultValue={title} name="upperTitle" />
              <Box className="title-icon-wrapper">
                <button type="submit">
                  <SaveIcon className="title-icon" />
                </button>
                <CloseIcon className="title-icon" onClick={handleCloseIconClick} />
              </Box>
            </form>
          ) : (
            <Box className="title-text-wrapper">
              <Box onClick={titleClickHandler} className="title-text-with-icon-wrapper">
                <Typography className="title-text">{title || 'Untitled'}</Typography>
                <Box className="title-icon-wrapper">
                  <EditIcon className="title-icon" fontSize="tiny" />
                </Box>
              </Box>
              <Typography className="draft-text">Draft</Typography>
            </Box>
          )}
        </Box>
        <Box className="title-btn-wrapper">
          <Button onClick={saveDraft} sx={{ mr: 2 }}>
            Finish later
          </Button>
          <Button variant="contained" onClick={handleInstantSend}>
            Send{' '}
            <KeyboardArrowDownIcon
              onClick={handleDownArrowClick}
              sx={{ ml: 1 }}
              color="white"
              fontSize="small"
            />
          </Button>
        </Box>
      </Box>
      <section className="notification-content-section">
        <Typography variant="h4">Notification Content</Typography>
        <Typography variant="muted1" className="notification-subtitle">
          Create the notification&apos;s content
        </Typography>
        <TextInputWithLabel
          value={title}
          setValue={setTitle}
          label="Title"
          placeholder="The title of the notification that can be quickly understood by the user"
          isRequired
        />
        {/* <TextInputWithLabel
          value={subTitle}
          setValue={setSubTitle}
          label="Subtitle"
          placeholder="Additional information that explains the purpose of the notification"
        /> */}
        <TextInputWithLabel
          value={body}
          setValue={setBody}
          label="Body"
          placeholder="The content of the alert message"
          isRequired
          type="textarea"
        />
        <Typography variant="h4" sx={{ mt: '1.5rem' }}>
          Recipients
        </Typography>
        <Typography variant="muted1" className="notification-subtitle">
          Choose who will receive this notification
        </Typography>
        <FormControl className="recipients-radio-wrapper">
          <RadioGroup
            name="recipients-radio-select"
            value={recipientsRadioValue}
            className="recipients-radio-select"
            onChange={handleRecipientsRadioChange}
          >
            <BoxedRadioOption
              label="All users"
              value={userTypes.ALL_USERS}
              subTitle="All users from all companies"
            />
            <BoxedRadioOption
              label="Selected users only"
              value={userTypes.SELECTED_USERS}
              subTitle="Selected users from selected companies"
            />
          </RadioGroup>
        </FormControl>
        {recipientsRadioValue === userTypes.SELECTED_USERS && (
          <SelectCompanyUser setSelectedCompanyUsersPair={setSelectedCompanyUsersPair} />
        )}
      </section>
      <SendModal
        open={sendModalOpen}
        setOpen={setSendModalOpen}
        position={position}
        handleApply={handleSendModal}
      />
      <ScheduleNotificationDialog
        open={scheduleNotificationModalOpen}
        setOpen={setScheduleNotificationModalOpen}
        date={scheduleNotificationDate}
        time={scheduleNotificationTime}
        subTitle="Canberra, Melbourne, Sydney"
        handleApply={handleScheduleNotification}
        setDate={setScheduleNotificationDate}
        setTime={setScheduleNotificationTime}
      />
      <ReviewNotificationDialog
        open={reviewNotificationModalOpen}
        setOpen={setReviewNotificationModalOpen}
        isPending={isNotificationPending}
        setIsPending={setIsNotificationPending}
        totalCompanies={12}
        totalUsers={28}
        sendNotificationText={sendNotificationText}
        handleApply={handleReviewNotification}
      />
    </div>
  );
};

export default EditNewNotification;
