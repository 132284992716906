import { Dialog, DialogContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CustomDialogAction from '../CustomDialogAction/CustomDialogAction';
import CustomDialogTitle from '../CustomDialogTitle/CustomDialogTitle';
import './BaseDialog.scss';

const BaseDialog = (props) => {
  const {
    open,
    title,
    handleClose,
    hasCloseIcon,
    actionButtons,
    ref,
    subTitle,
    titleMiddle,
    subTitleMiddle,
    titleIcon,
    children,
    ...other
  } = props;
  const handleEntering = () => {
    if (ref?.current != null) {
      ref.current.focus();
    }
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '100vh' } }}
      maxWidth="sm"
      TransitionProps={{ ...(ref ? { onEntering: handleEntering } : {}) }}
      open={open}
      className="base-dialog"
      {...other}
    >
      <CustomDialogTitle
        title={title}
        handleClose={handleClose}
        titleMiddle={titleMiddle}
        hasCloseIcon={hasCloseIcon}
        titleIcon={titleIcon}
      />
      {subTitle && (
        <Typography
          variant="muted1"
          className={`subTitle ${subTitleMiddle ? 'subtitle-middle' : ''}`}
        >
          {subTitle}
        </Typography>
      )}
      <DialogContent>{children}</DialogContent>
      {actionButtons.length > 0 && <CustomDialogAction buttons={actionButtons} />}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  hasCloseIcon: PropTypes.bool.isRequired,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
      onclick: PropTypes.func.isRequired,
    })
  ).isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  subTitle: PropTypes.string.isRequired,
  titleMiddle: PropTypes.bool,
  subTitleMiddle: PropTypes.bool,
  titleIcon: PropTypes.elementType,
};

BaseDialog.defaultProps = {
  ref: null,
  titleMiddle: false,
  subTitleMiddle: false,
  titleIcon: null,
};

export default BaseDialog;
