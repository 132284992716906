import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AttachmentIcon from '@mui/icons-material/Attachment';
import {
  Box,
  Breadcrumbs,
  Grid,
  InputLabel as MuiInputLabel,
  TextField as MuiTextField,
  Snackbar,
  TableBody,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getSignUrl } from '../../app/paySlips/paySlipSlice';
import { fetchUserDetails, updateUserPaySlip } from '../../app/userRepository/userDetailsSlice';
import {
  fetchRepositoryUserInfo,
  putUserInfo,
  setUserInfoUpdated,
} from '../../app/userRepository/userInfoSlice';
import { convertTimestampToDate } from '../../utils/dataFormat';
import { rejectOptions } from '../../utils/selectOptions';
import CustomDatatable from '../CustomDatatable/CustomDatatable';
import EmployeeApproveDialog from '../Dialog/EmployeeApproveDialog/EmployeeApproveDialog';
import FeedbackDialog from '../Dialog/FeedbackDialog/FeedbackDialog';
import Loader from '../Loader/Loader';
import TableCell from '../TableCell/TableCell';
import TabStatus from '../Tabs/TabStatus';
import './RepositoryUserDetails.scss';
import UserInfoSkeleton from './UserInfoSkeleton';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TextField = styled(MuiTextField)(() => ({
  '&.MuiTextField-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '10px 14px',
    width: '100%',
  },
}));

const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  '&.MuiInputLabel-root': {
    marginBottom: theme.spacing(0.8),
  },
}));
const RepositoryUserDetails = () => {
  const params = useParams();
  const tabsData = ['Pending', 'Approved', 'Rejected'];
  const { isLoading, userDetails, totalCount } = useSelector(
    (state) => state.repositoryUserDetails
  );
  const {
    isLoading: userInfoLoading,
    userInfo,
    isUserInfoUpdated,
  } = useSelector((state) => state.repositoryUserInfo);
  const [openSnack, setSnackOpen] = useState(false);
  const [displaylMessage, setDisplayMessage] = useState('');
  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    workingHoursPerWeek: '',
    totalAnnualLeaveInHours: '',
    hourlyRate: '',
    abn: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const isSaveButtonDisabled = !isFormDirty;
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      ...userInfo,
    }));
  }, [userInfo]);

  useEffect(() => {
    const isDirty = Object.keys(formData).some((key) => formData[key] !== userInfo[key]);
    setIsFormDirty(isDirty);
  }, [formData, userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCancel = () => {
    setFormData((prevData) => ({
      ...prevData,
      ...userInfo,
    }));
  };
  const [status, setStatus] = useState(tabsData[0]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const getStatusFlags = (currentStatus) => {
    const normalizedStatus = currentStatus.toUpperCase();
    return {
      isPending: normalizedStatus === 'PENDING',
      isApproved: normalizedStatus === 'APPROVED',
      isRejected: normalizedStatus === 'REJECTED',
    };
  };

  const { isPending, isApproved, isRejected } = getStatusFlags(status);

  const getHeadings = () => {
    switch (true) {
      case isPending:
        return ['Date Uploaded', 'Attachment', 'Actions'];
      case isApproved:
        return ['Date Uploaded', 'Attachment', 'Date approved'];
      case isRejected:
        return ['Date Uploaded', 'Attachment', 'Date rejected', 'Rejection reason'];
      default:
        return [];
    }
  };

  const headingsToRender = getHeadings();
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }
  const handleChangeStatus = (newStatus) => {
    setStatus(tabsData[newStatus]);
  };
  const handlePayslipView = async (fileName) => {
    const data = {
      fileName,
    };
    const signurl = await dispatch(getSignUrl(data)).unwrap();
    window.open(signurl, '_blank');
  };
  useEffect(() => {
    const PayslipParams = {
      status: status.toUpperCase(),
      limit: rowsPerPage,
      offset: page,
      userId: params.userId,
    };
    dispatch(fetchUserDetails(PayslipParams));
  }, [dispatch, status, rowsPerPage, page]);
  useEffect(() => {
    dispatch(fetchRepositoryUserInfo(params.userId));
  }, [dispatch]);
  const splitUserName = (userName) => {
    const [firstName, lastName] = userName.split(' ');
    return { firstName, lastName };
  };
  const handleSaveChanges = () => {
    dispatch(putUserInfo({ userId: params.userId, updatedUserInfo: formData }));
    // setFormData(initialFormData);
  };
  useEffect(() => {
    if (isUserInfoUpdated) {
      setDisplayMessage('User info updated successfully');
      setSnackOpen(true);
      dispatch(setUserInfoUpdated());
    }
  }, [isUserInfoUpdated]);
  const [selectedPayslipId, setSelectedPayslipId] = useState(null);
  const [subTitle, setSubTitle] = useState('');
  const [title, setTitle] = useState('');
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [rejectedModalValue, setRejectedModalValue] = useState('');
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [approveValue, setApproveValue] = useState(null);
  const handleApproveApply = async () => {
    setOpenApproveModal(false);
    await dispatch(
      updateUserPaySlip({
        payslipId: selectedPayslipId,
        action: 'APPROVED',
        payslipStatus: 'PAYSLIP_RECEIVED',
        firstName: approveValue.firstName,
        lastName: approveValue.lastName,
        email: approveValue.email,
        companyName: approveValue.companyName,
        abn: approveValue.abn,
        hourlyRate: approveValue.hourlyRate,
        totalAnnualLeaveInHours: approveValue.totalAnnualLeaveInHours,
        workingHoursPerWeek: approveValue.workingHoursPerWeek,
      })
    ).unwrap();
    setDisplayMessage(`User payslip successfully approved`);
    setSnackOpen(true);
  };
  const handleRejectApply = async () => {
    setOpenFeedbackModal(false);
    await dispatch(
      updateUserPaySlip({
        action: 'REJECTED',
        payslipId: selectedPayslipId,
        payslipStatus: 'PAYSLIP_DETAILS_ISSUES',
        rejectReason: rejectedModalValue,
      })
    ).unwrap();
    setDisplayMessage(`User payslip successfully rejected`);
    setSnackOpen(true);
  };
  const handleRejectedClick = (payslipId) => {
    setSelectedPayslipId(payslipId);
    setTitle('Please provide a reason of rejection');
    setSubTitle('Message displayed to user in the Subi app depends on the rejection reason');
    setOpenFeedbackModal(!openFeedbackModal);
  };
  const handleApproveClick = (payslipId) => {
    setSelectedPayslipId(payslipId);
    setOpenApproveModal(true);
  };
  return (
    <div className="user-details" style={{ flex: 1, gap: '16px' }}>
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb" className="company-bredcumb">
          <Link underline="hover" color="inherit" to="/b2c-user-repository">
            <span className="icon-wrapper">
              <ArrowBackIosIcon className="arrow-icon" />
            </span>
            User Repository
          </Link>
          <Link underline="hover" color="text.primary" aria-current="page">
            <span className="span-text">
              {userInfo.firstName && `${userInfo.firstName} ${userInfo.lastName}`}
            </span>
          </Link>
        </Breadcrumbs>
      </div>
      <div className="top-section">
        <Grid alignItems="center">
          <Grid item xs={9}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <h4 className="company-name">User details</h4>
              </Grid>
              <Grid item>
                <h6>User ID: {params.userId}</h6>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="button-action-style">
          <button type="button" className="cancel-button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            type="button"
            className={`${isSaveButtonDisabled ? 'save-button-disabled' : 'save-button-enabled'}`}
            onClick={handleSaveChanges}
            disabled={isSaveButtonDisabled || userInfoLoading}
          >
            {userInfoLoading ? 'Loading...' : 'Save changes'}
          </button>
        </div>
      </div>
      <form>
        <div className="user-info">
          <Typography variant="h4" className="info-text">
            Basic information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel>First Name</InputLabel>
              <TextField
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Last Name</InputLabel>
              <TextField
                name="lastName"
                type="text"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextField type="email" name="email" value={formData.email} onChange={handleChange} />
            </Grid>
          </Grid>
        </div>
        <div className="user-info" style={{ marginTop: '32px' }}>
          <Typography variant="h4" className="info-text">
            Employment information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel>Company name</InputLabel>
              <TextField
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>ABN</InputLabel>
              <TextField type="text" name="abn" value={formData.abn} onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Working hours (per week)</InputLabel>
              <TextField
                type="number"
                name="workingHoursPerWeek"
                value={formData.workingHoursPerWeek}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Hourly rate</InputLabel>
              <TextField
                type="number"
                name="hourlyRate"
                value={formData.hourlyRate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Total annual leave (in hours) </InputLabel>
              <TextField
                type="number"
                name="totalAnnualLeaveInHours"
                value={formData.totalAnnualLeaveInHours}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
      </form>
      <div className="parent-container">
        {userInfoLoading ? (
          <UserInfoSkeleton />
        ) : (
          <div className="company-info">
            <Box sx={{ textTransform: 'uppercase' }}>
              <h2>Manager</h2>
            </Box>
            <div className="child-row">
              <div className="box-content">
                <h3>First name</h3>
                <h5>{userInfo?.managerName && splitUserName(userInfo.managerName).firstName}</h5>
              </div>
              <div className="box-content">
                <h3>Last name</h3>
                <h5>{userInfo?.managerName && splitUserName(userInfo.managerName).lastName}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Email</h3>
                <h5>{userInfo?.managerEmail}</h5>
              </div>
              <div className="box-content">
                <h3>LinkedIn URL</h3>
                <h5>{userInfo?.managerLinkedIn}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Mobile number</h3>
                <h5>{userInfo?.managerPhone}</h5>
              </div>
            </div>
          </div>
        )}
        {userInfoLoading ? (
          <UserInfoSkeleton />
        ) : (
          <div className="company-info">
            <Box sx={{ textTransform: 'uppercase' }}>
              <h2>director</h2>
            </Box>

            <div className="child-row">
              <div className="box-content">
                <h3>First name</h3>
                <h5>{userInfo?.directorName && splitUserName(userInfo.directorName).firstName}</h5>
              </div>
              <div className="box-content">
                <h3>Last name</h3>
                <h5>{userInfo?.directorName && splitUserName(userInfo.directorName).lastName}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Email</h3>
                <h5>{userInfo?.directorEmail}</h5>
              </div>
              <div className="box-content">
                <h3>LinkedIn URL</h3>
                <h5>{userInfo?.directorLinkedIn}</h5>
              </div>
            </div>
            <div className="child-row">
              <div className="box-content">
                <h3>Mobile number</h3>
                <h5>{userInfo?.directorPhone && userInfo.directorPhone}</h5>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="title">
        <h1>Payslip history</h1>
      </div>
      <TabStatus
        tabsData={tabsData}
        handleChangeStatus={handleChangeStatus}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      <CustomDatatable
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalCount={totalCount}
      >
        {(() => {
          if (isLoading) {
            return (
              <div className="loader-container">
                <Loader />
              </div>
            );
          }
          if (!userDetails.length) {
            return (
              <Typography variant="h4" className="message-style">
                There are no payslips available.
              </Typography>
            );
          }
          return (
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="custom-table">
              <TableHead>
                <TableRow>
                  {headingsToRender.map((heading) => (
                    <TableCell key={heading}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails.map((row) => (
                  <TableRow key={row.userId}>
                    <TableCell>{convertTimestampToDate(row.dateUploaded)}</TableCell>
                    <TableCell>
                      <button
                        type="button"
                        className="button-style"
                        onClick={() => handlePayslipView(row.filename)}
                      >
                        <AttachmentIcon className="attatchment-rotate" />
                        payslip
                      </button>
                    </TableCell>
                    {isApproved && (
                      <TableCell>{convertTimestampToDate(row.verifiedDate)}</TableCell>
                    )}
                    {isPending && (
                      <TableCell align="center">
                        <button
                          type="button"
                          className="reject-button"
                          onClick={() => handleRejectedClick(row._id)}
                        >
                          Reject
                        </button>
                        <button
                          type="button"
                          className="approve-button"
                          onClick={() => handleApproveClick(row._id)}
                        >
                          Approve
                        </button>
                      </TableCell>
                    )}
                    {isRejected && (
                      <>
                        <TableCell>{convertTimestampToDate(row.rejectDate)}</TableCell>
                        <TableCell>{row.rejectedReason}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        })()}
      </CustomDatatable>
      <FeedbackDialog
        open={openFeedbackModal}
        setOpen={setOpenFeedbackModal}
        value={rejectedModalValue}
        setValue={setRejectedModalValue}
        options={rejectOptions}
        title={title}
        subTitle={subTitle}
        handleApply={handleRejectApply}
      />
      <EmployeeApproveDialog
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        value={approveValue}
        setValue={setApproveValue}
        handleApply={handleApproveApply}
      />
      <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
          {displaylMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RepositoryUserDetails;
