import { Checkbox, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import * as React from 'react';
import BaseSelectModal from './BaseSelectModal';

const CheckboxDialog = (props) => {
  const { values, setValues, open, setOpen, title, options, position, handleApply, ...other } =
    props;
  const formGroupRef = React.useRef(null);

  const handleChange = (event) => {
    if (event.target.checked) {
      setValues((prev) => [...prev, event.target.value]);
    } else {
      const indexToRemove = values.indexOf(event.target.value);
      if (indexToRemove > -1) {
        const result = [...values.slice(0, indexToRemove), ...values.slice(indexToRemove + 1)];
        setValues(result);
      }
    }
  };

  const handleReset = () => {
    setValues([]);
  };

  return (
    <BaseSelectModal
      title={title}
      open={open}
      setOpen={setOpen}
      handleReset={handleReset}
      position={position}
      handleApply={handleApply}
      {...other}
    >
      <FormGroup
        ref={formGroupRef}
        aria-label="checkbox"
        name="checkbox"
        value={values}
        onChange={handleChange}
        style={{ flexWrap: 'nowrap', maxHeight: '50vh', overflowY: 'scroll' }}
      >
        {options.map((option) => (
          <FormControlLabel
            value={option.value}
            key={option.value}
            control={<Checkbox checked={values.includes(option.value)} />}
            label={option.label}
          />
        ))}
      </FormGroup>
    </BaseSelectModal>
  );
};

CheckboxDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValues: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.arrayOf(
    PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
  ).isRequired,
};

export default CheckboxDialog;
