import { Box, FormControl } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import BaseDialog from '../BaseDialog/BaseDialog';
import './ScheduleNotificationDialog.scss';

const ScheduleNotificationDialog = (props) => {
  const { date, setDate, open, setOpen, time, setTime, subTitle, handleApply, ...other } = props;
  useEffect(() => {
    setTime(
      dayjs()
        .startOf('hour')
        .add(Math.ceil(dayjs().minute() / 15) * 15, 'minutes')
    );
  }, [open]);
  const onDateChange = (newDate) => {
    setDate(dayjs(newDate));
  };

  const onTimeChange = (newTime) => {
    setTime(dayjs(newTime));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleScheduleNotification = () => {
    setOpen(false);
    handleApply();
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title="Schedule notification"
      actionButtons={[
        {
          label: `Schedule notification`,
          variant: 'contained',
          onClick: handleScheduleNotification,
        },
        { label: 'Cancel', onClick: handleClose },
      ]}
      subTitle={
        <div>
          {subTitle}
          <hr className="divider-line" />
        </div>
      }
      hasCloseIcon
      className="schedule-notification-dialog"
      sx={{ '& .MuiDialog-paper': { width: '70%', maxHeight: '70vh' } }}
      {...other}
    >
      <Box className="schedule-date-select-wrapper">
        <FormControl className="datepicker-formcontrol" size="small">
          <DatePicker
            slotProps={{
              inputAdornment: { position: 'start' },
              textField: { size: 'small', color: 'primary' },
            }}
            value={date}
            onChange={onDateChange}
          />
        </FormControl>
        <FormControl className="timepicker-formcontrol" size="small">
          <TimePicker
            slotProps={{
              inputAdornment: { position: 'start' },
              textField: { size: 'small', color: 'primary', disabled: true },
            }}
            value={time}
            onChange={onTimeChange}
            minutesStep={15}
          />
        </FormControl>
      </Box>
    </BaseDialog>
  );
};

ScheduleNotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired,
  setTime: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
};

export default ScheduleNotificationDialog;
