export const industrySelect = [
  {
    label: 'Agriculture, forestry and fishing',
    value: 'Agriculture, forestry and fishing',
  },
  {
    label: 'Mining',
    value: 'Mining',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Electricity, gas, water and waste services',
    value: 'Electricity, gas, water and waste services',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Wholesale trade',
    value: 'Wholesale trade',
  },
  {
    label: 'Retail trade',
    value: 'Retail trade',
  },
  {
    label: 'Accommodation and food services',
    value: 'Accommodation and food services',
  },
  {
    label: 'Transport, postal and warehousing',
    value: 'Transport, postal and warehousing',
  },
  {
    label: 'Information media and telecommunications',
    value: 'Information media and telecommunications',
  },
  {
    label: 'Financial and insurance services',
    value: 'Financial and insurance services',
  },
  {
    label: 'Rental, hiring and real estate services',
    value: 'Rental, hiring and real estate services',
  },
  {
    label: 'Professional, scientific and technical services',
    value: 'Professional, scientific and technical services',
  },
  {
    label: 'Administrative and support services',
    value: 'Administrative and support services',
  },
  {
    label: 'Public administration and safety',
    value: 'Public administration and safety',
  },
  {
    label: 'Education and training',
    value: 'Education and training',
  },
  {
    label: 'Health care and social assistance',
    value: 'Health care and social assistance',
  },
  {
    label: 'Arts and recreation services',
    value: 'Arts and recreation services',
  },
  {
    label: 'Other services',
    value: 'Other services',
  },
];

export const locationSelect = [
  { label: 'NSW', value: 'nsw' },
  { label: 'ACT', value: 'act' },
  { label: 'NT', value: 'nt' },
  { label: 'QLD', value: 'qld' },
  { label: 'VIC', value: 'vic' },
  { label: 'WA', value: 'wa' },
];

export const dateSelectOptions = [
  {
    label: 'Before',
    value: 'before',
  },
  {
    label: 'After',
    value: 'after',
  },
];

export const fteCountSelect = [
  {
    label: '1-5',
    value: '1-5',
  },
  {
    label: '6-10',
    value: '6-10',
  },
  {
    label: '11-20',
    value: '11-20',
  },
  {
    label: '21-50',
    value: '21-50',
  },
  {
    label: '51-100',
    value: '51-100',
  },
  {
    label: '100+',
    value: '100+',
  },
];

export const integrationSelect = [
  {
    label: 'Xero',
    value: 'xero',
  },
  {
    label: 'CSV',
    value: 'csv',
  },
  {
    label: 'KeyPay',
    value: 'keypay',
  },
  {
    label: 'Bamboo',
    value: 'bamboo',
  },
  {
    label: 'ADP',
    value: 'adp',
  },
  {
    label: 'Workday',
    value: 'workday',
  },
  {
    label: 'MYOB',
    value: 'myob',
  },
];

export const companySelect = [
  {
    label: 'PRAGMATEAM PTY LIMITED',
    value: 'PRAGMATEAM PTY LIMITED',
  },
  {
    label: 'THINK HEALTH PHYSIOTHERAPY PTY LTD',
    value: 'THINK HEALTH PHYSIOTHERAPY PTY LTD',
  },
  {
    label: 'BeFit Health PTY LTD',
    value: 'BeFit Health PTY LTD',
  },
  {
    label: 'Luxity Media Pty Ltd',
    value: 'Luxity Media Pty Ltd',
  },
];

export const amountReuquestedSelect = [
  {
    label: '$1-100',
    value: '$1-100',
  },
  {
    label: '$101-500',
    value: '$101-500',
  },
  {
    label: '$501-1000',
    value: '$501-1000',
  },
  {
    label: '$1001-5000',
    value: '$1001-5000',
  },
  {
    label: '$5001-10000',
    value: '$5001-10000',
  },
  {
    label: '$10000+',
    value: '$10000+',
  },
];
export const paymentMethodSelect = [
  {
    label: 'Instalments',
    value: 'Instalments',
  },
  {
    label: 'Upfront',
    value: 'Upfront',
  },
];

export const rejectOptions = [
  {
    title: 'Payslip',
    key: 'payslip',
    options: [
      {
        label: `It's not a payslip`,
        value: `It's not a payslip`,
      },
      {
        label: `The required information is not available and legible`,
        value: `The required information is not available and legible`,
      },
      {
        label: `It's not the latest payslip`,
        value: `It's not the latest payslip`,
      },
      {
        label: `Not enough leave`,
        value: `Not enough leave`,
      },
    ],
  },
  {
    title: 'Bank Statement',
    key: 'bankStatement',
    options: [
      {
        label: `The uploaded document is not a bank statement`,
        value: `The uploaded document is not a bank statement`,
      },
      {
        label: `The required information on the bank statement is not available or legible`,
        value: `The required information on the bank statement is not available or legible`,
      },
      {
        label: `It’s not the latest bank statement`,
        value: `It’s not the latest bank statement`,
      },
      {
        label: `The account displayed on the bank statement does not match the account where the user's salary is deposited`,
        value: `The account displayed on the bank statement does not match the account where the user's salary is deposited`,
      },
      {
        label: `The bank statement does not reflect sufficient funds or regular transactions to validate the user's financial status`,
        value: `The bank statement does not reflect sufficient funds or regular transactions to validate the user's financial status`,
      },
      {
        label: `Fail credit assessment`,
        value: `Credit assessment not approved`,
      },
    ],
  },
];

export const statusOptions = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];
