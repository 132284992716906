import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePut } from '../../utilities/apiCaller';

export const fetchB2BEmployees = createAsyncThunk(
  'b2bEmployee/fetchB2BEmployees',
  async (
    { status, limit, offset, company, approvedDate, paidDate, paidAmount, requestAmount },
    { rejectWithValue }
  ) => {
    try {
      let url = `/data/b2b/employee-payments?claimStatus=${status}&limit=${limit}&offset=${
        offset + 1
      }`;
      if (company) {
        url += `&company=${company}`;
      }
      if (approvedDate) {
        url += `&approvedDate=${approvedDate}`;
      }
      if (requestAmount) {
        url += `&amount=${requestAmount}`;
      }
      if (paidDate) {
        url += `&paidDate=${paidDate}`;
      }
      if (paidAmount) {
        url += `&amount=${paidAmount}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateClaimById = createAsyncThunk(
  'claims/updateClaimById',
  async (claimId, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2b/employee-payments', { claimId });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const b2bEmployeeSlice = createSlice({
  name: 'b2bEmployee',
  initialState: {
    b2bEmployees: [],
    unfiltredb2bEmployees: null,
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  reducers: {
    setEmployeePaymentsBySearchToken: (state, action) => {
      if (!state.unfiltredb2bEmployees) {
        state.unfiltredb2bEmployees = [...state.b2bEmployees];
      }
      const { token } = action.payload;
      state.b2bEmployees = state.unfiltredb2bEmployees.filter((obj) => {
        // eslint-disable-next-line consistent-return, no-restricted-syntax, guard-for-in
        for (const key in obj) {
          if (
            typeof obj[key] === 'string' &&
            obj[key].toLowerCase().includes(token.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchB2BEmployees.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchB2BEmployees.fulfilled, (state, action) => {
        state.b2bEmployees = action.payload.data;
        state.isLoading = false;
        state.error = null;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchB2BEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.b2bEmployees = [];
        state.totalCount = 0;
      })
      .addCase(updateClaimById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateClaimById.fulfilled, (state, action) => {
        state.isLoading = false;
        const { _id: claimId } = action.payload;
        state.b2bEmployees = state.b2bEmployees.filter((employee) => employee.claimId !== claimId);
        state.error = null;
      })
      .addCase(updateClaimById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setEmployeePaymentsBySearchToken } = b2bEmployeeSlice.actions;
export default b2bEmployeeSlice.reducer;
