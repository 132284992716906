import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { renderLayout } from '../../layouts/renderLayout';
import { TokenChecker } from './TokenChecker';

const isAuthenticate = (accessToken) => {
  return !!accessToken;
};

const PrivateRoute = ({ element, layoutType }) => {
  const { accessToken } = useSelector((state) => state.loginReducer.authResult);

  return isAuthenticate(accessToken) ? (
    <TokenChecker>{renderLayout(layoutType || 'withSidebar', element)}</TokenChecker>
  ) : (
    <Navigate to="/" />
  );
};

PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  layoutType: PropTypes.oneOf(['withSidebar', 'none']),
};

PrivateRoute.defaultProps = {
  layoutType: 'withSidebar',
};

export default PrivateRoute;
