import { privatePost } from '../../utilities/apiCaller';

export const getPaymentIntent = async () => {
  const response = await privatePost('/data/b2c/payments/payment-intend', undefined);
  return response.data;
};

export const postAttachUser = async (dataBody) => {
  const response = await privatePost('/data/b2c/payments/attach-user', dataBody);
  return response.data;
};
