import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const UserInfoSkeleton = () => {
  return (
    <div className="company-info">
      <Box sx={{ textTransform: 'uppercase' }}>
        <Skeleton variant="text" width={100} />
      </Box>

      <div className="child-row">
        <div className="box-content">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={150} />
        </div>

        <div className="box-content">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={150} />
        </div>
      </div>

      <div className="child-row">
        <div className="box-content">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={150} />
        </div>

        <div className="box-content">
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={200} />
        </div>
      </div>

      <div className="child-row">
        <div className="box-content">
          <Skeleton variant="text" width={150} />
          <Skeleton variant="text" width={150} />
        </div>
      </div>
    </div>
  );
};

export default UserInfoSkeleton;
