import React from 'react';
import Typography from '@mui/material/Typography';
import './Settings.scss';

const SettingsPage = () => {
  return (
    <div className="settings">
      <Typography variant="h4" className="message-style">
        Coming Soon
      </Typography>
    </div>
  );
};

export default SettingsPage;
