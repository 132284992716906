import dayjs from 'dayjs';

export const formatDate = (date, formatStr = 'DD/MM/YYYY') => dayjs(date).format(formatStr);

export const fromStringToDate = (date, readFormatStr = 'DD/MM/YYYY') =>
  dayjs(date, readFormatStr).toDate();

export const addDate = (date, value, unit) => dayjs(date).add(value, unit).toDate();

export const fromStringToDayJS = (date, readFormatStr = 'DD/MM/YYYY') => dayjs(date, readFormatStr);

export const fromDayJSToString = (dayJsObject, formatStr = 'DD/MM/YYYY') =>
  dayJsObject.format(formatStr);

export const now = () => dayjs().toDate();
