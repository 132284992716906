import { Card, CardActions, CardContent, Divider, Modal, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import * as React from 'react';
import './Dialog.scss';

const CardTitle = ({ children }) => {
  return (
    <Typography variant="h5" sx={{ pt: '1rem', pl: '1rem', pb: '1rem' }}>
      {children}
    </Typography>
  );
};

const BaseSelectModal = (props) => {
  const { open, setOpen, title, position, handleApply, handleReset, children, ...other } = props;

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="checkbox-dialog"
      style={{ position: 'absolute', top: position.top, left: position.left }}
      {...other}
    >
      <Card variant="outlined">
        <CardTitle>{title}</CardTitle>
        <Divider />
        <CardContent>{children}</CardContent>
        <CardActions className="dialog-action">
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
          <Button autoFocus onClick={handleReset}>
            Reset
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

BaseSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.shape({ top: PropTypes.number.isRequired, left: PropTypes.number.isRequired })
    .isRequired,
};

export default BaseSelectModal;
