import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { privateGet, privatePut } from '../../utilities/apiCaller';

export const fetchUserDetails = createAsyncThunk(
  'userDetails/fetchUserDetails',
  async ({ limit, offset, status, userId }, { rejectWithValue }) => {
    try {
      let url = `/data/b2c/user/payslip-history?limit=${limit}&offset=${offset + 1}`;
      if (status) {
        url += `&status=${status}`;
      }
      if (userId) {
        url += `&userId=${userId}`;
      }
      const response = await privateGet(url);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateUserPaySlip = createAsyncThunk(
  'paySlip/updatePayslip',
  async (updateData, { rejectWithValue }) => {
    try {
      const response = await privatePut('/data/b2c/payslips', updateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: {
    userDetails: [],
    isLoading: false,
    error: null,
    totalCount: 0,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload.data;
        state.totalCount = action.payload.totalCount;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.userDetails = [];
      })
      .addCase(updateUserPaySlip.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUserPaySlip.fulfilled, (state, { payload }) => {
        const { payslipData } = payload;
        state.userDetails = state.userDetails.filter((paySlip) => paySlip._id !== payslipData._id);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateUserPaySlip.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userDetailsSlice.reducer;
