import { MoreHoriz } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import ApplicationRejectModal from '../../../../../../Components/Payslip/ApplicationRejectModal';
import ApplicationUpdateRequiredModal from '../../../../../../Components/Payslip/ApplicationUpdateRequiredModal';

const Container = ({ children }) => {
  return <div className="group flex gap-2">{children}</div>;
};

const PendingActions = ({ payslip, handleApproveFn }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApproveClick = async () => {
    handleApproveFn(payslip._id, {
      email: payslip.email,
      firstName: payslip.firstName,
      lastName: payslip.lastName,
    });
    handleClose();
  };

  const _selectedUser = {
    email: payslip.email,
    firstName: payslip.firstName,
    lastName: payslip.lastName,
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHoriz />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleApproveClick}>
          <Container>
            <CheckIcon color={theme.palette.success.main} className="text-green-500" />
            <Typography>Approve</Typography>
          </Container>
        </MenuItem>

        <MenuItem className="group">
          <ApplicationUpdateRequiredModal payslipId={payslip._id} user={_selectedUser}>
            <Container>
              <AutorenewIcon className="group-hover:animate-spin" />
              <Typography>Require updated documents</Typography>
            </Container>
          </ApplicationUpdateRequiredModal>
        </MenuItem>

        <MenuItem>
          <ApplicationRejectModal payslipId={payslip._id} user={_selectedUser}>
            <Container>
              <CloseIcon color={theme.palette.red.main} className="text-red-500" />
              <Typography>Reject</Typography>
            </Container>
          </ApplicationRejectModal>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PendingActions;
