import React, { useState } from 'react';
import './Login.scss';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsButtonDisabled(e.target.value === '' || password === '');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setIsButtonDisabled(email === '' || e.target.value === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-md field-gap">
        <div className="text-center flex flex-col  items-center gap-4">
          <div className="flex items-center gap-1">
            <img className="img-text" src="/img/subi-logo.png" alt="subi" />
            <span className="subi-text">Subi</span>
          </div>

          <span className="welcome-text">Welcome back! Please enter your details to log in.</span>
        </div>

        <form className="field-gap" onSubmit={handleSubmit}>
          <div className="child-div">
            <span className="label-text">Email</span>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="w-full login-input"
              onChange={handleEmailChange}
            />
          </div>

          <div className="child-div">
            <span className="label-text">Password</span>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              onChange={handlePasswordChange}
              className="w-full login-input"
            />
          </div>
          <button
            type="submit"
            className={`w-full ${isButtonDisabled ? 'disabled-button' : 'login-button'}`}
            disabled={isButtonDisabled}
          >
            Log in
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
