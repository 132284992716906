import { styled } from '@mui/material/styles';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

const StyledDatePicker = styled(MuiDatePicker)(() => ({
  '&.MuiFormControl-root': {
    width: '100%',
  },
  /**
   * We should handle the errors with a better approach
   */
  '.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'inherit',
  },
}));

export default StyledDatePicker;
